import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Input from '../../../common/ui-components/input/input.component';
import { useNotification } from '../../../common/ui-components/notification';
import PrimaryButton from '../../../common/ui-components/primary-button/primary-button.component';
import { UserModel } from '../models/user-model';
import './list-users.component.scss';
import { UserRowProps } from './user-list-row.component';
import UserList from './user-list.component';

interface ListUsersState {
    users: UserModel[];
    filter: string;
}

export interface ListUsersProps {
    users: UserModel[];
    children: ({ user, editUser, updateUser }: UserRowProps) => React.ReactNode;
    header: React.ReactNode;
    edit: (
        user: UserModel,
        onUpdate: (user: Partial<UserModel>) => void,
        disabled: boolean
    ) => React.ReactNode;
    addUser: (user: UserModel) => Promise<string>;
    updateUser: (user: UserModel) => Promise<void>;
    accountId?: string;
    showAccountColumn?: boolean;
}

const ListUsers = ({
    users: allUsers,
    accountId,
    children,
    header,
    edit,
    addUser,
    updateUser,
    showAccountColumn
}: ListUsersProps) => {
    const [state, setState] = useState<ListUsersState>({
        users: [],
        filter: ''
    });
    const showNotification = useNotification();

    const setUsers = (users: UserModel[]) => setState({ ...state, users });
    const setFilter = (filter: string) => setState({ ...state, filter });

    useEffect(() => setUsers(allUsers), [allUsers]);

    const createNewUser = () =>
        setUsers([
            ...state.users,
            {
                id: null,
                disabled: false,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                isAdmin: false,
                isOwner: false,
                isAccountManager: false,
                accountId
            }
        ]);

    function updateUserWithId(user: UserModel, id: string | null) {
        const index = state.users.findIndex(u => u.id === id);
        state.users[index] = user;
        setUsers([...state.users]);
    }

    const onUpdateUser = async (user: UserModel) => {
        if (user.id === null) {
            const userId = await addUser(user);
            showNotification(i18n`User wurde per E-Mail eingeladen`);
            updateUserWithId(
                {
                    ...user,
                    id: userId
                },
                null
            );
        } else {
            await updateUser(user);
            updateUserWithId(user, user.id);
        }
    };

    const removeUnsavedUser = () => setUsers(state.users.filter(u => u.id !== null));

    const validateEmail = (user: UserModel) =>
        !state.users.find(
            u => u.id !== user.id && u.email.toLowerCase() === user.email.toLowerCase()
        );

    const filteredUsers = state.users.filter(userMatches(state.filter.toLowerCase()));

    return (
        <div className="list-users">
            <div className="list-users__create-user">
                <PrimaryButton
                    className="list-users__create-user-btn"
                    title={i18n`Neuen Benutzer anlegen`}
                    onClick={createNewUser}
                    disabled={state.users.some(u => u.id == null)}
                />
            </div>
            <div className="list-users__filter">
                <Input
                    placeholder={i18n`Filtern...`}
                    value={state.filter}
                    onChange={e => setFilter(e.target.value)}
                />
            </div>
            <UserList
                users={filteredUsers}
                onCancelUserCreation={removeUnsavedUser}
                onUpdateUser={onUpdateUser}
                validateEmail={validateEmail}
                children={children}
                header={header}
                edit={edit}
                showAccountColumn={showAccountColumn}
            />
        </div>
    );
};

export default ListUsers;

function userMatches(filter: string) {
    return (user: UserModel): boolean => {
        return (
            match(user.firstName, filter) ||
            match(user.lastName, filter) ||
            match(user.phone, filter) ||
            match(user.email, filter)
        );
    };
}

const match = (field: string, filter: string) => field.toLocaleLowerCase().includes(filter);
