import * as React from 'react';
import './primary-button.component.scss';
import Spinner from '../spinner/spinner.component';

type PrimaryButtonProps = {
    title: string;
    className?: string;
    onClick?: () => void;
    pending?: boolean;
} & any;

const PrimaryButton = ({ className, title, pending, ...props }: PrimaryButtonProps) => (
    <button
        className={`primary-button ${className || ''} ${pending ? 'primary-button--pending' : ''}`}
        type="button"
        {...props}
    >
        {pending !== null ? <Spinner className="primary-button__spinner" /> : <></>}
        {title}
    </button>
);

export default PrimaryButton;
