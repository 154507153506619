import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import './survey-version-indicator.scss';

export const SurveyVersionIndicator = ({ isV2 }: { isV2: boolean }) => (
    <span className="survey-version-indicator" title={getIndicatorTooltip(isV2)}>
        {isV2 ? 'V2' : 'V1'}
    </span>
);

function getIndicatorTooltip(isV2: boolean): string {
    return isV2
        ? i18n`Umfrage Layout mit 6 Antwortmöglichkeiten`
        : i18n`Umfrage Layout mit 4 Antwortmöglichkeiten`;
}
