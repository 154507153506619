import * as React from 'react';
import { SortDirection } from './table.component';

export interface TableSorting {
    row: string;
    direction: SortDirection;
}

export function sortRows<TRow>(
    teams: TRow[],
    direction: SortDirection,
    accessor: (team: TRow) => string
) {
    teams.sort((a, b) => {
        const leftValue = accessor(a);
        const rightValue = accessor(b);

        const comparison = leftValue.localeCompare(rightValue);

        if (direction === SortDirection.Asc) {
            return comparison;
        } else {
            return -comparison;
        }
    });
}

export interface SortableListChildProps<T> {
    rows: T[];
    sorting: TableSorting;
    setSorting(sorting: TableSorting): void;
}

export interface SortableListProps<T> {
    rows: T[];
    initialSort: string;
    sortingAccessor: (sorting: TableSorting) => (row: T) => string;
    children: (props: SortableListChildProps<T>) => any;
}

export interface SortableListState<T> {
    rows: T[];
    sorting: TableSorting;
}

export class SortableList<T> extends React.Component<SortableListProps<T>, SortableListState<T>> {
    constructor(props: SortableListProps<T>) {
        super(props);
        const state: SortableListState<T> = {
            rows: props.rows,
            sorting: {
                row: props.initialSort,
                direction: SortDirection.Asc
            }
        };
        sortRows(state.rows, state.sorting.direction, props.sortingAccessor(state.sorting));
        this.state = state;
    }

    componentDidUpdate(previousProps: Readonly<SortableListProps<T>>) {
        if (previousProps.rows === this.props.rows) {
            return;
        }
        const rows = [...this.props.rows];
        sortRows(
            rows,
            this.state.sorting.direction,
            this.props.sortingAccessor(this.state.sorting)
        );
        this.setState({
            ...this.state,
            rows
        });
    }

    render() {
        return this.props.children({
            rows: this.state.rows,
            sorting: this.state.sorting,
            setSorting: (sorting: TableSorting) => this.setSorting(sorting)
        });
    }

    private setSorting(sorting: TableSorting) {
        const rows = [...this.state.rows];
        sortRows(rows, sorting.direction, this.props.sortingAccessor(sorting));
        this.setState({
            ...this.state,
            rows,
            sorting
        });
    }
}
