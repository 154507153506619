import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Button from '../../../../../common/ui-components/button/button.component';
import PrimaryButton from '../../../../../common/ui-components/primary-button/primary-button.component';
import './delete-report-dialog.component.scss';

export const DeleteReportDialog = ({ onCancel, onConfirm }) => (
    <div className="delete-report-dialog">
        <h1 className="delete-report-dialog__title">{i18n`Report löschen?`}</h1>
        <p className="delete-report-dialog__content">{i18n`Der Report wird gelöscht und neu erstellt. Eingaben und Auswahlen werden zurückgesetzt. Diese Aktion kann nicht rückgängig gemacht werden.`}</p>
        <div className="delete-report-dialog__actions">
            <Button title="Nein" onClick={onCancel} />
            <PrimaryButton title="Ja" onClick={onConfirm} />
        </div>
    </div>
);
