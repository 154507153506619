import { mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import { SortDirection } from './table.component';

export interface TableColumnProps {
    children?;
    className?: string;
    width?: string | number;
    sorting?: SortDirection;
    onClick?: (event) => void;
    style?: object;
}

const TableColumn = ({ children, className, width, sorting, ...props }: TableColumnProps) => (
    <div
        {...props}
        className={`table__column ${className || ''} ${
            sorting != null ? 'table__column--sortable' : ''
        }`}
        style={{
            flex: typeof width === 'string' ? `0 0 ${width}` : width,
            ...(props.style || {})
        }}
    >
        {children}
        {getSortArrow(sorting)}
    </div>
);

const getSortArrow = (sorting: SortDirection) =>
    sorting ? (
        <Icon
            path={mdiMenuDown}
            size={1}
            className={`table__column-sort-arrow ${getSortingClass(sorting)}`}
        />
    ) : (
        <></>
    );

const getSortingClass = (sorting: SortDirection): string => {
    if (sorting === SortDirection.Asc) {
        return 'table__column-sort-arrow--asc';
    }
    return 'table__column-sort-arrow--desc';
};

export default TableColumn;
