import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useNotification } from '../../../../../common/ui-components/notification';
import { Table, TableHeader, TableRow } from '../../../../../common/ui-components/table';
import TableBody from '../../../../../common/ui-components/table/table-body.component';
import TableColumn from '../../../../../common/ui-components/table/table-column.component';
import { SurveyRunModel } from '../../../models/team-model';
import { CloseSurveyButton } from './close-survey-button.component';
import { OpenReportButton } from './open-report-button.component';
import { RemindMembersButton } from './remind-members-button.component';
import { DeleteReportButton } from './delete-report-button.component';
import { mdiLink } from '@mdi/js';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import copy from 'clipboard-copy';

const actionRowWidth = 1;

export interface SurveyListProps {
    disabled: boolean;
    surveyRuns: SurveyRunModel[];
    teamId: string;
    onCloseSurvey: (run: SurveyRunModel) => Promise<void>;
    onRemindMembers: (run: SurveyRunModel) => void;
    onDeleteReport: (run: SurveyRunModel) => Promise<void>;
}

const SurveyList = ({
    disabled,
    surveyRuns,
    teamId,
    onCloseSurvey,
    onRemindMembers,
    onDeleteReport
}: SurveyListProps) => {
    const showNotification = useNotification();

    return (
        <Table className="view-team__list">
            <TableHeader>
                <TableColumn width={1}>{i18n`Datum`}</TableColumn>
                <TableColumn width={1}>{i18n`Status`}</TableColumn>
                <TableColumn width={actionRowWidth} />
            </TableHeader>
            <TableBody>
                {surveyRuns.map((r, i) => {
                    const link = `/teams/${teamId}/runs/${r.id}`;
                    return (
                        <TableRow key={i} link={r.completed && link}>
                            <TableColumn width={1}>{displayDate(r.created)}</TableColumn>
                            <TableColumn width={1}>{displayState(r)}</TableColumn>
                            <TableColumn width={actionRowWidth}>
                                {r.completed && <OpenReportButton />}
                                {r.completed && (
                                    <DeleteReportButton
                                        onClick={() =>
                                            onDeleteReport(r).then(() =>
                                                showNotification(i18n`Report wurde neu erstellt`)
                                            )
                                        }
                                    />
                                )}
                                {!disabled && !r.completed && (
                                    <CloseSurveyButton
                                        onClick={() =>
                                            onCloseSurvey(r).catch(() =>
                                                showNotification(
                                                    i18n`Schließen der Umfrage ist fehlgeschlagen`
                                                )
                                            )
                                        }
                                        disabled={r.closeRequested}
                                    />
                                )}
                                {!disabled && !r.completed && !r.closeRequested && (
                                    <RemindMembersButton onClick={() => onRemindMembers(r)} />
                                )}
                                {!disabled && !r.completed && !r.closeRequested && (
                                    <IconButton
                                        icon={mdiLink}
                                        title={i18n`URL zur Umfrage kopieren`}
                                        onClick={() => {
                                            copy(r.surveyUrl).then(() =>
                                                showNotification(
                                                    i18n`URL in Zwischenablage kopiert`
                                                )
                                            );
                                        }}
                                    />
                                )}
                            </TableColumn>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

function displayDate(input: string): string {
    const date = new Date(input);
    return date.toLocaleDateString();
}

function displayState(run: SurveyRunModel): string {
    const responses = `(${(run.members || []).filter(m => m.completed).length}/${
        run.members.length
    })`;
    return run.completed ? i18n`Abgeschlossen ${responses}` : i18n`Offen ${responses}`;
}

export default SurveyList;
