import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { Redirect } from 'react-router';
import {
    BAD_REQUEST,
    FORBIDDEN,
    INTERNAL_SERVER_ERROR,
    NOT_FOUND,
    UNAUTHORIZED
} from '../http-status-codes';

const errorCases = new Map<number, any>();

const BadRequestError = () => i18n`400 - Bad Request`;
const ForbiddenError = () => i18n`403 - Forbidden`;
const NotFoundError = () => i18n`404 - Not Found`;
const InternalServerError = () => i18n`500 - Internal Server Error`;

errorCases.set(BAD_REQUEST, <BadRequestError />);
errorCases.set(UNAUTHORIZED, <Redirect to="/auth/login" />);
errorCases.set(FORBIDDEN, <ForbiddenError />);
errorCases.set(NOT_FOUND, <NotFoundError />);
errorCases.set(INTERNAL_SERVER_ERROR, <InternalServerError />);

const AsyncErrorHandling = ({ error }) => {
    if (error.status != null) {
        return errorCases.get(error.status);
    }
    return <pre>{error.toString()}</pre>;
};

export default AsyncErrorHandling;
