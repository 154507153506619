import * as React from 'react';

const TrendTimeSelectionHandles = ({ handles, getHandleProps }) => (
    <>
        {handles.map(handle => {
            const props = getHandleProps(handle.id);
            return <TimeSelectionHandle handle={handle} {...props} key={handle.id} />;
        })}
    </>
);

const TimeSelectionHandle = ({ handle, ...props }) => (
    <div
        className="trend-time-selection__handle"
        style={{ left: `${handle.percent}%` }}
        {...props}
    />
);

export default TrendTimeSelectionHandles;
