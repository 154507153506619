import i18n from 'es2015-i18n-tag';
import TextArea from '../../../../../common/ui-components/textarea/textarea.component';
import * as React from 'react';
import { TrendGraphModel } from '../../../models/report-model';
import './trend-comment.component.scss';

export interface TrendCommentProps {
    graph: TrendGraphModel;
    culture: string;
    onUpdate: (update: TrendGraphModel) => void;
}

const TrendComment = ({ graph, culture, onUpdate }: TrendCommentProps) => {
    const comment = graph.comment === null ? '' : graph.comment[culture];
    return (
        <div className={`trend-comment ${comment ? '' : 'trend-comment--empty'}`}>
            <h4 className="trend-comment__title">{i18n('report')`Bewertung / Kommentar`}</h4>
            <TextArea onChange={event => onChange(event.target.value)} value={comment || ''} />
        </div>
    );

    function onChange(changedComment: string) {
        graph.comment[culture] = changedComment;
        onUpdate(graph);
    }
};

export default TrendComment;
