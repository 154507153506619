import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import { mdiDelete } from '@mdi/js';
import Button from '../../../../../common/ui-components/button/button.component';
import Dialog from '../../../../../common/ui-components/dialog/dialog.component';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import PrimaryButton from '../../../../../common/ui-components/primary-button/primary-button.component';
import './account-delete-button.component.scss';

export interface AccountDeleteButtonProps {
    onDelete: () => void;
}

const AccountDeleteButton = ({ onDelete }: AccountDeleteButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);
    return (
        <>
            <IconButton
                title={i18n`Account löschen`}
                icon={mdiDelete}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDialog(true);
                }}
            />
            <Dialog
                isOpen={showDialog}
                onRequestClose={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDialog(false);
                }}
            >
                <DeleteAccountDialog onCancel={() => setShowDialog(false)} onConfirm={onDelete} />
            </Dialog>
        </>
    );
};

const DeleteAccountDialog = ({ onCancel, onConfirm }) => (
    <div
        className="delete-account-dialog"
        onClick={e => {
            e.preventDefault();
            e.stopPropagation();
        }}
    >
        <h1 className="delete-account-dialog__title">{i18n`Account löschen?`}</h1>
        <div className="delete-account-dialog__content">
            <Button title="Nein" onClick={onCancel} />
            <PrimaryButton title="Ja" onClick={onConfirm} />
        </div>
    </div>
);

export default AccountDeleteButton;
