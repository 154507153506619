import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import LanguageSelector from '../../../../i18n/language-selector';
import LoginForm from './login-form.component';
import './login.component.scss';

const logo = require('../../../../../assets/team-psychology-portal.svg');

const Login = () => (
    <>
        <div className="login">
            <div className="login__container">
                <div className="login__head">
                    <img className="login__logo" src={logo} />
                </div>
                <LoginForm />
            </div>
        </div>
        <LoginLinks />
        <LanguageSelector className="login__language-selector" small />
    </>
);

const LoginLinks = () => (
    <div className="login__links">
        <a
            className="login__link"
            href="https://team-psychology.com/impressum/"
        >{i18n`Impressum`}</a>
        <a
            className="login__link"
            href="https://team-psychology.com/datenschutz/"
        >{i18n`Datenschutz`}</a>
    </div>
);

export default Login;
