import * as React from 'react';
import { TabsContext } from './tabs.component';

export const TabHeader = ({ children, index, onSelect }) => {
    return (
        <TabsContext.Consumer>
            {selectedTab => (
                <div
                    className={`tabs__tab-header ${
                        selectedTab === index ? 'tabs__tab-header--selected' : ''
                    }`}
                    onClick={onSelect}
                >
                    {children}
                    <div className="tabs__tab-header-line" />
                </div>
            )}
        </TabsContext.Consumer>
    );
};
