import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Button from '../../../../common/ui-components/button/button.component';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import './delete-team-dialog.component.scss';

const DeleteTeamDialog = ({ onCancel, onConfirm }) => (
    <div
        className="delete-team-dialog"
        onClick={e => {
            e.preventDefault();
            e.stopPropagation();
        }}
    >
        <h1 className="delete-team-dialog__title">{i18n`Team löschen?`}</h1>
        <div className="delete-team-dialog__content">
            <Button title="Nein" onClick={onCancel} />
            <PrimaryButton title="Ja" onClick={onConfirm} />
        </div>
    </div>
);

export default DeleteTeamDialog;
