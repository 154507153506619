import * as React from 'react';
import './table.component.scss';

export interface TableProps {
    className?: string;
    children;
}

const Table = ({ className, children }: TableProps) => (
    <div className={`table ${className || ''}`}>{children}</div>
);

export default Table;

export enum SortDirection {
    None,
    Asc,
    Desc
}
