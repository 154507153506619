import * as React from 'react';
import { AsyncState, useAsync } from 'react-async';
import { match as Match, RouteComponentProps, withRouter } from 'react-router';
import { AsyncErrorHandling } from '../../../common/async';
import { TeamModel } from '../models/team-model';
import * as teamService from '../team-service';

const getTeam = ({ id }) => teamService.getTeam(id);

export interface FetchTeamProps {
    children: (team: TeamModel, state: AsyncState<TeamModel>) => React.ReactNode;
}

interface FetchTeamRouteProps {
    match: Match<FetchTeamParams>;
}

export interface FetchTeamParams {
    teamId: string;
}

export const FetchTeam = ({ match, children }: FetchTeamProps & FetchTeamRouteProps) => {
    const state = useAsync(getTeam, { id: match.params.teamId });

    if (state.error != null) {
        return <AsyncErrorHandling error={state.error} />;
    }
    if (state.data == null) {
        return <></>;
    }

    return <>{children(state.data, state)}</>;
};

export default withRouter(
    FetchTeam as React.ComponentType<FetchTeamProps & RouteComponentProps<FetchTeamParams>>
) as React.ComponentType<FetchTeamProps>;
