import * as React from 'react';
import { useState } from 'react';
import './radio-button.component.scss';

let radioButtonId = 1;

export type RadioButtonProps = {
    group: string;
    label: string;
    className?: string;
} & any;

const RadioButton = ({ group, label, className, ...props }: RadioButtonProps) => {
    const [id] = useState(radioButtonId++);

    return (
        <label
            className={`radio-button ${className} ${props.disabled && 'radio-button--disabled'}`}
            htmlFor={`radio-button-${id}`}
            title={props.title}
        >
            <input
                className="radio-button__input"
                type="radio"
                name={group}
                id={`radio-button-${id}`}
                {...props}
            />
            <div className="radio-button__checkbox" />
            <span className="radio-button__label">{label}</span>
        </label>
    );
};

export default RadioButton;
