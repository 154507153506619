import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Button from '../../../../../common/ui-components/button/button.component';
import PrimaryButton from '../../../../../common/ui-components/primary-button/primary-button.component';
import './remind-members-dialog.component.scss';

export const RemindMembersDialog = ({ onCancel, onConfirm }) => (
    <div className="remind-members-dialog">
        <h1 className="remind-members-dialog__title">{i18n`Mitglieder an Umfrage erinnern?`}</h1>
        <p className="remind-members-dialog__content">
            {i18n`Alle Mitglieder die nicht geantwortet haben empfangen eine Erinnerungs E-Mail.`}
        </p>
        <div className="remind-members-dialog__actions">
            <Button title="Nein" onClick={onCancel} />
            <PrimaryButton title="Ja" onClick={onConfirm} />
        </div>
    </div>
);
