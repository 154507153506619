import * as React from 'react';
import './button.component.scss';

type ButtonProps = {
    title: string;
    className?: string;
    onClick?: () => void;
} & any;

const Button = ({ className, title, ...props }: ButtonProps) => (
    <button className={`btn ${className || ''}`} type="button" {...props}>
        {title}
    </button>
);

export default Button;
