import { saveAs } from 'file-saver';
import { http, httpGet, httpPut } from '../../common/http-client';
import { ReportModel } from './models/report-model';

export async function getReport(teamId: string, surveyRunId: string): Promise<ReportModel> {
    return httpGet(`/api/teams/${teamId}/runs/${surveyRunId}/report`);
}

export async function updateReport(report: ReportModel): Promise<void> {
    await httpPut(`/api/reports/${report.id}`, report);
}

export async function downloadReport(teamId: string, surveyRunId: string) {
    const res = await http(`/api/teams/${teamId}/runs/${surveyRunId}/report/print`);
    const data = await res.blob();
    saveAs(data, 'report.pdf');
}
