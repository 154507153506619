import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { withRouter } from 'react-router';
import { withAuth } from '../../../domains/auth/context/auth-context';
import './header.component.scss';
import LanguageSelector from '../../../i18n/language-selector';

const logo = require('../../../../assets/team-psychology-portal.svg');

const UserHeader = withAuth()(({ user, logout }) => (
    <div className="header__user-area">
        {user && (
            <>
                <LanguageSelector className="header__language-selector" small />
                <span className="header__user-name">{i18n`Hallo ${user.firstName}`}</span>
                <button className="header__logout" onClick={logout}>
                    {i18n`Ausloggen`}
                </button>
            </>
        )}
    </div>
));

const Header = ({ backArrow, className }) => (
    <div className={`header ${className}`}>
        <div className="header__back-arrow">{backArrow && <BackArrow />}</div>
        <img className="header__logo" src={logo} />
        <UserHeader />
    </div>
);

const BackArrow = withRouter(({ history }) => (
    <button className="header__back-arrow-btn" onClick={() => history.goBack()}>
        <Icon path={mdiArrowLeft} size={1.5} />
    </button>
));

export default Header;
