import * as React from 'react';

export interface TabProps {
    header: React.ReactNode;
    children: React.ReactNode;
}

const Tab = (props: TabProps) => <></>;

export default Tab;
