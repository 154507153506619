import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import './compare-question-selection.component.scss';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import { mdiClose } from '@mdi/js';

export interface CompareQuestionSelectionProps {
    comparisons: string[];
    selectedComparison: string;
    onSelect: (value: string) => void;
    onCancel: () => void;
}

export function CompareQuestionSelection({
    comparisons,
    selectedComparison,
    onSelect,
    onCancel
}: CompareQuestionSelectionProps) {
    const selections = [...comparisons].sort().reverse();
    return (
        <div className="question-analysis__compare-chart-selection">
            <div>
                <label>{i18n('report')`Vergleich auswählen`}</label>
                <IconButton
                    title={i18n`Schließen`}
                    className="question-analysis__compare-chart-close"
                    onClick={() => onCancel()}
                    icon={mdiClose}
                />
            </div>
            <select
                value={selectedComparison || ''}
                onChange={e => onSelect(e.target.value)}
                onBlur={() => onCancel()}
            >
                <option value={null}>{i18n('report')`Keine Auswahl`}</option>
                {selections.map((item, i) => (
                    <option key={i} value={item}>
                        {i18n`${new Date(item)}:t(y)`}
                    </option>
                ))}
            </select>
        </div>
    );
}
