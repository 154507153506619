import * as React from 'react';
import { AsyncState } from 'react-async';
import { match as Match, RouteComponentProps, withRouter } from 'react-router';
import { Async, AsyncErrorHandling, Rejected, Resolved } from '../../../common/async';
import * as accountService from '../account-service';
import { AccountModel } from '../models/account-model';

const getAccount = ({ id }): Promise<AccountModel> => accountService.getAdminAccount(id);

export interface FetchAccountProps {
    children: (account: AccountModel, state: AsyncState<AccountModel>) => React.ReactNode;
}

interface FetchAccountRouteProps {
    match: Match<FetchAccountParams>;
}

export interface FetchAccountParams {
    accountId: string;
}

const FetchAccount = ({ match, children }: FetchAccountProps & FetchAccountRouteProps) => (
    <Async promiseFn={getAccount} id={match.params.accountId}>
        <Resolved>{children}</Resolved>
        <Rejected>{error => <AsyncErrorHandling error={error} />}</Rejected>
    </Async>
);

export default withRouter(
    FetchAccount as React.ComponentType<FetchAccountProps & RouteComponentProps<FetchAccountParams>>
) as React.ComponentType<FetchAccountProps>;
