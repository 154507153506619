import i18n from 'es2015-i18n-tag';
import { Field, Form, FormikBag, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'ramda';
import Input from '../../../../common/ui-components/input/input.component';
import { SportTeamCreateModel } from '../../models/sport-team-create-model';
import { getSportTemplates } from '../../team-service';
import CreateTeam, { CreateTeamFormValue, formSchema } from './create-team.component';

export interface CreateSportTeamProps {
    createTeam: (model: SportTeamCreateModel) => Promise<void>;
}

const onCreate = (
    formValue: CreateTeamFormValue,
    actions: FormikBag<CreateSportTeamProps & RouteComponentProps, CreateTeamFormValue>
) => {
    const { name, sport, league, survey } = formValue;
    actions.props
        .createTeam({
            name,
            league,
            sport,
            templateKey: survey.templateKey,
            hasSixPossibleAnswers: survey.hasSixPossibleAnswers,
            isPrinorQuest: survey.isPrinorQuest
        })
        .then(() => {
            actions.props.history.goBack();
        })
        .finally(() => {
            actions.setSubmitting(false);
        });
};

const CreateSportTeam = (props: FormikProps<CreateTeamFormValue>) => {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        getSportTemplates().then(res => {
            props.setFieldValue('survey', res[0]);
            setTemplates(res);
        });
    }, []);

    return (
        <CreateTeam {...props} templates={templates}>
            <Field
                component={Input}
                title={i18n`Sportart`}
                name="sport"
                className="create-team__form-field"
                required
            />
            <Field
                component={Input}
                title={i18n`Ligazugehörigkeit`}
                name="league"
                className="create-team__form-field"
                required
            />
        </CreateTeam>
    );
};

const enhance = compose(
    withRouter,
    withFormik({
        displayName: 'CreateSportTeam',
        validationSchema: formSchema,
        handleSubmit: onCreate,
        mapPropsToValues: () => ({
            name: '',
            sport: '',
            league: '',
            survey: null
        })
    })
);

export default enhance(CreateSportTeam);
