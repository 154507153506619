import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { ReportModel } from '../../models/report-model';
import { useState } from 'react';

interface ReportTitlePageProps {
    report: ReportModel;
    teamId: string;
}

const Introduction = ({ isBusiness, hasSixPossibleAnswers }) => {
    const introduction = getIntroductionText(isBusiness, hasSixPossibleAnswers);
    return (
        <>
            {introduction.map((text, i) => (
                <p className="report__introduction-line" key={i}>
                    {text}
                </p>
            ))}
        </>
    );
};

function getIntroductionText(isBusiness: boolean, hasSixPossibleAnswers: boolean): string[] {
    let introductionText: string;

    if (isBusiness) {
        introductionText = getBusinessIntroductionText(hasSixPossibleAnswers);
    } else {
        introductionText = getSportIntruductionText(hasSixPossibleAnswers);
    }

    return introductionText.split('\n');
}

function getBusinessIntroductionText(hasSixPossibleAnswers: boolean): string {
    if (hasSixPossibleAnswers) {
        return i18n('report')`BUSINESS_INTRODUCTION_V2`;
    } else {
        return i18n('report')`BUSINESS_INTRODUCTION`;
    }
}

function getSportIntruductionText(hasSixPossibleAnswers: boolean): string {
    if (hasSixPossibleAnswers) {
        return i18n('report')`SPORT_INTRODUCTION_V2`;
    } else {
        return i18n('report')`SPORT_INTRODUCTION`;
    }
}

export const ReportTitlePage = ({ report, teamId }: ReportTitlePageProps) => {
    const [hasImage, setHasImage] = useState(true);
    const creationDate = new Date(report.created);
    const subtitle = i18n('report')`${report.team} Erhebung vom ${creationDate}:t(d)`;

    return (
        <div className="report__title-page">
            <h1 className="report__title">{i18n('report')`Teamdiagnose-Report`}</h1>
            <div className="report__logo-area">
                <img
                    className={`report__team-logo ${hasImage || 'report__team-logo--hide'}`}
                    src={`/api/teams/${teamId}/logo?time=${Date.now()}`}
                    onError={() => setHasImage(false)}
                />
            </div>
            <h2 className="report__subtitle">{subtitle}</h2>
            <Introduction
                isBusiness={report.isBusiness}
                hasSixPossibleAnswers={report.hasSixPossibleAnswers}
            />
        </div>
    );
};
