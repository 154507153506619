import { mdiFileRestore } from '@mdi/js';
import * as React from 'react';
import i18n from 'es2015-i18n-tag';
import { useState } from 'react';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import Dialog from '../../../../../common/ui-components/dialog/dialog.component';
import { DeleteReportDialog } from '../delete-report-dialog/delete-report-dialog.component';

export const DeleteReportButton = ({ onClick }) => {
    const [showDialog, setShowDialog] = useState(false);
    const onCancel = event => {
        event.preventDefault();
        event.stopPropagation();
        setShowDialog(false);
    };
    const onConfirm = event => {
        event.preventDefault();
        event.stopPropagation();
        setShowDialog(false);
        onClick();
    };
    return (
        <>
            <IconButton
                icon={mdiFileRestore}
                title={i18n`Report wird gelöscht und neu erstellt`}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDialog(true);
                }}
            />
            <Dialog isOpen={showDialog}>
                <DeleteReportDialog onCancel={onCancel} onConfirm={onConfirm} />
            </Dialog>
        </>
    );
};
