export function getMaxValueForScale(hasSixPossibleAnswers: boolean) {
    if (hasSixPossibleAnswers) {
        return 6;
    }
    return 3;
}

export function getMaxValueForTrends(hasSixPossibleAnswers: boolean) {
    if (hasSixPossibleAnswers) {
        return 5;
    }
    return 3;
}
