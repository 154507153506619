import * as React from 'react';

export const ReportFooter = () => (
    <div className="report__footer">
        <span className="report__footer-copyright">
            © mps consulting GmbH - Föhrenweg 26 - D-29308 Winsen-Aller - GERMANY
        </span>
        <a href="https://www.loups-team.com/" className="report__footer-link">
            www.loups-team.com
        </a>
    </div>
);
