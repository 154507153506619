import i18n from 'es2015-i18n-tag';
import { Field, FormikBag, FormikProps, withFormik } from 'formik';
import { compose } from 'ramda';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as yup from 'yup';
import Input from '../../../../common/ui-components/input/input.component';
import { AuthState } from '../../../auth/context/auth-context';
import { TeamAdvisor, TeamModel } from '../../models/team-model';
import FetchTeam from '../../resolver/fetch-team.component';
import { updateTeam } from '../../team-service';
import EditTeam from './edit-team.component';
import { withRouter } from 'react-router-dom';

interface EditBusinessTeamFormValue {
    name: string;
    sport: string;
    league: string;
    advisor?: TeamAdvisor;
}

const formSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .required(),
    sport: yup
        .string()
        .trim()
        .required(),
    league: yup
        .string()
        .trim()
        .required(),
    advisor: yup.object().nullable(true)
});

type EditBusinessTeamProps = {
    team: TeamModel;
} & RouteComponentProps;

type EditBusinessTeam = EditBusinessTeamProps & FormikProps<EditBusinessTeamFormValue> & AuthState;

const onSave = (
    formValue: EditBusinessTeamFormValue,
    { props, setSubmitting }: FormikBag<EditBusinessTeamProps, EditBusinessTeamFormValue>
) => {
    const { id } = props.team;
    const { name, sport, league, advisor } = formValue;
    updateTeam({
        id,
        name,
        sport,
        league,
        isBusiness: true,
        advisor,
        members: props.team.members,
        accountId: props.team.accountId
    })
        .then(() => {
            props.history.goBack();
        })
        .finally(() => setSubmitting(false));
};

const EditBusinessTeam = (props: EditBusinessTeam) => {
    return (
        <EditTeam advisorLabel={i18n`Berater`} {...props}>
            <Field
                component={Input}
                title={i18n`Firma`}
                name="sport"
                className="edit-team__form-field"
                required
            />
            <Field
                component={Input}
                title={i18n`Standort`}
                name="league"
                className="edit-team__form-field"
                required
            />
        </EditTeam>
    );
};

const enhance = compose(
    withRouter,
    withFormik({
        displayName: 'EditBusinessTeam',
        validationSchema: formSchema,
        handleSubmit: onSave,
        mapPropsToValues: ({ team }) => ({
            name: team.name,
            sport: team.sport,
            league: team.league,
            advisor: team.advisor
        })
    })
);

const Editor = enhance(EditBusinessTeam);

// @ts-ignore
export default () => <FetchTeam>{team => <Editor team={team} />}</FetchTeam>;
