import { httpDelete, httpGet, httpPost, httpPut } from '../../common/http-client';
import { TeamModel } from '../teams/models/team-model';
import { UserModel } from '../users/models/user-model';
import { AccountCreateModel } from './models/account-create-model';
import { AccountModel } from './models/account-model';
import { AccountUpdateModel } from './models/account-update-model';

export async function createAccount(account: AccountCreateModel) {
    await httpPost('/api/admin/accounts', account);
}

export async function updateAccount(account: AccountUpdateModel) {
    await httpPut(`/api/admin/accounts/${account.id}`, account);
}

export async function listAccounts(): Promise<AccountModel[]> {
    return await httpGet('/api/admin/accounts');
}

export async function deleteAccount(id: string): Promise<void> {
    await httpDelete(`/api/admin/accounts/${id}`);
}

export async function getAdminAccount(id: string): Promise<AccountModel> {
    const account = await httpGet<AccountModel>(`/api/admin/accounts/${id}`);
    if (account.validUntil != null) {
        account.validUntil = new Date(account.validUntil);
    }
    if (account.validFrom != null) {
        account.validFrom = new Date(account.validFrom);
    }

    return account;
}

export async function getMyAccount(): Promise<AccountModel> {
    const account = await httpGet<AccountModel>('/api/account');
    if (account.validUntil != null) {
        account.validUntil = new Date(account.validUntil);
    }
    if (account.validFrom != null) {
        account.validFrom = new Date(account.validFrom);
    }

    return account;
}

export function getAccountTeams(id: string): Promise<TeamModel[]> {
    return httpGet(`/api/admin/accounts/${id}/teams`);
}

export function getAccountUsers(id: string): Promise<UserModel[]> {
    return httpGet(`/api/admin/accounts/${id}/users`);
}
