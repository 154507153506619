import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '../button/button.component';

export type BackButtonProps = {
    className?: string;
} & RouteComponentProps;

export const BackButton = ({ history, className }: BackButtonProps) => (
    <Button
        title={i18n`Abbrechen`}
        type="button"
        className={className}
        onClick={() => history.goBack()}
    />
);

export default withRouter(BackButton);
