import i18n from 'es2015-i18n-tag';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../../common/ui-components/checkbox/checkbox.component';
import { TableColumn } from '../../../common/ui-components/table';
import { UserModel } from '../models/user-model';
import * as userService from '../user-service';
import ListUsers from './list-users.component';
import { ViewAccountUser } from './view-account-user-row.component';
import { AuthState, withAuth } from '../../auth/context/auth-context';

const ListAccountUsers = ({ user: currentUser }: AuthState) => {
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        userService.getAccountUsers().then(setUsers);
    }, []);

    const addUser = (user: UserModel) => {
        return userService.addAccountUser(user);
    };

    return (
        <ListUsers
            users={users}
            header={<TableColumn width="25px">{i18n`Account Manager`}</TableColumn>}
            addUser={addUser}
            accountId={currentUser.accountId}
            updateUser={userService.updateAccountUser}
            edit={(user, onUpdate, disabled) => (
                <TableColumn width="25px">
                    <Checkbox
                        checked={user.isAccountManager}
                        type="checkbox"
                        disabled={disabled || user.isAccountManager}
                        onChange={() => onUpdate({ isAccountManager: !user.isAccountManager })}
                        title={
                            user.isAccountManager
                                ? i18n`Diesem User können die AccountManager-Rechte nicht entzogen werden`
                                : ''
                        }
                    />
                </TableColumn>
            )}
        >
            {({ user, editUser, updateUser }) => (
                <ViewAccountUser user={user} onEdit={editUser} onUpdate={updateUser} />
            )}
        </ListUsers>
    );
};

export default withAuth()(ListAccountUsers);
