import { mdiAccount, mdiPencil } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Checkbox from '../../../common/ui-components/checkbox/checkbox.component';
import IconButton from '../../../common/ui-components/icon-button/icon-button.component';
import TableColumn from '../../../common/ui-components/table/table-column.component';
import { AuthState, Role, withAuth } from '../../auth/context/auth-context';
import { UserModel } from '../models/user-model';
import DisableUserButton from './disable-user-button.component';

export interface ViewUserProps {
    user: UserModel;
    onEdit: () => void;
    onUpdate: ({ disabled }: { disabled: boolean }) => void;
}

export const ViewAdminUser = ({ user, onEdit, onUpdate }: ViewUserProps) => (
    <>
        <TableColumn width={1}>{user.firstName}</TableColumn>
        <TableColumn width={1}>{user.lastName}</TableColumn>
        <TableColumn width={1}>
            <a className="list-users__detail-link" href={`mailto:${user.email}`}>
                {user.email}
            </a>
        </TableColumn>
        <TableColumn width={1}>
            {user.phone && (
                <a className="list-users__detail-link" href={`tel:${user.phone}`}>
                    {user.phone}
                </a>
            )}
        </TableColumn>
        <TableColumn width={1}>{user.accountName}</TableColumn>
        <TableColumn width="25px">
            <Checkbox checked={user.isAdmin} type="checkbox" disabled={true} />
        </TableColumn>
        <TableColumn width="50px" style={{ justifyContent: 'flex-end' }}>
            {!user.disabled && <EditUserButton onEdit={onEdit} user={user} />}
            {!user.disabled && (
                <DisableUserButton onDisable={() => onUpdate({ disabled: true })} user={user} />
            )}
            {user.disabled && <EnableUserButton onEnable={() => onUpdate({ disabled: false })} />}
        </TableColumn>
    </>
);

interface EditUserBaseProps {
    onEdit: () => void;
    user: UserModel;
}

const editUserAuthMapper = (auth: AuthState, component: EditUserBaseProps): any => ({
    ...component,
    isOwner: auth.user.role === Role.Owner
});

const EditUserButton: any = withAuth(editUserAuthMapper)(({ onEdit, user, isOwner }) => {
    const canEdit = user.isOwner ? isOwner : true;
    return (
        <IconButton
            title={canEdit ? i18n`User bearbeiten` : i18n`Dieser User kann nicht bearbeitet werden`}
            disabled={!canEdit}
            onClick={onEdit}
            icon={mdiPencil}
        />
    );
});

const EnableUserButton = ({ onEnable }) => (
    <IconButton title={i18n`User aktivieren`} icon={mdiAccount} onClick={onEnable} />
);
