import * as React from 'react';
import { useState } from 'react';
import './select.component.scss';

export type SelectItem = string | { label: string; value: any };

type SelectProps = {
    title: string;
    items: SelectItem[];
    className?: string;
} & any;

let selectId = 1;

function getLabel(item: SelectItem): string {
    if (typeof item === 'string') {
        return item;
    }
    return item.label;
}

function serializeValue(item: SelectItem): string {
    if (typeof item === 'string') {
        return JSON.stringify(item);
    }
    return JSON.stringify(item.value);
}

function deserializeValue(value: string): any {
    return JSON.parse(value);
}

const Select = ({
    title,
    className,
    items,
    required,
    field,
    form,
    small,
    ...props
}: SelectProps) => {
    const [id] = useState(selectId++);

    const name = field ? field.name : props.name;
    const onChange = event => {
        const deserializedValue = deserializeValue(event.target.value);
        if (field) {
            form.setFieldValue(field.name, deserializedValue);
        } else {
            props.onChange(deserializedValue);
        }
    };
    const onBlur = field ? field.onBlur : props.onBlur;
    const value = field ? field.value : props.value;
    const serializedValue = value != null ? JSON.stringify(value) : '';

    return (
        <div className={`select ${className || ''} ${small && 'select--small'}`}>
            {title && (
                <label htmlFor={`select-${id}`} className="select__label">
                    {title}
                    {required && '*'}
                </label>
            )}
            <select
                {...props}
                id={`select-${id}`}
                name={name}
                className="select__field"
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                value={serializedValue}
            >
                {items.map((item, i) => (
                    <option key={i} value={serializeValue(item)}>
                        {getLabel(item)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;
