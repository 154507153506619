import * as React from 'react';
import { Async, AsyncErrorHandling, Rejected, Resolved } from '../../../common/async';
import { getMyTeams } from '../team-service';

const FetchMyTeams = ({ children }) => (
    <Async promiseFn={getMyTeams}>
        <Resolved>{children}</Resolved>
        <Rejected>{error => <AsyncErrorHandling error={error} />}</Rejected>
    </Async>
);

export default FetchMyTeams;
