import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Chart from 'react-google-charts';
import { getMaxValueForScale } from '../../chart-helpers';
import { FactorModel } from '../../models/report-model';

export interface FactorChartProps {
    title: string;
    data: FactorModel[];
    lang: string;
    hasSixPossibleAnswers: boolean;
}

class FactorChart extends React.Component<FactorChartProps> {
    shouldComponentUpdate(nextProps: Readonly<FactorChartProps>): boolean {
        const sameTitle = this.props.title === nextProps.title;
        const sameData = this.props.data === nextProps.data;
        const sameLanguage = this.props.lang === nextProps.lang;
        const skipUpdate = sameTitle && sameData && sameLanguage;
        return !skipUpdate;
    }

    render() {
        const { title, data, lang, hasSixPossibleAnswers } = this.props;
        const series = {};
        const titles = ['', i18n('report')`Varianz`, i18n('report')`Mittelwert`];
        if (hasSixPossibleAnswers) {
            series[2] = { type: 'line', visibleInLegend: false, lineDashStyle: [4, 4] };
            titles.push('');
        }
        return (
            <div className="group-dynamic-factors__diagram">
                <h4 className="group-dynamic-factors__diagram-title">{title}</h4>
                <Chart
                    height="500px"
                    chartType="BarChart"
                    data={[
                        titles,
                        ...data.map(({ title: factorTitle, variance, average }) => {
                            const factor = [factorTitle[lang], variance ?? 0, average ?? 0];
                            if (hasSixPossibleAnswers) {
                                factor.push(5);
                            }
                            return factor;
                        })
                    ]}
                    options={{
                        enableInteractivity: false,
                        colors: ['#00b0f0', '#50f2b4', '#50f2b4'],
                        fontName: 'Dosis',
                        hAxis: {
                            maxValue: getMaxValueForScale(hasSixPossibleAnswers),
                            minValue: 0
                        },
                        chartArea: { height: '90%', width: '55%' },
                        series
                    }}
                />
            </div>
        );
    }
}

export default FactorChart;
