import * as React from 'react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';
import TrendTimeSelectionHandles from './handles.component';
import TrendTimeSelectionRail from './rail.component';
import TrendTimeSelectionTicks from './ticks.component';
import TrendTimeSelectionTracks from './tracks.component';
import './trend-time-selection.component.scss';

export interface TrendTimeSelectionProps {
    dates: string[];
    selectedDates: string[];
    onSelectRange: (dates: string[]) => void;
}

const TrendTimeSelection = ({ dates, selectedDates, onSelectRange }: TrendTimeSelectionProps) => {
    const handleChange = ([start, end]: [number, number]) => {
        const range = dates.filter((d, i) => start <= i && end >= i).map(d => d);
        onSelectRange(range);
    };
    const values = [
        dates.findIndex(d => d === selectedDates[0]),
        dates.findIndex(d => d === selectedDates[selectedDates.length - 1])
    ];
    return (
        <Slider
            domain={[0, dates.length - 1]}
            step={1}
            values={values}
            className="trend-time-selection"
            onChange={handleChange}
        >
            <Rail>{({ getRailProps }) => <TrendTimeSelectionRail {...getRailProps()} />}</Rail>
            <Tracks>{({ tracks }) => <TrendTimeSelectionTracks tracks={tracks} />}</Tracks>
            <Handles>{props => <TrendTimeSelectionHandles {...props} />}</Handles>
            <Ticks values={dates.map((d, i) => i)}>
                {({ ticks }) => <TrendTimeSelectionTicks ticks={ticks} dates={dates} />}
            </Ticks>
        </Slider>
    );
};

export default TrendTimeSelection;
