import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import { AsyncState } from 'react-async';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import Tab from '../../../../common/ui-components/tabs/tab.component';
import Tabs from '../../../../common/ui-components/tabs/tabs.component';
import { SurveyRunModel, TeamMember, TeamModel } from '../../models/team-model';
import FetchTeam from '../../resolver/fetch-team.component';
import {
    deleteReport,
    remindMembers,
    startSurveyRun,
    stopSurveyRun,
    updateTeam
} from '../../team-service';
import MemberList from './member-list/member-list.component';
import SurveyList from './survey-list/survey-list.component';
import './view-team.component.scss';
import { useNotification } from '../../../../common/ui-components/notification';

const REPORT_TAB = 0;
const MEMBER_TAB = 1;

const delay = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));

interface ViewTeamProps {
    team: TeamModel;
    reload: () => void;
}

interface ViewTeamState {
    members: TeamMember[];
    editingMember: number;
    openTab: number;
    isNewMember: boolean;
}

class ViewTeam extends React.Component<ViewTeamProps, ViewTeamState> {
    state: ViewTeamState = {
        members: this.props.team.members || [],
        editingMember: -1,
        openTab: 0,
        isNewMember: false
    };

    render() {
        const { team } = this.props;
        const reportTabActive = this.state.openTab === REPORT_TAB;
        const memberTabActive = this.state.openTab === MEMBER_TAB;

        return (
            <div className="view-team">
                <div className="view-team__header">
                    <span className="view-team__details">
                        {team.sport} - {team.league}
                    </span>
                    {team.isPrinorQuest && reportTabActive && (
                        <StartSurveyRunButton
                            disabled={!!team.runs.find(r => !r.completed) || !team.canStartRun}
                            handleStart={this.startSurveyRun}
                        />
                    )}
                    {team.isPrinorQuest && memberTabActive && (
                        <PrimaryButton
                            className="view-team__add-member-btn"
                            title={i18n`Teammitglied anlegen`}
                            onClick={this.addMember}
                        />
                    )}
                </div>
                <Tabs
                    className="view-team__tabs"
                    onTabChange={tab => this.setState({ ...this.state, openTab: tab })}
                >
                    <Tab
                        header={i18n`Befragungen (${
                            team.runs.filter(r => !r.completed).length
                        } Offen)`}
                    >
                        <SurveyList
                            disabled={!team.isPrinorQuest}
                            surveyRuns={team.runs}
                            teamId={team.id}
                            onCloseSurvey={this.closeSurvey}
                            onRemindMembers={this.remindSurveyMembers}
                            onDeleteReport={this.deleteReport}
                        />
                    </Tab>
                    <Tab header={i18n`Mitglieder (${this.state.members.length})`}>
                        <MemberList
                            disabled={!team.isPrinorQuest}
                            editing={this.state.editingMember}
                            members={this.state.members}
                            openSurveys={team.runs.filter(r => !r.completed).length > 0}
                            onEdit={this.editMember}
                            onSave={this.saveMember}
                            onCancel={this.cancelEditing}
                            onDelete={this.deleteMember}
                        />
                    </Tab>
                </Tabs>
            </div>
        );
    }

    private startSurveyRun = () =>
        startSurveyRun(this.props.team.id)
            // Delay added so backend is guaranteed to be consistent
            .then(() => delay(500))
            .then(() => this.props.reload());

    private addMember = () => {
        const newMember: TeamMember = {
            firstName: '',
            lastName: '',
            role: '',
            email: ''
        };
        const members = [...this.state.members, newMember];
        this.setState({
            ...this.state,
            members,
            editingMember: this.state.members.length,
            isNewMember: true
        });
    };

    private closeSurvey = (run: SurveyRunModel) =>
        stopSurveyRun(this.props.team, run)
            // Delay added so backend is guaranteed to be consistent
            .then(() => delay(500))
            .then(() => this.props.reload());

    private remindSurveyMembers = (run: SurveyRunModel) => remindMembers(this.props.team, run);

    private deleteReport = (run: SurveyRunModel) => {
        return deleteReport(this.props.team, run);
    };

    private editMember = (index: number) => {
        this.setState({
            ...this.state,
            editingMember: index,
            isNewMember: false
        });
    };

    private saveMember = (member: TeamMember, index: number) => {
        const members = this.state.members.map((m, i) => {
            if (i !== index) {
                return m;
            }
            return member;
        });
        this.setState({ ...this.state, members, editingMember: -1 });
        return this.updateTeam(members);
    };

    private cancelEditing = () => {
        if (this.state.isNewMember) {
            this.setState({
                ...this.state,
                members: this.state.members.slice(0, this.state.editingMember),
                editingMember: -1,
                isNewMember: false
            });
        } else {
            this.setState({
                ...this.state,
                editingMember: -1,
                isNewMember: false
            });
        }
    };

    private deleteMember = (memberIndex: number) => {
        const members = this.state.members.filter((m, i) => i !== memberIndex);
        this.setMembers(members);
        return this.updateTeam(members);
    };

    private setMembers = (members: TeamMember[]) => {
        this.setState({
            ...this.state,
            members
        });
    };

    private updateTeam = (members: TeamMember[]) =>
        updateTeam({
            ...this.props.team,
            members
        });
}

interface StartSurveyRunButtonProps {
    disabled: boolean;
    handleStart: () => Promise<void>;
}

const StartSurveyRunButton = ({ disabled, handleStart }: StartSurveyRunButtonProps) => {
    const [pending, setPending] = useState(false);
    const showNotification = useNotification();

    const clickHandler = () => {
        setPending(true);
        handleStart()
            .then(
                () => showNotification(i18n`Befragung wurde gestartet`),
                () => showNotification(i18n`Starten der Befragung ist fehlgeschlagen`)
            )
            .finally(() => setPending(false));
    };

    return (
        <PrimaryButton
            className="view-team__start-run-btn"
            pending={pending}
            title={i18n`Befragung durchführen`}
            disabled={pending || disabled}
            onClick={clickHandler}
        />
    );
};

export default () => (
    <FetchTeam>
        {(team: TeamModel, state: AsyncState<TeamModel>) => (
            <ViewTeam team={team} reload={state.reload} />
        )}
    </FetchTeam>
);
