import * as React from 'react';

export enum Role {
    Advisor = 1,
    AccountManager = 2,
    Admin = 4,
    Owner = 8
}

export function hasAnyRole(user: CurrentUserModel, ...roles: Role[]): boolean {
    // tslint:disable-next-line:no-bitwise
    return roles.some(role => (user.role & role) === role);
}

export interface CurrentUserModel {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    role: Role;
    language?: string;
    accountId?: string;
}

export interface AuthState {
    loggedIn: boolean;
    user?: CurrentUserModel;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = React.createContext<AuthState>({
    loggedIn: false,
    login: () => {},
    logout: () => {}
});

type AuthPropMapper<P = object, R = Partial<P & AuthState>> = (
    authState: AuthState,
    componentProps: P
) => R;

const defaultMapper: AuthPropMapper = (authState, componentProps) => ({
    ...authState,
    ...componentProps
});

export const withAuth = (propMapper: AuthPropMapper = defaultMapper) => (
    Component
): React.ComponentType => componentProps => (
    <AuthContext.Consumer>
        {state => <Component {...propMapper(state, componentProps)} />}
    </AuthContext.Consumer>
);

export default AuthContext;
