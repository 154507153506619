import i18n from 'es2015-i18n-tag';
import TableColumn from '../../../../../common/ui-components/table/table-column.component';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import { mdiDelete, mdiPencil } from '@mdi/js';
import * as React from 'react';
import { useState } from 'react';
import { TeamMember } from '../../../models/team-model';
import Dialog from '../../../../../common/ui-components/dialog/dialog.component';
import { DeleteMemberDialog } from './delete-member-dialog.component';

export interface ViewMemberProps {
    member: TeamMember;
    disabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
}

export const ViewMember = ({ disabled, member, onEdit, onDelete }: ViewMemberProps) => (
    <>
        <TableColumn width={1}>{member.firstName}</TableColumn>
        <TableColumn width={1}>{member.lastName}</TableColumn>
        <TableColumn width={1}>{member.role}</TableColumn>
        <TableColumn width={1}>
            <a href={`mailto:${member.email}`}>{member.email}</a>
        </TableColumn>
        <TableColumn width="50px">
            {!disabled && (
                <IconButton title={i18n`Mitglied bearbeiten`} icon={mdiPencil} onClick={onEdit} />
            )}
            {!disabled && <DeleteMember title={i18n`Mitglied löschen`} onDelete={onDelete} />}
        </TableColumn>
    </>
);

const DeleteMember = ({ onDelete, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);
    return (
        <>
            <IconButton icon={mdiDelete} onClick={() => setShowDialog(true)} {...props} />
            <Dialog isOpen={showDialog} onRequestClose={() => setShowDialog(false)}>
                <DeleteMemberDialog
                    onCancel={() => setShowDialog(false)}
                    onConfirm={() => {
                        onDelete();
                        setShowDialog(false);
                    }}
                />
            </Dialog>
        </>
    );
};
