import { httpGet, httpPost, httpPut } from '../../common/http-client';
import { UserModel } from './models/user-model';

export async function getUsers(): Promise<UserModel[]> {
    return httpGet('/api/admin/users');
}

export async function getAccountUsers(): Promise<UserModel[]> {
    return httpGet('/api/account/users');
}

export async function updateUser(user: UserModel): Promise<void> {
    await httpPut(`/api/admin/users/${user.id}`, user);
}

export async function updateAccountUser(user: UserModel): Promise<void> {
    await httpPut(`/api/account/users/${user.id}`, user);
}

export async function addUser(user: UserModel): Promise<string> {
    const res = await httpPost('/api/admin/users', user);

    return await res.json();
}

export async function addAccountUser(user: UserModel): Promise<string> {
    const res = await httpPost('/api/account/users', user);

    return await res.json();
}

export async function updateUserLanguage(lang: string): Promise<void> {
    await httpPut('/api/users/current/language', lang);
}
