import * as React from 'react';
import { TableRow, TableColumn } from '../../../../../common/ui-components/table';
import { getAdvisorDisplayName, TeamModel } from '../../../models/team-model';
import TeamEditButton from '../../../components/team-edit-button.component';
import TeamDeleteButton from '../team-delete-button.component';

export interface TeamListRowProps {
    team: TeamModel;
    onDelete: () => void;
    showAccountColumn?: boolean;
}

const BusinessTeamListRow = ({ team, onDelete, showAccountColumn, ...props }: TeamListRowProps) => (
    <TableRow link={`/teams/business/${team.id}`} {...props}>
        <TableColumn width={1}>{team.name}</TableColumn>
        <TableColumn width={1}>{team.sport}</TableColumn>
        <TableColumn width={1}>{team.league}</TableColumn>
        <TableColumn width={1}>{getAdvisorDisplayName(team)}</TableColumn>
        {showAccountColumn && <TableColumn width={1}>{team.accountName}</TableColumn>}
        <TableColumn width="50px">
            <TeamEditButton id={team.id} isBusiness={true} />
            <TeamDeleteButton onDelete={onDelete} />
        </TableColumn>
    </TableRow>
);

export default BusinessTeamListRow;
