import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { TableSortColumn } from '../../../../../common/ui-components/table/table-sort-header.component';
import TeamList, { TeamListProps } from '../team-list.component';
import SportTeamListRow from './sport-team-list-row.component';

const defaultSportColumns: TableSortColumn[] = [
    {
        row: 'name',
        label: () => i18n`Name des Teams`
    },
    {
        row: 'sport',
        label: () => i18n`Sportart`
    },
    {
        row: 'league',
        label: () => i18n`Ligazugehörigkeit`
    },
    {
        row: 'advisor',
        label: () => i18n`Betreuer`
    }
];

export const SportTeamList = (props: TeamListProps) => {
    const teams = props.teams.filter(t => !t.isBusiness);
    const sportColumns: TableSortColumn[] = props.showAccountColumn
        ? [
              ...defaultSportColumns,
              {
                  row: 'account',
                  label: () => i18n`Account`
              }
          ]
        : [...defaultSportColumns];

    return (
        <TeamList {...props} header={sportColumns} teams={teams}>
            {(team, onDelete) => (
                <SportTeamListRow
                    key={team.id}
                    team={team}
                    onDelete={() => onDelete()}
                    showAccountColumn={props.showAccountColumn}
                />
            )}
        </TeamList>
    );
};
