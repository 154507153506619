import { mdiPencil } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import { History } from 'history';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '../../../common/ui-components/icon-button/icon-button.component';
import './team-edit-button.component.scss';

export interface TeamEditButtonProps {
    className?: string;
    id: string;
    history: History;
    isBusiness: boolean;
}

export const TeamEditButton = ({ className, id, history, isBusiness }: TeamEditButtonProps) => {
    const urlPrefix = isBusiness ? '/teams/business' : '/teams/sport';

    return (
        <IconButton
            title={i18n`Team bearbeiten`}
            role="link"
            icon={mdiPencil}
            className={className}
            onClick={e => {
                e.preventDefault();
                history.push(`${urlPrefix}/${id}/edit`);
            }}
        />
    );
};

// @ts-ignore
export default withRouter(TeamEditButton);
