import React, { ReactNode } from 'react';
import { AccountModel } from '../../models/account-model';
import i18n from 'es2015-i18n-tag';
import './account-overview.component.scss';
import FetchMyAccount from '../../resolver/fetch-my-account.component';
import { getQuotaType, isInTimeQuota, QuotaType } from '../../account-quota';

export interface AccountOverviewProps {
    account: AccountModel;
}
export const AccountOverview = ({ account }: AccountOverviewProps) => {
    const quotaType = getQuotaType(account);
    const hasTimeQuota = quotaType === QuotaType.Time;
    const hasQuota = quotaType !== QuotaType.None;
    const isQuotaExpired = hasTimeQuota && !isInTimeQuota(account);

    return (
        <div className="account-overview">
            <AccountOverviewStat label={i18n`Anzahl User`} value={account.metadata.userCount} />
            <AccountOverviewStat
                label={i18n`Anzahl Betreuer`}
                value={account.metadata.advisorCount}
            />
            <AccountOverviewStat label={i18n`Anzahl Teams`}>
                <span>
                    {account.metadata.teamCount} /&nbsp;
                    {account.teamQuota != null && account.teamQuota}
                    {account.teamQuota == null && (
                        <span className="account-overview__unlimited-quota">{i18n`Unbegrenzt`}</span>
                    )}
                </span>
            </AccountOverviewStat>
            <AccountOverviewStat label={i18n`Anzahl Umfragen`}>
                <span>
                    {account.metadata.surveyRunCount} /&nbsp;
                    {account.surveyQuota != null && account.surveyQuota}
                    {account.surveyQuota == null && (
                        <span className="account-overview__unlimited-quota">{i18n`Unbegrenzt`}</span>
                    )}
                </span>
            </AccountOverviewStat>
            <AccountOverviewStat label={i18n`Account-Manager`}>
                <ul className="account-overview-stat__manager-list">
                    {account.metadata.accountManagerNames.map(name => (
                        <li key={name}>{name}</li>
                    ))}
                </ul>
            </AccountOverviewStat>
            <AccountOverviewStat label={i18n`Vertragsform`}>
                {hasQuota && i18n`Kontingentiert`}
                {!hasQuota && i18n`Unbegrenzt`}
            </AccountOverviewStat>
            {hasTimeQuota && (
                <AccountOverviewStat label={i18n`Account-Status`}>
                    {isQuotaExpired && i18n`abgelaufen`}
                    {!isQuotaExpired && i18n`aktiv`}
                </AccountOverviewStat>
            )}
            <AccountOverviewStat label={i18n`Laufzeit`}>
                {hasTimeQuota && i18n`${account.validFrom}:t(d) - ${account.validUntil}:t(d)`}
                {!hasTimeQuota && i18n`Unbegrenzt`}
            </AccountOverviewStat>
        </div>
    );
};

type AccountOverviewStatProps =
    | { label: string; value: ReactNode }
    | { label: string; children: ReactNode };

function AccountOverviewStat({ label, ...props }: AccountOverviewStatProps) {
    return (
        <div className="account-overview-stat">
            <label className="account-overview-stat__label">{label}</label>
            {'value' in props && (
                <span className="account-overview-stat__value">{props.value}</span>
            )}
            {'children' in props && props.children}
        </div>
    );
}

export default () => (
    <FetchMyAccount>{account => <AccountOverview account={account} />}</FetchMyAccount>
);
