import * as React from 'react';
import ReactModal from 'react-modal';

const customStyles = {
    overlay: {
        zIndex: 20,
        background: 'rgba(0, 0, 0, 0.5)',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const Dialog = props => {
    // HACK: Modals don't work in test
    if (process.env.NODE_ENV === 'test') {
        return <></>;
    }
    return <ReactModal {...props} style={customStyles} />;
};

export default Dialog;
