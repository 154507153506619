import * as React from 'react';
import { TableColumn, TableRow } from '../../../../../common/ui-components/table';
import { AccountModel } from '../../../models/account-model';
import AccountEditButton from '../../../components/account-edit-button.component';
import AccountDeleteButton from './account-delete-button.component';

export interface AccountListRowProps {
    account: AccountModel;
    onDelete: () => void;
}

interface AccountListRowState {
    pending: boolean;
    account: AccountModel;
}

class AccountListRow extends React.Component<AccountListRowProps, AccountListRowState> {
    state = {
        account: this.props.account,
        pending: false
    };

    render() {
        const { onDelete, ...props } = this.props;
        return (
            <TableRow {...props} link={`/accounts/${this.props.account.id}`}>
                <TableColumn width={1}>{this.state.account.name}</TableColumn>
                <TableColumn width={1}>{this.state.account.accountNumber}</TableColumn>
                <TableColumn width="50px" style={{ justifyContent: 'flex-end' }}>
                    <AccountEditButton id={props.account.id} />
                    <AccountDeleteButton onDelete={onDelete} />
                </TableColumn>
            </TableRow>
        );
    }
}

export default AccountListRow;
