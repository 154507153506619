import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { RouteDefinition } from '../../routing/routes';
import { Role } from '../auth/context/auth-context';
import FetchReport from './resolver/fetch-report.component';
import ViewReport from './views/view-report/view-report.component';

export const VIEW_REPORT: RouteDefinition = {
    path: '/teams/:teamId/runs/:surveyRunId',
    title: () => (
        <FetchReport>
            {report => i18n('report')`Report zur Befragung vom ${new Date(report.created)}:t(d)`}
        </FetchReport>
    ),
    content: ViewReport,
    sidenav: false,
    backArrow: true,
    role: Role.Advisor
};
