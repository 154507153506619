import { getToken } from '../domains/auth/context/auth.component';
import { NO_CONTENT, OK } from './http-status-codes';

export async function httpGet<R>(url: string): Promise<R> {
    const res = await http(url);
    assertSuccess(res);
    return await res.json();
}

export async function httpPost<B>(url: string, body: B): Promise<Response> {
    const res = await httpWithBody(url, 'POST', body);
    assertSuccess(res);
    return res;
}

export async function httpPut<B>(url: string, body: B): Promise<Response> {
    const res = await httpWithBody(url, 'PUT', body);
    assertSuccess(res);
    return res;
}

export async function httpDelete(url: string): Promise<Response> {
    const res = await http(url, 'DELETE');
    assertSuccess(res);
    return res;
}

async function httpWithBody<B>(url: string, method: string, body: B) {
    const options: Partial<RequestInit> = {};
    if (body != null) {
        const serializedBody = JSON.stringify(body);
        options.body = serializedBody;
        options.headers = {
            'Content-Type': 'application/json'
        };
    }
    return await http(url, method, options);
}

export async function http(
    url: string,
    method: string = 'GET',
    options: Partial<RequestInit> = {}
) {
    const token = getToken();
    const headers: any = options.headers || {};
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    return await fetch(url, {
        method,
        headers,
        ...options
    });
}

function assertSuccess(res: Response) {
    if (res.status !== OK && res.status !== NO_CONTENT) {
        const err = new Error(`Invalid status code: ${res.status}`);
        // @ts-ignore
        err.status = res.status;
        throw err;
    }
}
