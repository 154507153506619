import { decode } from 'jsonwebtoken';
import * as React from 'react';
import { withRouter } from 'react-router';
import { parseQuery } from '../../../common/query-params';
import AuthContext, { Role, CurrentUserModel } from './auth-context';

const tokenKey = 'AUTH_TOKEN';

let storedToken = JSON.parse(window.sessionStorage.getItem(tokenKey));

const updateStoredToken = (token: string) => {
    storedToken = token;
    window.sessionStorage.setItem(tokenKey, JSON.stringify(token));
};

export const getToken = () => storedToken;

const getUser = (token: string): CurrentUserModel => {
    if (token == null) {
        return null;
    }
    const {
        userId,
        name,
        firstName,
        lastName,
        admin,
        owner,
        accountManager,
        accountId,
        language
    } = decode(token) as any;
    const user: CurrentUserModel = {
        id: userId,
        firstName,
        lastName,
        name,
        accountId,
        role: getRole(admin, owner, accountManager),
        language
    };
    return user;
};

const getRole = (admin: boolean, owner: boolean, accountManager: boolean): Role => {
    let role = Role.Advisor;
    if (owner) {
        // tslint:disable-next-line:no-bitwise
        role |= Role.Owner;
    }
    if (admin) {
        // tslint:disable-next-line:no-bitwise
        role |= Role.Admin;
    }
    if (accountManager) {
        // tslint:disable-next-line:no-bitwise
        role |= Role.AccountManager;
    }
    return role;
};

const withToken = Component => props => <Component {...props} setToken={updateStoredToken} />;

interface TokenQueryParams {
    token: string;
}

const Authentication = ({ children, setToken, history, location }) => {
    const queryParams = parseQuery<TokenQueryParams>(location.search);
    // FIXME: this should be a prop, but causes a timing issue
    const token = queryParams.token || storedToken;

    // FIXME
    if (queryParams.token) {
        updateStoredToken(queryParams.token);
    }

    const user = getUser(token);

    const logout = () => {
        setToken(null);
        history.push('/auth/login');
    };

    return (
        <AuthContext.Provider
            value={{
                loggedIn: !!token,
                user,
                login: setToken,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// @ts-ignore
export default withToken(withRouter(Authentication));
