import * as React from 'react';
import { Link } from 'react-router-dom';

export interface TableRowProps {
    link?: string;
    children: React.ReactNode;
    className?: string;
}

const TableLinkRow = ({ link, children, className }: Required<TableRowProps>) => (
    <Link to={link} className={`table__row table__row--link ${className}`}>
        {children}
    </Link>
);

const PlainTableRow = ({ children, className }: TableRowProps) => (
    <div className={`table__row ${className}`}>{children}</div>
);

const TableRow = ({ link, children, className, ...props }: TableRowProps) => {
    if (link) {
        return (
            <TableLinkRow link={link} className={className || ''} {...props}>
                {children}
            </TableLinkRow>
        );
    } else {
        return (
            <PlainTableRow className={className || ''} {...props}>
                {children}
            </PlainTableRow>
        );
    }
};

export default TableRow;
