import { LocationDescriptor } from 'history';
import * as React from 'react';
import './primary-button.component.scss';
import { Link } from 'react-router-dom';

type PrimaryButtonProps = {
    title: string;
    className?: string;
    onClick?: () => void;
    to: LocationDescriptor;
} & any;

const PrimaryLink = ({ className, title, ...props }: PrimaryButtonProps) => (
    <Link className={`primary-button ${className || ''}`} {...props}>
        {title}
    </Link>
);

export default PrimaryLink;
