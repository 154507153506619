import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Checkbox from '../../../../common/ui-components/checkbox/checkbox.component';
import { FactorTrendModel } from '../../models/report-model';
import './trend-factor-selection.component.scss';

export interface TrendFactorSelectionProps {
    factors: FactorTrendModel[];
    culture: string;
    selection: number[];
    onToggleFactor: (factorIndex: number) => void;
    onSelectAll: () => void;
    onDeselectAll: () => void;
}

export const TrendFactorSelection = React.forwardRef(
    (
        {
            culture,
            factors,
            selection,
            onToggleFactor,
            onSelectAll,
            onDeselectAll
        }: TrendFactorSelectionProps,
        ref
    ) => (
        <div className="trends-factor-selection" ref={ref as any}>
            <h4 className="trends-factor-selection__title">{i18n('report')`Faktoren`}</h4>
            <div className="trends-factor-selection__actions">
                <button className="trends-factor-selection__select-all" onClick={onSelectAll}>
                    {i18n('report')`Alle auswählen`}
                </button>
                <button className="trends-factor-selection__deselect-all" onClick={onDeselectAll}>
                    {i18n('report')`Alle abwählen`}
                </button>
            </div>
            <div className="trends-factor-selection__list">
                {factors.map((factor, i) => (
                    <Checkbox
                        key={i}
                        label={factor.title[culture]}
                        checked={selection.includes(i)}
                        onChange={() => onToggleFactor(i)}
                    />
                ))}
            </div>
        </div>
    )
);
