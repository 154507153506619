import React, { useEffect, useState } from 'react';
import * as teamService from '../../team-service';
import ListTeams from './list-teams.component';
import { getMyAccount } from '../../../accounts/account-service';

const AccountTeamList = () => {
    const [teams, setTeams] = useState([]);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        teamService.getAccountTeams().then(setTeams);
        getMyAccount().then(setAccount);
    }, []);

    return <ListTeams teams={teams} account={account} />;
};

export default AccountTeamList;
