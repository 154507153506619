import * as React from 'react';
import { TableRow } from '../../../../../common/ui-components/table';
import { TeamMember } from '../../../models/team-model';
import EditMember from './edit-member.component';
import { ViewMember } from './view-member.component';

export interface MemberRowProps {
    member: TeamMember;
    disabled: boolean;
    editing: boolean;
    onSave: (member: TeamMember) => void;
    onCancel: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

const MemberRow = ({
    disabled,
    member,
    onSave,
    onEdit,
    onCancel,
    onDelete,
    editing
}: MemberRowProps) => {
    return (
        <TableRow>
            {editing && <EditMember member={member} onSave={onSave} onCancel={onCancel} />}
            {!editing && (
                <ViewMember
                    disabled={disabled}
                    member={member}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            )}
        </TableRow>
    );
};

export default MemberRow;
