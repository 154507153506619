import i18n from 'es2015-i18n-tag';
import React, { useEffect, useState } from 'react';
import PrimaryLink from '../../../../common/ui-components/primary-button/primary-link.component';
import './list-accounts.component.scss';
import { deleteAccount, listAccounts } from '../../account-service';
import { AccountModel } from '../../models/account-model';
import AccountList from './table/account-list.component';

const ListAccounts = () => {
    const [accounts, setAccounts] = useState<AccountModel[]>([]);

    useEffect(() => {
        listAccounts().then(setAccounts);
    }, []);

    const onDelete = async (account: AccountModel) => {
        await deleteAccount(account.id);
        const next = accounts.filter(a => a.id !== account.id);
        setAccounts(next);
    };

    return (
        <div className="list-accounts">
            <div className="list-accounts__create-account">
                <PrimaryLink
                    to="/accounts/new"
                    className="list-account__create-account-btn"
                    title={i18n`Neuen Account anlegen`}
                />
            </div>
            <AccountList accounts={accounts} onDelete={onDelete} />
        </div>
    );
};

export default ListAccounts;
