import * as React from 'react';

interface SidenavOverlayProps {
    collapsed: boolean;
    onClick: () => void;
}

const SidenavOverlay = ({ collapsed, onClick }: SidenavOverlayProps) => (
    <>{!collapsed && <div className="sidenav__overlay" onClick={onClick} />}</>
);

export default SidenavOverlay;
