import i18n from 'es2015-i18n-tag';
import { Field, Form, FormikProps } from 'formik';
import { compose } from 'ramda';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import BackButton from '../../../../common/ui-components/back-button/back-button.component';
import Input from '../../../../common/ui-components/input/input.component';
import { useNotification } from '../../../../common/ui-components/notification';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import Select, { SelectItem } from '../../../../common/ui-components/select/select.component';
import * as accountService from '../../../accounts/account-service';
import {
    AuthState,
    CurrentUserModel,
    hasAnyRole,
    Role,
    withAuth
} from '../../../auth/context/auth-context';
import { UserModel } from '../../../users/models/user-model';
import * as userService from '../../../users/user-service';
import { TeamAdvisor, TeamModel } from '../../models/team-model';
import { updateImage } from '../../team-service';
import './edit-team.component.scss';
import { ImageUpload } from './image-upload.component';

const EMPTY_ADVISOR = { value: null } as SelectItem;

interface EditTeamFormValue {
    name: string;
    sport: string;
    league: string;
    advisor?: TeamAdvisor;
}

type EditTeamProps = {
    team: TeamModel;
    children: any;
    advisorLabel: string;
} & RouteComponentProps;

type EditTeamEnhancedProps = EditTeamProps & FormikProps<EditTeamFormValue> & AuthState;

const mapToSelectItem = (advisor: TeamAdvisor): SelectItem => {
    const displayName = getDisplayName(advisor);
    return {
        label: displayName,
        value: {
            id: advisor.id,
            firstName: advisor.firstName,
            lastName: advisor.lastName,
            isAccountManager: advisor.isAccountManager
        }
    };
};
const getDisplayName = (advisor: TeamAdvisor): string => {
    if (!advisor.firstName) {
        return advisor.lastName;
    }
    if (!advisor.lastName) {
        return advisor.firstName;
    }
    return `${advisor.lastName}, ${advisor.firstName}`;
};

const EditTeam = ({
    isValid,
    isSubmitting,
    user,
    team,
    children,
    advisorLabel
}: EditTeamEnhancedProps) => {
    const [advisors, setAdvisors] = useState([]);
    const showNotification = useNotification();

    useEffect(() => {
        if (!hasAnyRole(user, Role.AccountManager, Role.Admin, Role.Owner)) {
            return;
        }
        getUsers(user, team.accountId).then(setAdvisors);
    }, []);

    return (
        <Form noValidate className="edit-team">
            <div className="edit-team__form">
                <Field
                    component={Input}
                    autoFocus
                    title={i18n`Name des Teams`}
                    name="name"
                    className="edit-team__form-field"
                    required
                />
                {children}
                {hasAnyRole(user, Role.Owner, Role.Admin, Role.AccountManager) && (
                    <Field
                        component={Select}
                        title={advisorLabel}
                        name="advisor"
                        className="edit-team__form-field"
                        items={[EMPTY_ADVISOR, ...advisors.map(mapToSelectItem)]}
                    />
                )}
            </div>
            <ImageUpload
                teamId={team.id}
                onChange={async image => {
                    await updateImage(team.id, image);
                    showNotification(i18n`Logo wurde gespeichert`);
                }}
            />
            <div className="edit-team__actions">
                <PrimaryButton
                    title={i18n`Speichern`}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className="create-team__action-btn"
                />
                <BackButton className="edit-team__action-btn" />
            </div>
        </Form>
    );
};

function getUsers(user: CurrentUserModel, accountId?: string): Promise<UserModel[]> {
    if (accountId != null) {
        if (hasAnyRole(user, Role.Admin, Role.Owner)) {
            return accountService.getAccountUsers(accountId);
        } else {
            return userService.getAccountUsers();
        }
    } else {
        return userService.getUsers();
    }
}

const enhance = compose<any, any>(withAuth());

export default enhance(EditTeam);
