import i18n from 'es2015-i18n-tag';
import { Field, Form, FormikProps } from 'formik';
import * as React from 'react';
import { ReactNode } from 'react';
import * as yup from 'yup';
import BackButton from '../../../../common/ui-components/back-button/back-button.component';
import Input from '../../../../common/ui-components/input/input.component';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import Select from '../../../../common/ui-components/select/select.component';
import { SurveyTemplateModel } from '../../models/survey-template-model';
import './create-team.component.scss';

export interface CreateTeamProps {
    isValid: boolean;
    isSubmitting: boolean;
    templates: SurveyTemplateModel[];
    children: ReactNode;
}

export interface CreateTeamFormValue {
    name: string;
    sport: string;
    league: string;
    survey: SurveyTemplateModel;
}

export const formSchema = yup.object().shape<CreateTeamFormValue>({
    name: yup
        .string()
        .trim()
        .required(),
    sport: yup
        .string()
        .trim()
        .required(),
    league: yup
        .string()
        .trim()
        .required(),
    survey: yup.object<SurveyTemplateModel>().required()
});

const CreateTeam = ({
    isValid,
    isSubmitting,
    templates,
    children
}: CreateTeamProps & FormikProps<CreateTeamFormValue>) => {
    return (
        <Form noValidate>
            <div className="create-team__form">
                <Field
                    component={Input}
                    autoFocus
                    title={i18n`Name des Teams`}
                    name="name"
                    className="create-team__form-field"
                    required
                />
                {children}
                <Field
                    component={Select}
                    title={i18n`Umfrage`}
                    name="survey"
                    className="create-team__form-field"
                    items={templates.map(t => ({
                        label: `${t.name} (${t.hasSixPossibleAnswers ? 'V2' : 'V1'})`,
                        value: t
                    }))}
                    required
                />
            </div>
            <div className="create-team__actions">
                <PrimaryButton
                    title={i18n`Speichern`}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className="create-team__action-btn"
                />
                <BackButton className="create-team__action-btn" />
            </div>
        </Form>
    );
};

export default CreateTeam;
