import { FormikBag, withFormik } from 'formik';
import { compose } from 'ramda';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { SelectItem } from '../../../../common/ui-components/multi-select/multi-select.component';
import { TeamModel } from '../../../teams/models/team-model';
import { UserModel } from '../../../users/models/user-model';
import { getAccountTeams, getAccountUsers, updateAccount } from '../../account-service';
import AccountEditor, {
    EditAccountFormValue,
    EditAccountProps,
    formSchema
} from '../../components/edit-account.component';
import { AssignedTeam, AssignedUser } from '../../models/account-create-model';
import FetchAccount, { FetchAccountParams } from '../../resolver/fetch-account.component';

const mapSelectItemsToUsers = (
    items: SelectItem<UserModel>[],
    isAccountManager: boolean
): AssignedUser[] => items.map(u => u.value).map(u => ({ ...u, isAccountManager }));

const onEdit = (
    formValue: EditAccountFormValue,
    { props, setSubmitting }: FormikBag<any, EditAccountFormValue>
) => {
    const { teams, users, accountManagers, ...fields } = formValue;
    updateAccount({
        id: props.account.id,
        teams: teams.map(t => t.value),
        users: [
            ...mapSelectItemsToUsers(accountManagers, true),
            ...mapSelectItemsToUsers(users, false)
        ],
        ...fields
    })
        .then(() => props.history.goBack())
        .finally(() => setSubmitting(false));
};

const enhance = compose(
    withRouter,
    withFormik({
        displayName: 'EditAccount',
        validationSchema: formSchema,
        handleSubmit: onEdit,
        enableReinitialize: true,
        mapPropsToValues: ({ account, teams, users }: EditAccountProps): EditAccountFormValue => ({
            name: account.name,
            users: (users ?? []).filter(u => !u.isAccountManager).map(mapUserToSelectItem),
            accountManagers: (users ?? []).filter(u => u.isAccountManager).map(mapUserToSelectItem),
            teams: (teams ?? []).map(mapTeamToSelectItem),
            validUntil: account.validUntil == null ? ('' as any) : new Date(account.validUntil),
            validFrom: account.validFrom == null ? ('' as any) : new Date(account.validFrom),
            teamQuota: account.teamQuota ?? ('' as any),
            surveyQuota: account.surveyQuota ?? ('' as any)
        })
    })
);

const mapTeamToSelectItem = (team: AssignedTeam): SelectItem<AssignedTeam> => ({
    value: team,
    label: team.name
});

const mapUserToSelectItem = (user: UserModel): SelectItem<UserModel> => ({
    value: user,
    label: `${user.firstName} ${user.lastName}`
});

const mapTeamToAssignedTeam = (team: TeamModel): AssignedTeam => ({
    id: team.id,
    name: team.name,
    sport: team.sport,
    league: team.league
});

const EditAccountView = enhance(AccountEditor);

const EditAccount = ({ match }: RouteComponentProps<FetchAccountParams>) => {
    const [teams, setTeams] = useState<AssignedTeam[]>([]);
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        getAccountTeams(match.params.accountId)
            .then(t => t.map(mapTeamToAssignedTeam))
            .then(setTeams);
        getAccountUsers(match.params.accountId).then(setUsers);
    }, []);

    return (
        <FetchAccount>
            {account => <EditAccountView account={account} users={users} teams={teams} />}
        </FetchAccount>
    );
};

export default withRouter(EditAccount);
