import { mdiClipboardText } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import i18n from 'es2015-i18n-tag';

export const OpenReportButton = () => (
    <span title={i18n`Report öffnen`}>
        <Icon path={mdiClipboardText} size={1} className={'view-team__report-btn'} />
    </span>
);
