import * as React from 'react';
import { TeamModel } from '../../models/team-model';
import TeamTile from './team-tile.component';

export interface TeamListProps {
    teams: TeamModel[];
}

const TeamList = ({ teams }: TeamListProps) => (
    <div className="my-teams__list">
        {teams.map(t => (
            <TeamTile key={t.id} team={t} />
        ))}
    </div>
);

export default TeamList;
