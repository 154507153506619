import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import { Role } from '../../../domains/auth/context/auth-context';
import routes from '../../../routing/routes';
import SidenavCategory from './sidenav-category.component';
import { SidenavExternalLink } from './sidenav-link.component';
import SidenavOverlay from './sidenav-overlay.component';
import { SidenavToggleBtn } from './sidenav-toggle-btn.component';
import './sidenav.component.scss';

const overlaySidenav = () => window.matchMedia('screen and (max-width: 1750px)').matches;

const Sidenav = ({ className }) => {
    const [collapsed, setCollapsed] = useState(overlaySidenav());

    const collapsedClass = collapsed ? 'sidenav--collapsed' : 'sidenav--expanded';

    const links = routes.filter(r => r.sidenav !== false);

    return (
        <>
            <div
                className={`sidenav ${collapsedClass} ${className}`}
                onClick={() => {
                    if (collapsed) {
                        return;
                    }
                    if (!overlaySidenav()) {
                        return;
                    }
                    setCollapsed(true);
                }}
            >
                <SidenavCategory title={i18n`Admin`} links={links} role={Role.Admin} />
                <SidenavCategory title={i18n`Account`} links={links} role={Role.AccountManager} />
                <SidenavCategory title={i18n`Betreuer`} links={links} role={Role.Advisor} />
                <SidenavSpacer />
                <AboutSidenavLink />
                <PrivacyPolicyLink />
                <SidenavToggleBtn onClick={() => setCollapsed(!collapsed)} />
            </div>
            <SidenavOverlay collapsed={collapsed} onClick={() => setCollapsed(true)} />
        </>
    );
};

const AboutSidenavLink = () => (
    <SidenavExternalLink to="https://team-psychology.com/impressum/">{i18n`Impressum`}</SidenavExternalLink>
);

const PrivacyPolicyLink = () => (
    <SidenavExternalLink to="https://team-psychology.com/datenschutz/">{i18n`Datenschutz`}</SidenavExternalLink>
);

const SidenavSpacer = () => <div className="sidenav__spacer" />;

export default Sidenav;
