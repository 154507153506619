export enum QuotaType {
    None,
    Time,
    Quantity
}

interface AccountQuotaModel {
    surveyQuota?: number;
    teamQuota?: number;
    validFrom?: Date;
    validUntil?: Date;
}

export function getQuotaType(values: AccountQuotaModel): QuotaType {
    if (values.validUntil) {
        return QuotaType.Time;
    }
    if (values.surveyQuota || values.teamQuota) {
        return QuotaType.Quantity;
    }
    return QuotaType.None;
}

export function isInTimeQuota(values: AccountQuotaModel): boolean | null {
    if (!values.validUntil) {
        return null;
    }
    const now = Date.now();
    const validFrom = values.validFrom.getTime() ?? 0;
    const validUntil = addDays(values.validUntil, 1).getTime();

    const isInTimeRange = validFrom < now && validUntil > now;

    return isInTimeRange;
}

function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
