import i18n from 'es2015-i18n-tag';
import { default as React } from 'react';

export enum LoginStatus {
    Success,
    InvalidEmail,
    UnknownEmail
}

// Function required because we need to re-execute the template after changing the language
const loginStatusMessages = new Map<LoginStatus, () => string>();

loginStatusMessages.set(
    LoginStatus.Success,
    () => i18n`Ein Login-Code wurde per E-Mail verschickt.`
);
loginStatusMessages.set(LoginStatus.InvalidEmail, () => i18n`Invalide E-Mail-Adresse`);
loginStatusMessages.set(
    LoginStatus.UnknownEmail,
    () => i18n`Unbekannte E-Mail. Bitte überprüfen Sie Ihre Eingabe.`
);

export const LoginStatusMessage = ({ status }) => (
    <>{status != null && <p className="login__status-msg">{getStatusMsg(status)}</p>}</>
);

function getStatusMsg(status: LoginStatus): string {
    return loginStatusMessages.get(status)();
}
