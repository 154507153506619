import { mdiBasketball, mdiBriefcase } from '@mdi/js';
import Icon from '@mdi/react';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { allTeams, myTeams } from '../../common/icons';
import { SurveyVersionIndicator } from '../../common/ui-components/survey-version-indicator/survey-version-indicator';
import { RouteDefinition } from '../../routing/routes';
import { Role } from '../auth/context/auth-context';
import FetchTeam from './resolver/fetch-team.component';
import {
    createSportTeam,
    createBusinessTeam,
    createAccountSportTeam,
    createAccountBusinessTeam
} from './team-service';
import CreateBusinessTeam from './views/create-team/create-business-team.component';
import CreateSportTeam from './views/create-team/create-sport-team.component';
import EditBusinessTeam from './views/edit-team/edit-business-team.component';
import EditSportTeam from './views/edit-team/edit-sport-team.component';
import AccountTeamList from './views/list-teams/account-team-list.component';
import AdminTeamList from './views/list-teams/admin-team-list.component';
import MyTeams from './views/my-teams/my-teams.component';
import ViewTeam from './views/view-team/view-team.component';
import FetchMyAccount from '../accounts/resolver/fetch-my-account.component';

export const ALL_TEAMS: RouteDefinition = {
    path: '/admin/teams',
    title: () => i18n`Alle Teams`,
    icon: allTeams,
    exact: true,
    content: AdminTeamList,
    role: Role.Admin
};

export const ACCOUNT_TEAMS: RouteDefinition = {
    path: '/account/teams',
    title: () => i18n`Teams`,
    header: () => (
        <>
            <span>{i18n`Account-Teams`}</span>
            <span className="app__title--highlighted">
                <FetchMyAccount>{account => account.name}</FetchMyAccount>
            </span>
        </>
    ),
    icon: allTeams,
    exact: true,
    content: AccountTeamList,
    role: Role.AccountManager
};

export const MY_TEAMS: RouteDefinition = {
    path: '/teams',
    title: () => i18n`Meine Teams`,
    icon: myTeams,
    exact: true,
    content: MyTeams,
    role: Role.Advisor
};

export const ADMIN_NEW_SPORT_TEAM: RouteDefinition = {
    path: '/admin/teams/sport/new',
    title: () => i18n`Neues Sport Team anlegen`,
    content: () => <CreateSportTeam createTeam={createSportTeam} />,
    sidenav: false,
    exact: true,
    role: Role.Admin
};

export const ADMIN_NEW_BUSINESS_TEAM: RouteDefinition = {
    path: '/admin/teams/business/new',
    title: () => i18n`Neues Business Team anlegen`,
    content: () => <CreateBusinessTeam createTeam={createBusinessTeam} />,
    sidenav: false,
    exact: true,
    role: Role.Admin
};

export const ACCOUNT_NEW_SPORT_TEAM: RouteDefinition = {
    path: '/account/teams/sport/new',
    title: () => i18n`Neues Sport Team anlegen`,
    content: () => <CreateSportTeam createTeam={createAccountSportTeam} />,
    sidenav: false,
    exact: true,
    role: Role.AccountManager
};

export const ACCOUNT_NEW_BUSINESS_TEAM: RouteDefinition = {
    path: '/account/teams/business/new',
    title: () => i18n`Neues Business Team anlegen`,
    content: () => <CreateBusinessTeam createTeam={createAccountBusinessTeam} />,
    sidenav: false,
    exact: true,
    role: Role.AccountManager
};

export const EDIT_SPORT_TEAM: RouteDefinition = {
    path: '/teams/sport/:teamId/edit',
    title: () => (
        <FetchTeam>
            {team => (
                <>
                    <Icon path={mdiBasketball} size={1} className="page-title__icon" />
                    {i18n`${team.name} bearbeiten`}
                </>
            )}
        </FetchTeam>
    ),
    content: EditSportTeam,
    sidenav: false,
    role: Role.Advisor
};

export const EDIT_BUSINESS_TEAM: RouteDefinition = {
    path: '/teams/business/:teamId/edit',
    title: () => (
        <FetchTeam>
            {team => (
                <>
                    <Icon path={mdiBriefcase} size={1} className="page-title__icon" />
                    {i18n`${team.name} bearbeiten`}
                </>
            )}
        </FetchTeam>
    ),
    content: EditBusinessTeam,
    sidenav: false,
    role: Role.Advisor
};

export const VIEW_SPORT_TEAM: RouteDefinition = {
    path: '/teams/sport/:teamId',
    title: () => (
        <FetchTeam>
            {team => (
                <>
                    <Icon path={mdiBasketball} size={1} className="page-title__icon" />
                    {team.name} <SurveyVersionIndicator isV2={team.hasSixPossibleAnswers} />
                </>
            )}
        </FetchTeam>
    ),
    content: ViewTeam,
    sidenav: false,
    exact: true,
    backArrow: true,
    role: Role.Advisor
};

export const VIEW_BUSINESS_TEAM: RouteDefinition = {
    path: '/teams/business/:teamId',
    title: () => (
        <FetchTeam>
            {team => (
                <>
                    <Icon path={mdiBriefcase} size={1} className="page-title__icon" />
                    {team.name} <SurveyVersionIndicator isV2={team.hasSixPossibleAnswers} />
                </>
            )}
        </FetchTeam>
    ),
    content: ViewTeam,
    sidenav: false,
    exact: true,
    backArrow: true,
    role: Role.Advisor
};
