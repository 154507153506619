import * as React from 'react';
import { Table } from '../../../common/ui-components/table';
import { SortableList, TableSorting } from '../../../common/ui-components/table/sort-helpers';
import TableBody from '../../../common/ui-components/table/table-body.component';
import { UserModel } from '../models/user-model';
import UserListHeader from './user-list-header.component';
import UserListRow, { UserRowProps } from './user-list-row.component';

export interface UserListProps {
    users: UserModel[];
    onCancelUserCreation: () => void;
    onUpdateUser: (user: UserModel) => Promise<void>;
    validateEmail: (user: UserModel) => boolean;
    header: React.ReactNode;
    children: ({ user, editUser, updateUser }: UserRowProps) => React.ReactNode;
    edit: (
        user: UserModel,
        onUpdate: (user: Partial<UserModel>) => void,
        disabled: boolean
    ) => React.ReactNode;
    showAccountColumn?: boolean;
}

const userSortingAccessor = (sorting: TableSorting) => (user: UserModel) => user[sorting.row];

const UserList = (props: UserListProps) => (
    <SortableList rows={props.users} initialSort="firstName" sortingAccessor={userSortingAccessor}>
        {({ rows, sorting, setSorting }) => (
            <Table className="list-users__list">
                <UserListHeader
                    sorting={sorting}
                    onSort={(row, direction) => setSorting({ row, direction })}
                    children={props.header}
                    showAccountColumn={props.showAccountColumn}
                />
                <TableBody>
                    {rows.map(u => (
                        <UserListRow
                            key={u.id}
                            user={u}
                            onCancelCreation={props.onCancelUserCreation}
                            onUpdateUser={props.onUpdateUser}
                            validateEmail={props.validateEmail}
                            children={props.children}
                            edit={props.edit}
                            showAccountColumn={props.showAccountColumn}
                        />
                    ))}
                </TableBody>
            </Table>
        )}
    </SortableList>
);

export default UserList;
