import i18n from 'es2015-i18n-tag';
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableColumn,
    TableHeader,
    TableRow
} from '../../../../../common/ui-components/table';
import TableBody from '../../../../../common/ui-components/table/table-body.component';
import { UserModel } from '../../../../users/models/user-model';
import { getAccountUsers } from '../../../account-service';

const AccountUserList = ({ accountId }) => {
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        getAccountUsers(accountId).then(setUsers);
    }, [accountId]);

    return (
        <Table>
            <TableHeader>
                <TableColumn width={1}>{i18n`Vorname`}</TableColumn>
                <TableColumn width={1}>{i18n`Nachname`}</TableColumn>
                <TableColumn width={1}>{i18n`E-Mail`}</TableColumn>
                <TableColumn width={1}>{i18n`Telefon`}</TableColumn>
            </TableHeader>
            <TableBody>
                {users.map(user => (
                    <TableRow key={user.id}>
                        <TableColumn width={1}>{user.firstName}</TableColumn>
                        <TableColumn width={1}>{user.lastName}</TableColumn>
                        <TableColumn width={1}>
                            <a className="list-users__detail-link" href={`mailto:${user.email}`}>
                                {user.email}
                            </a>
                        </TableColumn>
                        <TableColumn width={1}>
                            {user.phone && (
                                <a className="list-users__detail-link" href={`tel:${user.phone}`}>
                                    {user.phone}
                                </a>
                            )}
                        </TableColumn>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AccountUserList;
