import React from 'react';
import Select from '../common/ui-components/select/select.component';
import { languages } from './configurations';
import { LocaleContext } from './locale';

export interface LanguageSelectorProps {
    className?: string;
    small?: boolean;
}

const LanguageSelector = ({ ...props }: LanguageSelectorProps) => {
    return (
        <LocaleContext.Consumer>
            {({ lang, switchLanguage }) => (
                <Select
                    {...props}
                    small={props.small}
                    value={lang}
                    onChange={switchLanguage}
                    items={languages.map(l => ({ value: l.locale, label: l.label }))}
                />
            )}
        </LocaleContext.Consumer>
    );
};

export default LanguageSelector;
