function setScreenHeight(screenHeight) {
    document.documentElement.style.setProperty('--screen-height', `${screenHeight}px`);
}

function onResize() {
    setScreenHeight(window.innerHeight);
}

window.onresize = onResize;

onResize();
