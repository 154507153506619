import * as React from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router';
import { Async, Resolved, Rejected, AsyncErrorHandling } from '../../../../common/async';
import { parseQuery } from '../../../../common/query-params';
import { requestToken } from '../../auth-service';
import { AuthState, withAuth } from '../../context/auth-context';

interface AuthRedirectProps extends RouteComponentProps, AuthState {}

const AuthRedirect = ({ location, login }: AuthRedirectProps) => {
    const { login_id: oneTimeToken } = parseQuery(location.search);
    return (
        <Async promiseFn={() => requestToken(oneTimeToken)}>
            <Resolved>
                {token => {
                    login(token);
                    return <Redirect to="/teams" />;
                }}
            </Resolved>
            <Rejected>{error => <AsyncErrorHandling error={error} />}</Rejected>
        </Async>
    );
};

// @ts-ignore
export default withRouter(withAuth()(AuthRedirect));
