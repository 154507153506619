import * as React from 'react';
import { TableRow } from '../../../common/ui-components/table';
import { UserModel } from '../models/user-model';
import EditUser from './edit-user.component';

export interface UserRowProps {
    user: UserModel;
    editUser: () => void;
    updateUser: (user: Partial<UserModel>) => void;
}

export interface UserListRowProps {
    user: UserModel;
    /***
     * Called when the creation of a new user gets cancelled.
     */
    onCancelCreation?: () => void;
    onUpdateUser: (user: UserModel) => Promise<void>;
    validateEmail: (user: UserModel) => boolean;
    children: ({ user, editUser, updateUser }: UserRowProps) => React.ReactNode;
    edit: (
        user: UserModel,
        onUpdate: (user: Partial<UserModel>) => void,
        disabled: boolean
    ) => React.ReactNode;
    showAccountColumn?: boolean;
}

interface UserListRowState {
    editing: boolean;
    pending: boolean;
    user: UserModel;
}

class UserListRow extends React.Component<UserListRowProps, UserListRowState> {
    state = {
        editing: this.props.user.id == null,
        user: this.props.user,
        pending: false
    };

    render() {
        return (
            <TableRow
                {...this.props}
                className={this.state.user.disabled ? 'list-users__user-row--disabled' : ''}
            >
                {this.state.editing && (
                    <EditUser
                        disabled={this.state.pending}
                        user={this.state.user}
                        onEdit={this.setUser}
                        onSave={this.saveUser}
                        onCancel={this.cancelUser}
                        validateEmail={this.props.validateEmail}
                        children={this.props.edit}
                        showAccountColumn={this.props.showAccountColumn}
                    />
                )}
                {!this.state.editing &&
                    this.props.children({
                        user: this.state.user,
                        editUser: this.editUser,
                        updateUser: this.updateUser
                    })}
            </TableRow>
        );
    }

    private editUser = () => this.setEditing(true);

    private saveUser = async () => {
        this.setPending(true);
        try {
            await this.props.onUpdateUser(this.state.user);
            this.setEditing(false);
        } catch (err) {
            console.error(err);
        }
        this.setPending(false);
    };

    private cancelUser = () => {
        this.setState({
            ...this.state,
            user: this.props.user,
            editing: false
        });
        if (this.props.user.id === null && this.props.onCancelCreation) {
            this.props.onCancelCreation();
        }
    };

    private updateUser = async (update: Partial<UserModel>) => {
        const user: UserModel = {
            ...this.state.user,
            ...update
        };
        await this.props.onUpdateUser(user);
        this.setUser(user);
    };

    private setEditing = editing => this.setState({ ...this.state, editing });

    private setUser = user => this.setState({ ...this.state, user });

    private setPending = pending => this.setState({ ...this.state, pending });
}

export default UserListRow;
