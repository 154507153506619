import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { downloadReport } from '../../report-service';
import './export-report-bar.component.scss';

interface RouteParams {
    teamId: string;
    surveyRunId: string;
}

export interface ExportReportBarProps extends RouteComponentProps<RouteParams> {}

const ExportReportBar = (props: ExportReportBarProps) => {
    const { teamId, surveyRunId } = props.match.params;

    return (
        <div className="export-report-bar">
            <button
                className="export-report-bar__export-btn"
                onClick={() => downloadReport(teamId, surveyRunId)}
            >
                {i18n('report')`exportieren`}
            </button>
        </div>
    );
};

export default withRouter(ExportReportBar);
