export interface TeamModel {
    id: string;
    name: string;
    accountId?: string;
    accountName?: string;
    isBusiness: boolean;
    hasSixPossibleAnswers: boolean;
    isPrinorQuest: boolean;
    sport: string;
    league: string;
    hasLogo: boolean;
    advisor: TeamAdvisor;
    runs: SurveyRunModel[];
    members: TeamMember[];
    canStartRun: boolean;
}

export interface TeamAdvisor {
    id: string;
    firstName: string;
    lastName: string;
    isAccountManager: boolean;
}

export interface SurveyRunModel {
    id: string;
    created: string;
    completed: boolean;
    closeRequested: boolean;
    members: SurveyRunMemberModel[];
    surveyUrl?: string;
}

export interface SurveyRunMemberModel {
    email: string;
    completed: boolean;
}

export interface TeamMember {
    firstName: string;
    lastName: string;
    role: string;
    email: string;
}

export function getAdvisorDisplayName(team: TeamModel): string {
    if (team.advisor == null) {
        return '';
    }
    return `${team.advisor.firstName} ${team.advisor.lastName}`;
}
