import * as React from 'react';
import { withRouter, RouteComponentProps, match as Match } from 'react-router';
import { Async, AsyncErrorHandling, Rejected, Resolved } from '../../../common/async';
import { ReportModel } from '../models/report-model';
import * as reportService from '../report-service';

const getReport = ({ teamId, runId }) => reportService.getReport(teamId, runId);

export interface FetchReportProps {
    children: (report: ReportModel, teamId: string) => React.ReactNode;
}

export interface FetchReportParams {
    teamId: string;
    surveyRunId: string;
}

interface FetchReportRouteProps {
    match: Match<FetchReportParams>;
}

export const FetchReport = ({ match, children }: FetchReportProps & FetchReportRouteProps) => (
    <Async promiseFn={getReport} teamId={match.params.teamId} runId={match.params.surveyRunId}>
        <Resolved>{report => children(report, match.params.teamId)}</Resolved>
        <Rejected>{error => <AsyncErrorHandling error={error} />}</Rejected>
    </Async>
);

export default withRouter(
    FetchReport as React.ComponentType<FetchReportProps & RouteComponentProps<FetchReportParams>>
) as React.ComponentType<FetchReportProps>;
