export function debounce<P>(
    method: (param: P) => void | Promise<void>,
    timeout = 500
): (param: P) => void | Promise<void> {
    let timer;
    return (...params) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(method, timeout, ...params);
    };
}
