import * as React from 'react';
import AsyncFn, { AsyncChildren, AsyncProps } from 'react-async';

export interface AsyncComponent<T> extends React.Component<AsyncProps<T>> {}

export interface AsyncResolvedProps<T> {
    children: AsyncChildren<T>;
}

export interface AsyncResolvedComponent<T> extends React.Component<AsyncResolvedProps<T>> {}

export interface AsyncRejectedProps<T> {
    children: AsyncChildren<T>;
}

export interface AsyncRejectedComponent<T> extends React.Component<AsyncRejectedProps<T>> {}

// @ts-ignore
export const Async = AsyncFn as AsyncComponent;

// @ts-ignore
export const Resolved = Async.Resolved as AsyncResolvedComponent;
// @ts-ignore
export const Rejected = Async.Rejected as AsyncRejectedComponent;

export { default as AsyncErrorHandling } from './error-handling';
