import * as React from 'react';

export interface TableHeaderProps {
    children;
}

const TableHeader = ({ children }: TableHeaderProps) => (
    <div className="table__header">{children}</div>
);

export default TableHeader;
