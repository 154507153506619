import { i18nConfig } from 'es2015-i18n-tag';

const LOCALE_KEY = 'LOCALE';

export const locales = {
    'de-DE': require('../../translations/de-DE.json'),
    'en-GB': require('../../translations/en-GB.json')
};

export const languages = [
    {
        locale: 'de-DE',
        label: 'Deutsch'
    },
    {
        locale: 'en-GB',
        label: 'English'
    }
];

export let currentLanguage = getFittingLocale(
    window.sessionStorage.getItem(LOCALE_KEY) ||
        navigator.language ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigator.userLanguage ||
        'de-DE'
);

export function setupInternationalization() {
    updateLocale(currentLanguage);
}

function updateLocale(isoCode: string) {
    i18nConfig({
        locales: isoCode,
        translations: locales[isoCode]
    });
    currentLanguage = isoCode;
}

export function setLocale(isoCode: string) {
    updateLocale(isoCode);
    window.sessionStorage.setItem(LOCALE_KEY, isoCode);
}

export function setLocaleForGroup(group: string, isoCode: string) {
    i18nConfig({
        locales: isoCode,
        translations: {
            ...locales[currentLanguage],
            [group]: locales[isoCode][group]
        }
    });
}

function getFittingLocale(isoCode: string): string {
    if (isoCode in locales) {
        return isoCode;
    }
    const langCode = isoCode.substring(0, 2);
    const matchingLocaleForLangCode = getLocaleByLangCode(langCode);
    if (matchingLocaleForLangCode != null) {
        return matchingLocaleForLangCode;
    }

    return 'de-DE';
}

function getLocaleByLangCode(langCode: string): string | null {
    const localeCodes = Object.keys(locales);

    return localeCodes.find(c => c.startsWith(langCode));
}

export function shouldLoadLocaleFromUser(): boolean {
    return window.sessionStorage.getItem(LOCALE_KEY) == null;
}
