import * as React from 'react';
import Select from '../../../../common/ui-components/select/select.component';

const businessLogo = require('../../../../../assets/team-psychology-360_business.svg');
const sportsLogo = require('../../../../../assets/team-psychology-360_sports.svg');

export const ReportHeader = ({ languages, onChangeLanguage, lang, isBusiness }) => {
    return (
        <div className="report__header">
            <img
                src={isBusiness ? businessLogo : sportsLogo}
                className="report__header-logo"
                alt="team.diagnose.portal Logo"
            />
            <Select
                className="report__language-selection"
                onChange={value => onChangeLanguage(value)}
                value={lang}
                items={languages.map(({ culture, label }) => ({
                    label,
                    value: culture
                }))}
            />
        </div>
    );
};
