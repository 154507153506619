import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { TableColumn } from '../../../../../common/ui-components/table';
import { TableSorting } from '../../../../../common/ui-components/table/sort-helpers';
import TableSortHeader, {
    TableSortColumn
} from '../../../../../common/ui-components/table/table-sort-header.component';
import { SortDirection } from '../../../../../common/ui-components/table/table.component';

export interface AccountListHeaderProps {
    onSort: (row: string, direction: SortDirection) => void;
    sorting: TableSorting;
}

const columns: TableSortColumn[] = [
    {
        row: 'name',
        label: () => i18n`Name`
    },
    {
        row: 'accountNumber',
        label: () => i18n`Account Nummer`
    }
];

const AccountListHeader = (props: AccountListHeaderProps) => (
    <TableSortHeader columns={columns} {...props}>
        <TableColumn width="50px" />
    </TableSortHeader>
);

export default AccountListHeader;
