import { mdiDelete } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import { QuestionModel } from '../../../models/report-model';
import { QuestionAnalysisBaseChart } from './question-analysis-chart.component';

export interface QuestionSelectionProps {
    questions: QuestionModel[];
    onSelect: (index: number) => void;
    onCancel: () => void;
}

export function QuestionSelection({ questions, onSelect, onCancel }: QuestionSelectionProps) {
    return (
        <div className="question-selection">
            <div className="question-selection__chart-container">
                {questions.map((q, i) => {
                    return (
                        <div
                            key={i}
                            onClick={() => onSelect(i)}
                            className={`question-selection__chart ${
                                q.selected ? 'question-selection__chart--selected' : ''
                            }`}
                            title={q.title}
                        >
                            <span className="question-selection__chart-title">{q.title}</span>
                            <QuestionAnalysisBaseChart
                                options={{
                                    hAxis: { textPosition: 'none' },
                                    tooltip: { trigger: 'none' },
                                    enableInteractivity: false
                                }}
                                question={q}
                            />
                        </div>
                    );
                })}
            </div>
            <IconButton
                title={i18n('report')`Antwort-Verteilung entfernen`}
                className="question-analysis__remove-chart-btn"
                onClick={onCancel}
                icon={mdiDelete}
            />
        </div>
    );
}
