import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions } from 'react-google-charts/dist/types';
import { QuestionModel } from '../../../models/report-model';
import './question-analysis-chart.component.scss';

export const QuestionAnalysisChart = ({ question, compareDate, compareQuestion }) => {
    const totalAnswers = getTotalAnswers(question);
    return (
        <div className="question-analysis-chart__container">
            <h4 className="question-analysis-chart__title">{question.title}</h4>
            <div className="question-analysis-chart__charts">
                {compareQuestion && (
                    <div className="question-analysis-chart__comparison-chart">
                        <span className="question-analysis-chart__subtitle">{i18n(
                            'report'
                        )`${getTotalAnswers(compareQuestion)} Antworten`}</span>
                        <QuestionAnalysisBaseChart
                            className="question-analysis-chart"
                            question={compareQuestion}
                        />
                    </div>
                )}
                <div className="question-analysis-chart__actual-chart">
                    <span className="question-analysis-chart__subtitle">{i18n(
                        'report'
                    )`${totalAnswers} Antworten`}</span>
                    <QuestionAnalysisBaseChart
                        className="question-analysis-chart"
                        question={question}
                    />
                </div>
            </div>
            {compareQuestion && (
                <div className="question-analysis-chart__dates">
                    <span>{i18n`${new Date(compareDate)}:t(y)`}</span>
                    <span>{i18n('report')`Aktuell`}</span>
                </div>
            )}
        </div>
    );
};

export interface QuestionAnalysisBaseChartProps {
    question: QuestionModel;
    className?: string;
    options?: ChartWrapperOptions['options'];
}

export const QuestionAnalysisBaseChart = ({
    question,
    className,
    options
}: QuestionAnalysisBaseChartProps) => {
    const totalAnswers = getTotalAnswers(question);
    if (totalAnswers === 0) {
        return (
            <span className="question-analysis-chart__no-answers">{i18n(
                'report'
            )`Frage wurde nicht ausgewertet, da keine gültigen Antworten vorliegen`}</span>
        );
    }
    return (
        <Chart
            style={{ flex: 1 }}
            className={className}
            chartType="ColumnChart"
            options={{
                legend: { position: 'none' },
                vAxis: { format: 'percent', maxValue: 1 },
                fontName: 'Dosis',
                backgroundColor: 'none',
                ...options
            }}
            data={[
                ['%', '', { type: 'string', role: 'tooltip' }, { role: 'style' }],
                ...question.answers.map(a => {
                    const percentage = a.value / totalAnswers || 0;
                    return [a.name, percentage, `${(percentage * 100).toFixed(2)}%`, '#50f2b4'];
                })
            ]}
        />
    );
};

function getTotalAnswers(question: QuestionModel): number {
    return question.answers.reduce((sum, answer) => sum + answer.value, 0);
}
