import * as React from 'react';
import { useState } from 'react';
import { TabHeader } from './tab-header.component';
import './tabs.component.scss';

export const TabsContext = React.createContext(0);

export interface TabsProps {
    children;
    onTabChange?: (tab: number) => void;
    className?: string;
}

const Tabs = ({ className, children, onTabChange }: TabsProps) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const changeTab = (tab: number) => {
        if (onTabChange) {
            onTabChange(tab);
        }
        setSelectedTab(tab);
    };

    const childs = React.Children.toArray(children);
    const headers = childs.map((child, i) => (
        <TabHeader index={i} onSelect={() => changeTab(i)} key={i}>
            {child.props.header}
        </TabHeader>
    ));
    const content = childs[selectedTab].props.children;
    return (
        <TabsContext.Provider value={selectedTab}>
            <div className={`tabs ${className || ''}`}>
                <div className="tabs__header">{headers}</div>
                <div className="tabs__content">{content}</div>
            </div>
        </TabsContext.Provider>
    );
};

export default Tabs;
