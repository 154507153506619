import * as ReactDom from 'react-dom';
import Modal from 'react-modal';
import App from './app.component';
import { setupInternationalization } from './i18n/configurations';
import './safari-viewport-height-fix';

setupInternationalization();

// istanbul ignore next
Modal.setAppElement('#app');

// istanbul ignore next
ReactDom.render(App, document.getElementById('app'));
