import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import { mdiDelete } from '@mdi/js';
import Dialog from '../../../../common/ui-components/dialog/dialog.component';
import IconButton from '../../../../common/ui-components/icon-button/icon-button.component';
import DeleteTeamDialog from './delete-team-dialog.component';

const TeamDeleteButton = ({ onDelete }) => {
    const [showDialog, setShowDialog] = useState(false);
    return (
        <>
            <IconButton
                title={i18n`Team löschen`}
                icon={mdiDelete}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDialog(true);
                }}
            />
            <Dialog
                isOpen={showDialog}
                onRequestClose={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDialog(false);
                }}
            >
                <DeleteTeamDialog onCancel={() => setShowDialog(false)} onConfirm={onDelete} />
            </Dialog>
        </>
    );
};

export default TeamDeleteButton;
