import Icon from '@mdi/react';
import * as React from 'react';
import './icon-button.component.scss';

type IconButtonProps = {
    className?: string;
    onClick?: () => void;
    icon: string;
} & any;

const IconButton = ({ className, icon, ...props }: IconButtonProps) => (
    <button className={`icon-btn ${className || ''}`} {...props}>
        <Icon path={icon} size={1} />
    </button>
);

export default IconButton;
