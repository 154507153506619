import i18n from 'es2015-i18n-tag';
import React from 'react';
import { match as Match, withRouter } from 'react-router';
import Tab from '../../../../common/ui-components/tabs/tab.component';
import Tabs from '../../../../common/ui-components/tabs/tabs.component';
import AccountTeamList from './teams/account-team-list.component';
import AccountUserList from './users/account-user-list.component';
import { AccountOverview } from '../account-overview/account-overview.component';
import FetchAccount from '../../resolver/fetch-account.component';
import './view-account.component.scss';

interface FetchAccountRouteProps {
    match: Match<FetchAccountParams>;
}

export interface FetchAccountParams {
    accountId: string;
}

const ViewAccount = ({ match }: FetchAccountRouteProps) => {
    return (
        <div className="view-account">
            <FetchAccount>{account => <AccountOverview account={account} />}</FetchAccount>
            <Tabs className="view-account__tabs">
                <Tab header={i18n`Teams`}>
                    <AccountTeamList accountId={match.params.accountId} />
                </Tab>
                <Tab header={i18n`Users`}>
                    <AccountUserList accountId={match.params.accountId} />
                </Tab>
            </Tabs>
        </div>
    );
};

export default withRouter(ViewAccount);
