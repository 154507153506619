import * as React from 'react';
import { useState } from 'react';
import './checkbox.component.scss';

let checkboxId = 1;

export type CheckboxProps = {
    group: string;
    label: string;
    className?: string;
} & any;

const Checkbox = ({ label, className, disabled, title, ...props }: CheckboxProps) => {
    const [id] = useState(checkboxId++);

    return (
        <label className={`checkbox ${className}`} htmlFor={`checkbox-${id}`} title={title}>
            <input
                className="checkbox__input"
                disabled={disabled}
                type="checkbox"
                id={`checkbox-${id}`}
                {...props}
            />
            <div
                className={`checkbox__checkbox checkbox__checkbox--${
                    disabled ? 'disabled' : 'enabled'
                }`}
            />
            <span>{label}</span>
        </label>
    );
};

export default Checkbox;
