import { mdiDelete, mdiCompare } from '@mdi/js';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import { CompareQuestionSelection } from './compare-question-selection.component';

export const QuestionContainer = ({
    children,
    onRemove,
    compareItems,
    selectedComparison,
    onSelectComparison
}) => {
    const [showCompareSelection, setShowCompareSelection] = useState(false);
    const hasCompareItems = compareItems !== null && compareItems.length > 0;

    return (
        <div className="question-analysis__container">
            {children}
            <div className="question-analysis-chart__buttons">
                <IconButton
                    title={i18n('report')`Antwort-Verteilung entfernen`}
                    className="question-analysis__remove-chart-btn"
                    onClick={onRemove}
                    icon={mdiDelete}
                />
                {hasCompareItems && (
                    <IconButton
                        title={i18n('report')`Vergleich hinzufügen/ändern`}
                        className="question-analysis__compare-chart-btn"
                        onClick={() => setShowCompareSelection(true)}
                        icon={mdiCompare}
                    />
                )}
                {showCompareSelection && hasCompareItems && (
                    <CompareQuestionSelection
                        comparisons={compareItems}
                        selectedComparison={selectedComparison}
                        onSelect={value => {
                            setShowCompareSelection(false);
                            onSelectComparison(value);
                        }}
                        onCancel={() => setShowCompareSelection(false)}
                    />
                )}
            </div>
        </div>
    );
};
