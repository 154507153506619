import i18n from 'es2015-i18n-tag';
import { Field, Form, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import BackButton from '../../../common/ui-components/back-button/back-button.component';
import Input from '../../../common/ui-components/input/input.component';
import MultiSelect, {
    SelectItem
} from '../../../common/ui-components/multi-select/multi-select.component';
import PrimaryButton from '../../../common/ui-components/primary-button/primary-button.component';
import { getAllTeams } from '../../teams/team-service';
import { UserModel } from '../../users/models/user-model';
import { getUsers } from '../../users/user-service';
import { AssignedTeam } from '../models/account-create-model';
import { AccountModel } from '../models/account-model';
import './edit-account.component.scss';
import Button from '../../../common/ui-components/button/button.component';
import DatePicker from '../../../common/ui-components/date-picker/date-picker.component';
import { getQuotaType, isInTimeQuota, QuotaType } from '../account-quota';

export interface EditAccountProps {
    account?: AccountModel;
    teams?: AssignedTeam[];
    users?: UserModel[];
}

export interface EditAccountFormValue {
    name: string;
    users: SelectItem<UserModel>[];
    accountManagers: SelectItem<UserModel>[];
    teams: SelectItem<AssignedTeam>[];
    surveyQuota?: number;
    teamQuota?: number;
    validFrom?: Date;
    validUntil?: Date;
}

export const formSchema = yup.object().shape<EditAccountFormValue>({
    name: yup
        .string()
        .trim()
        .required(),
    users: yup.array(),
    accountManagers: yup.array<SelectItem<UserModel>>().min(1),
    teams: yup.array<SelectItem<AssignedTeam>>(),
    surveyQuota: yup.number().integer(),
    teamQuota: yup.number().integer(),
    validFrom: yup.date(),
    validUntil: yup.date()
});

const EditAccount = ({
    isValid,
    isSubmitting,
    values,
    account,
    ...props
}: FormikProps<EditAccountFormValue> & EditAccountProps) => {
    const [teams, setTeams] = useState<AssignedTeam[]>([]);
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        getAllTeams()
            .then(allTeams =>
                allTeams.filter(t => t.accountId == null || t.accountId === account?.id)
            )
            .then(setTeams);
        getUsers()
            .then(allUsers =>
                allUsers.filter(
                    u => (u.accountId == null || u.accountId === account?.id) && !u.disabled
                )
            )
            .then(setUsers);
    }, []);

    const availableUsers = users.filter(
        user =>
            values.users.every(u => u.value.id !== user.id) &&
            values.accountManagers.every(u => u.value.id !== user.id)
    );

    return (
        <Form noValidate>
            <div className="edit-account__form">
                <div className="edit-account__metadata">
                    <Field
                        component={Input}
                        autoFocus
                        title={i18n`Name`}
                        name="name"
                        className="edit-account__form-field"
                        required
                    />
                    <Field
                        component={MultiSelect}
                        title={i18n`Account Manager`}
                        name="accountManagers"
                        className="edit-account__form-field"
                        required
                        items={availableUsers.map(mapUserToSelectItem)}
                        getOptionValue={({ value }) => value.id}
                    />
                    <Field
                        component={MultiSelect}
                        title={i18n`User`}
                        name="users"
                        className="edit-account__form-field"
                        items={availableUsers.map(mapUserToSelectItem)}
                        getOptionValue={({ value }) => value.id}
                    />
                    <Field
                        component={MultiSelect}
                        title={i18n`Teams`}
                        name="teams"
                        className="edit-account__form-field"
                        items={teams.map(t => ({
                            label: t.name,
                            value: t
                        }))}
                        getOptionValue={({ value }) => value.id}
                    />
                </div>
                <EditAccountQuotaComponent
                    values={values}
                    setFieldValue={props.setFieldValue}
                    account={account}
                />
            </div>
            <div className="edit-account__actions">
                <PrimaryButton
                    title={i18n`Speichern`}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    className="edit-team__action-btn"
                />
                <BackButton className="edit-team__action-btn" />
            </div>
        </Form>
    );
};

const EditAccountQuotaComponent = ({
    values,
    setFieldValue,
    account
}: Partial<FormikProps<EditAccountFormValue>> & { account: AccountModel }) => {
    const [currentQuotaType, setCurrentQuota] = useState(getQuotaType(values));

    useEffect(() => {
        if (currentQuotaType !== QuotaType.Time) {
            setFieldValue('validFrom', '');
            setFieldValue('validUntil', '');
        }
        if (currentQuotaType === QuotaType.None) {
            setFieldValue('surveyQuota', '');
            setFieldValue('teamQuota', '');
        }
    }, [currentQuotaType]);

    return (
        <div className="edit-account__quotas">
            <h2>{i18n`Kontingentierung`}</h2>
            <div className="edit-account__quota-selector">
                {selectButton(QuotaType.None, i18n`Keine`, () => setCurrentQuota(QuotaType.None))}
                {selectButton(QuotaType.Time, i18n`Nach Zeit`, () =>
                    setCurrentQuota(QuotaType.Time)
                )}
                {selectButton(QuotaType.Quantity, i18n`Nach Anzahl`, () =>
                    setCurrentQuota(QuotaType.Quantity)
                )}
            </div>
            {currentQuotaType === QuotaType.Time && (
                <EditTemporalAccountQuotas
                    values={values}
                    setFieldValue={setFieldValue}
                    account={account}
                />
            )}
            {currentQuotaType === QuotaType.Quantity && (
                <EditNonTemporalAccountQuotas
                    account={account}
                    surveyTitle={i18n`Grenzwert Befragungen`}
                    teamTitle={i18n`Grenzwert Teams`}
                />
            )}
        </div>
    );

    function selectButton(quotaType: QuotaType, title: string, onClick: () => void) {
        return (
            <>
                {currentQuotaType === quotaType && <PrimaryButton title={title} />}
                {currentQuotaType !== quotaType && <Button title={title} onClick={onClick} />}
            </>
        );
    }
};

const EditTemporalAccountQuotas = ({
    values,
    account,
    setFieldValue
}: Partial<FormikProps<EditAccountFormValue>> & { account: AccountModel }) => {
    useEffect(() => {
        if (!values.validUntil && values.validFrom) {
            const oneYearLater = new Date(
                values.validFrom.getFullYear() + 1,
                values.validFrom.getMonth(),
                values.validFrom.getDate() - 1
            );
            setFieldValue('validUntil', oneYearLater);
        }
    }, [values.validFrom]);

    return (
        <>
            <div className="edit-account__date-picker">
                <Field
                    component={DatePicker}
                    title={i18n`Laufzeit von`}
                    name="validFrom"
                    className="edit-account__form-field"
                    maxDate={values.validUntil}
                />
                <Field
                    component={DatePicker}
                    title={i18n`Laufzeit bis`}
                    name="validUntil"
                    className="edit-account__form-field"
                    minDate={values.validFrom}
                />
            </div>
            <TemporalQuotasNotice values={values} />
            <EditNonTemporalAccountQuotas
                account={account}
                surveyTitle={i18n`Benachrichtigungswert Befragungen`}
                teamTitle={i18n`Benachrichtigungswert Teams`}
                disabled={!values.validUntil}
            />
        </>
    );
};

const TemporalQuotasNotice = ({ values }: Partial<FormikProps<EditAccountFormValue>>) => {
    const isInTimeRange = isInTimeQuota(values);
    if (isInTimeRange == null) {
        return <></>;
    }
    if (isInTimeRange) {
        return (
            <span className="edit-account__time-quota-notice">{i18n`Account innerhalb der Laufzeit`}</span>
        );
    } else {
        return (
            <span className="edit-account__time-quota-notice edit-account__time-quota-notice--warn">{i18n`Account außerhalb der Laufzeit`}</span>
        );
    }
};

const EditNonTemporalAccountQuotas = ({ surveyTitle, teamTitle, account, ...props }: any) => {
    const surveyCount = account == null ? '' : i18n`(Aktuell: ${account.metadata.surveyRunCount})`;
    const teamCount = account == null ? '' : i18n`(Aktuell: ${account.metadata.teamCount})`;

    return (
        <>
            <Field
                component={Input}
                title={[surveyTitle, surveyCount].join(' ')}
                name="surveyQuota"
                className="edit-account__form-field"
                type="number"
                min={0}
                {...props}
            />
            <Field
                component={Input}
                title={[teamTitle, teamCount].join(' ')}
                name="teamQuota"
                className="edit-account__form-field"
                type="number"
                min={0}
                {...props}
            />
        </>
    );
};

const mapUserToSelectItem = (user: UserModel): SelectItem<UserModel> => ({
    value: user,
    label: `${user.firstName} ${user.lastName}`
});

export default EditAccount;
