import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useNotification } from '../../../../../common/ui-components/notification';
import { Table, TableHeader } from '../../../../../common/ui-components/table';
import TableBody from '../../../../../common/ui-components/table/table-body.component';
import TableColumn from '../../../../../common/ui-components/table/table-column.component';
import './member-list.component.scss';
import { TeamMember } from '../../../models/team-model';
import MemberRow from './member-row.component';

const MemberList = ({
    disabled,
    members,
    editing,
    openSurveys,
    onEdit,
    onCancel,
    onSave,
    onDelete
}) => {
    const showNotification = useNotification();

    const save = (member: TeamMember, memberIndex: number) => (memberUpdate: TeamMember) => {
        return onSave(memberUpdate, memberIndex).then(() => {
            if (!openSurveys) {
                return;
            }
            if (memberUpdate.email === member.email) {
                return;
            }
            showNotification(i18n`Team-Mitglied wurde zur Umfrage eingeladen`);
        });
    };

    return (
        <div className="view-team__members">
            {openSurveys && (
                <span className="view-team__members-update-notice">{i18n`Änderungen an der Mitgliederliste gelten auch für laufende Umfragen`}</span>
            )}
            <Table>
                <TableHeader>
                    <TableColumn width={1}>{i18n`Vorname`}</TableColumn>
                    <TableColumn width={1}>{i18n`Nachname`}</TableColumn>
                    <TableColumn width={1}>{i18n`Position`}</TableColumn>
                    <TableColumn width={1}>{i18n`E-Mail`}</TableColumn>
                    <TableColumn width="50px" />
                </TableHeader>
                <TableBody>
                    {members.map((member, i) => (
                        <MemberRow
                            disabled={disabled}
                            member={member}
                            key={i}
                            onEdit={() => onEdit(i)}
                            onSave={save(member, i)}
                            onCancel={() => onCancel()}
                            onDelete={() => onDelete(i)}
                            editing={editing === i}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default MemberList;
