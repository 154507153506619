import { useState } from 'react';
import * as React from 'react';
import './textarea.component.scss';

type TextAreaProps = {
    title: string;
    className?: string;
    required?: boolean;
} & any;

let textAreaId = 1;

const TextArea = ({ title, className, required, field, ...props }: TextAreaProps) => {
    const [id] = useState(textAreaId++);

    const name = field ? field.name : props.name;
    const value = field ? field.value : props.value;
    const onChange = field ? field.onChange : props.onChange;
    const onBlur = field ? field.onBlur : props.onBlur;

    return (
        <div className={`textarea ${className || ''} ${value ? '' : 'textarea--empty'}`}>
            <label htmlFor={`textarea-${id}`} className="textarea__label">
                {title}
                {required && '*'}
            </label>
            <textarea
                id={`textarea-${id}`}
                className="textarea__field"
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                required={required}
                value={value}
                {...props}
            />
            <p className="textarea__value">{value}</p>
        </div>
    );
};

export default TextArea;
