import i18n from 'es2015-i18n-tag';
import { default as React, useEffect, useRef, useState } from 'react';
import { http } from '../../../../common/http-client';

const MEGABYTE_MULTIPLICATOR = 1024 * 1024;
const FILE_SIZE_LIMIT = 5;
const IMAGE_BOUNDARIES = {
    width: 200,
    height: 200
};

const FileUpload = ({ onSelect, selected }) => {
    const fileUpload = useRef(null);

    const [hover, setHover] = useState(false);

    useEffect(() => {
        const enable = () => setHover(true);
        const disable = () => setHover(false);

        document.addEventListener('dragenter', enable);
        document.addEventListener('dragover', enable);
        document.addEventListener('dragleave', disable);
        document.addEventListener('drop', disable);

        return () => {
            document.removeEventListener('dragenter', enable);
            document.removeEventListener('dragover', enable);
            document.removeEventListener('dragleave', disable);
            document.removeEventListener('drop', disable);
        };
    }, []);

    const clickUpload = () => fileUpload.current.click();

    const showOverlay = !selected || hover;

    return (
        <>
            <div
                className={`edit-team__image-overlay ${
                    showOverlay ? 'edit-team__image-overlay--no-file' : ''
                }`}
                onClick={clickUpload}
            >
                Datei auswählen
            </div>
            <input
                ref={fileUpload}
                type="file"
                accept=".png,.jpeg,.jpg"
                onChange={event => onSelect(event.target.files[0])}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                multiple={false}
                className="edit-team__file-upload"
            />
        </>
    );
};

export const ImageUpload = ({ teamId, onChange }) => {
    const imageRef = useRef(null);
    const [state, setState] = useState({
        image: null,
        file: null,
        width: null,
        height: null
    });
    useEffect(() => {
        (async () => {
            const res = await http(`/api/teams/${teamId}/logo`);
            if (!res.ok) {
                return;
            }
            const body = await res.blob();
            const url = await readToDataUrl(body);
            // @ts-ignore
            setState({ image: url });
        })();
    }, []);

    const updateImage = file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            return;
        }
        readToDataUrl(file)
            // @ts-ignore
            .then(image => setState({ ...state, file, image }));
    };
    const onLoad = event => {
        const nextState = {
            ...state,
            width: event.target.naturalWidth,
            height: event.target.naturalHeight
        };
        setState(nextState);
        const { result } = validate(nextState);
        if (result && state.file != null) {
            onChange(state.file);
        }
    };

    const validation = validate(state);

    return (
        <div className="edit-team__image-container">
            <label className="edit-team__image-upload-label">Team Logo</label>
            <div className="edit-team__image-upload">
                <img
                    ref={imageRef}
                    src={state.image}
                    onLoad={onLoad}
                    width={256}
                    height={256}
                    className="edit-team__image-preview"
                />
                <FileUpload selected={state.image != null} onSelect={updateImage} />
            </div>
            <span
                className={`edit-team__image-hint ${
                    !validation.result && !validation.dimension
                        ? 'edit-team__image-hint--invalid'
                        : ''
                }`}
            >
                {i18n`Mindestauflösung: ${IMAGE_BOUNDARIES.width}x${IMAGE_BOUNDARIES.height} Pixel`}
            </span>
            <span
                className={`edit-team__image-hint ${
                    !validation.result && !validation.size ? 'edit-team__image-hint--invalid' : ''
                }`}
            >
                {i18n`Maximale Dateigröße: ${FILE_SIZE_LIMIT}MB`}
            </span>
            <span className="edit-team__image-hint">{i18n`Zugelassene Dateiformate: JPG, PNG`}</span>
        </div>
    );
};

function readToDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);
        fileReader.addEventListener('load', (event: any) => {
            resolve(event.target.result);
        });
        fileReader.addEventListener('error', err => reject(err));
    });
}

export interface ValidationResult {
    result: boolean;
    dimension: boolean;
    size: boolean;
}

function validate(state): ValidationResult {
    const hasFile = state.file != null;
    const dimensionMatches =
        state.width >= IMAGE_BOUNDARIES.width && state.height >= IMAGE_BOUNDARIES.height;
    const sizeMatches = state.file?.size <= FILE_SIZE_LIMIT * MEGABYTE_MULTIPLICATOR;

    return {
        result: !hasFile || (dimensionMatches && sizeMatches),
        dimension: dimensionMatches,
        size: sizeMatches
    };
}
