import * as React from 'react';
import TetherComponent from 'react-tether';
import { ITetherConstraint } from 'tether';
import { FactorTrendModel, TrendGraphModel } from '../../models/report-model';
import TrendComment from './trend-comment/trend-comment.component';
import {
    TrendFactorSelection,
    TrendFactorSelectionProps
} from './trend-factor-selection.component';
import { TrendGraphContainer, TrendGraphContainerProps } from './trend-graph-container.component';

const inlineFactorSelectionMediaQuery = window.matchMedia('screen and (max-width: 1425px)');

export interface TrendProps {
    graph: TrendGraphModel;
    onUpdate: (update: TrendGraphModel) => void;
    onRemove: () => void;
    onToggleSettings: () => void;
    culture: string;
    factors: FactorTrendModel[];
    settingsOpen: boolean;
    selectedDates: string[];
    hasSixPossibleAnswers: boolean;
}

const constraints: ITetherConstraint[] = [
    {
        to: 'scrollParent',
        attachment: 'together',
        outOfBoundsClass: 'trends__trend--out-of-bounds'
    }
];

export interface TrendState {
    showInline: boolean;
}

class Trend extends React.Component<TrendProps, TrendState> {
    state = {
        showInline: inlineFactorSelectionMediaQuery.matches
    };

    componentDidMount(): void {
        inlineFactorSelectionMediaQuery.addListener(this.onSizeChange);
    }

    componentWillUnmount(): void {
        inlineFactorSelectionMediaQuery.removeListener(this.onSizeChange);
    }

    render() {
        const trendProps: TrendInternalProps = this.buildInternalProps();

        if (this.state.showInline) {
            return <InlineTrend {...trendProps} />;
        } else {
            return <OutlineTrend {...trendProps} />;
        }
    }

    private onSizeChange = (event: MediaQueryListEvent) => {
        this.setState({
            showInline: event.matches
        });
    };

    private buildInternalProps(): TrendInternalProps {
        const {
            graph,
            culture,
            factors,
            onUpdate,
            onRemove,
            onToggleSettings,
            settingsOpen,
            selectedDates,
            hasSixPossibleAnswers
        } = this.props;

        return {
            settingsOpen,
            trend: {
                graph,
                culture,
                factors,
                onRemove,
                onToggleSettings,
                settingsActive: settingsOpen,
                selectedDates,
                hasSixPossibleAnswers
            },
            factor: {
                factors,
                selection: graph.selectedFactors,
                culture,
                onToggleFactor: this.onToggleFactor,
                onSelectAll: this.onSelectAll,
                onDeselectAll: this.onDeselectAll
            },
            onUpdate
        };
    }

    private onToggleFactor = factorIndex => {
        const selectedFactors = this.getSelectedFactor(factorIndex);
        const update = {
            ...this.props.graph,
            selectedFactors
        };
        this.props.onUpdate(update);
    };

    private getSelectedFactor = (factorIndex: number): number[] => {
        const graph = this.props.graph;
        if (!graph.selectedFactors.includes(factorIndex)) {
            return [...graph.selectedFactors, factorIndex];
        } else {
            return graph.selectedFactors.filter(i => i !== factorIndex);
        }
    };

    private onSelectAll = () =>
        this.props.onUpdate({
            ...this.props.graph,
            selectedFactors: this.props.factors.map((f, i) => i)
        });

    private onDeselectAll = () =>
        this.props.onUpdate({
            ...this.props.graph,
            selectedFactors: []
        });
}

interface TrendInternalProps {
    settingsOpen: boolean;
    trend: TrendGraphContainerProps;
    factor: TrendFactorSelectionProps;
    onUpdate: (update: TrendGraphModel) => void;
}

const InlineTrend = ({ settingsOpen, trend, factor, onUpdate }: TrendInternalProps) => (
    <>
        <TrendGraphContainer {...trend} />
        {settingsOpen && <TrendFactorSelection {...factor} />}
        {!settingsOpen && (
            <TrendComment graph={trend.graph} culture={trend.culture} onUpdate={onUpdate} />
        )}
    </>
);

const OutlineTrend = ({ settingsOpen, trend, factor, onUpdate }: TrendInternalProps) => (
    <TetherComponent
        attachment="middle right"
        constraints={constraints}
        offset="0 -96px"
        enabled={settingsOpen}
        renderTarget={ref => (
            <>
                <TrendGraphContainer ref={ref} {...trend} />
                <TrendComment graph={trend.graph} culture={trend.culture} onUpdate={onUpdate} />
            </>
        )}
        renderElement={ref => settingsOpen && <TrendFactorSelection ref={ref} {...factor} />}
    />
);

export default Trend;
