import * as React from 'react';
import { TableHeader } from './index';
import { TableSorting } from './sort-helpers';
import TableColumn from './table-column.component';
import { SortDirection } from './table.component';

export interface TableSortColumn {
    row: string;
    label: () => string;
}

export interface TableSortHeaderProps {
    onSort: (row: string, direction: SortDirection) => void;
    sorting: TableSorting;
    columns: TableSortColumn[];
    children?: any;
}

const TableSortHeader = ({ onSort, sorting, columns, children }: TableSortHeaderProps) => {
    const sortRow = (row: string) => () => {
        const prevDirection = getDirection(row);
        const direction =
            prevDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        onSort(row, direction);
    };

    const getDirection = (row: string) => {
        if (sorting.row === row) {
            return sorting.direction;
        }
        return SortDirection.None;
    };

    return (
        <TableHeader>
            {columns.map(({ row, label }) => (
                <TableColumn key={row} width={1} sorting={getDirection(row)} onClick={sortRow(row)}>
                    {label()}
                </TableColumn>
            ))}
            {children}
        </TableHeader>
    );
};

export default TableSortHeader;
