import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Header from '../common/shell/header/header.component';
import { withAuth } from '../domains/auth/context/auth-context';
import RouteWithTitle from './route-with-title.component';
import routes from './routes';

const Routes = ({ user }) => (
    <Switch>
        {routes
            .filter(r => user.role >= r.role)
            .map(route => (
                <Route key={route.path} path={route.path} exact={route.exact}>
                    <Header className="app__header" backArrow={route.backArrow} />
                    <div className="app__content">
                        <RouteWithTitle route={route} />
                    </div>
                </Route>
            ))}
        <Route>
            <Redirect to="/teams" />
        </Route>
    </Switch>
);

export default withAuth()(Routes);
