import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { TableColumn } from '../../../common/ui-components/table';
import { TableSorting } from '../../../common/ui-components/table/sort-helpers';
import TableSortHeader, {
    TableSortColumn
} from '../../../common/ui-components/table/table-sort-header.component';
import { SortDirection } from '../../../common/ui-components/table/table.component';

export interface UserListHeaderProps {
    onSort: (row: string, direction: SortDirection) => void;
    sorting: TableSorting;
    children: React.ReactNode;
    showAccountColumn?: boolean;
}

const columns: TableSortColumn[] = [
    {
        row: 'firstName',
        label: () => i18n`Vorname`
    },
    {
        row: 'lastName',
        label: () => i18n`Nachname`
    }
];

const UserListHeader = ({ children, showAccountColumn, ...props }: UserListHeaderProps) => (
    <TableSortHeader columns={columns} {...props}>
        <TableColumn width={1}>{i18n`E-Mail`}</TableColumn>
        <TableColumn width={1}>{i18n`Telefon`}</TableColumn>
        {showAccountColumn && <TableColumn width={1}>{i18n`Account`}</TableColumn>}
        {children}
        <TableColumn width="50px" />
    </TableSortHeader>
);

export default UserListHeader;
