import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { Ticks } from 'react-compound-slider';

const TimeSelectionTicks = ({ ticks, dates }) => (
    <div className="trend-time-selection__ticks">
        {ticks.map((t, i) => (
            <TimeSelectionTick key={t.id} tick={t} date={dates[i]} />
        ))}
    </div>
);

const TimeSelectionTick = ({ date, tick }) => (
    <span
        className="trend-time-selection__tick"
        style={{ left: `${tick.percent}%` }}
    >{i18n`${new Date(date)}:t(d)`}</span>
);

export default TimeSelectionTicks;
