import { http, httpDelete, httpGet, httpPost, httpPut } from '../../common/http-client';
import { BusinessTeamCreateModel } from './models/business-team-create-model';
import { SurveyTemplateModel } from './models/survey-template-model';
import { SportTeamCreateModel } from './models/sport-team-create-model';
import { TeamCreateModel } from './models/team-create-model';
import { SurveyRunModel, TeamModel } from './models/team-model';
import { TeamUpdateModel } from './models/team-update-model';

export async function getSportTemplates(): Promise<SurveyTemplateModel[]> {
    const templates = await httpGet<SurveyTemplateModel[]>('/api/templates');

    return templates.filter(t => !t.isBusiness);
}

export async function getBusinessTemplates(): Promise<SurveyTemplateModel[]> {
    const templates = await httpGet<SurveyTemplateModel[]>('/api/templates');

    return templates.filter(t => t.isBusiness);
}

export async function createSportTeam(team: SportTeamCreateModel) {
    await createTeam({ ...team, isBusiness: false });
}

export async function createBusinessTeam(team: BusinessTeamCreateModel) {
    await createTeam({ ...team, isBusiness: true });
}

async function createTeam(team: TeamCreateModel) {
    await httpPost('/api/admin/teams', team);
}

export async function createAccountSportTeam(team: SportTeamCreateModel) {
    await createAccountTeam({ ...team, isBusiness: false });
}

export async function createAccountBusinessTeam(team: BusinessTeamCreateModel) {
    await createAccountTeam({ ...team, isBusiness: true });
}

async function createAccountTeam(team: TeamCreateModel) {
    await httpPost('/api/account/teams', team);
}

/**
 * Returns a list of all teams, sorted by newest first
 */
export async function getAllTeams(): Promise<TeamModel[]> {
    return await httpGet<TeamModel[]>('/api/admin/teams');
}

/**
 * Returns a list of all teams of the current account, sorted by newest first
 */
export async function getAccountTeams(): Promise<TeamModel[]> {
    return await httpGet<TeamModel[]>('/api/account/teams');
}

export async function getTeam(id: string): Promise<TeamModel> {
    return httpGet(`/api/teams/${id}`);
}

export async function updateTeam(team: TeamUpdateModel) {
    await httpPut(`/api/teams/${team.id}`, team);
}

export async function getMyTeams(): Promise<TeamModel[]> {
    return await httpGet('/api/teams');
}

export async function startSurveyRun(teamId: string): Promise<void> {
    await httpPut(`/api/teams/${teamId}/runs/start`, null);
}

export async function deleteTeam(team: TeamModel): Promise<void> {
    await httpDelete(`/api/teams/${team.id}`);
}

export async function stopSurveyRun(team: TeamModel, run: SurveyRunModel): Promise<void> {
    await httpPost(`/api/teams/${team.id}/runs/${run.id}/terminate`, null);
}

export async function remindMembers(team: TeamModel, run: SurveyRunModel): Promise<void> {
    await httpPost(`/api/teams/${team.id}/runs/${run.id}/remind`, null);
}

export async function deleteReport(team: TeamModel, run: SurveyRunModel): Promise<void> {
    await httpDelete(`/api/teams/${team.id}/runs/${run.id}/report`);
}

export async function updateImage(teamId: string, data: Blob): Promise<void> {
    await http(`/api/teams/${teamId}/logo`, 'POST', {
        body: data
    });
}
