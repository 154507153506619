import * as React from 'react';
import { AuthState, Role, withAuth } from '../../../domains/auth/context/auth-context';
import { RouteDefinition } from '../../../routing/routes';
import { SidenavLink } from './sidenav-link.component';

export interface SidenavCategoryProps {
    title: string;
    links: RouteDefinition[];
    role: Role;
}

const SidenavCategory = ({ title, links, role, user }: SidenavCategoryProps & AuthState) => {
    // tslint:disable-next-line:no-bitwise
    if ((user.role & role) !== role) {
        return <></>;
    }

    const sidenavLinks = links.filter(l => l.role === role).map(buildSidenavLink);
    return (
        <>
            <h2 className="sidenav__title">{title}</h2>
            {sidenavLinks}
        </>
    );
};

const buildSidenavLink = (route: RouteDefinition) => (
    <SidenavLink key={route.path} to={route.path} icon={route.icon}>
        <route.title />
    </SidenavLink>
);

export default withAuth()(SidenavCategory) as React.ComponentType<SidenavCategoryProps>;
