import Icon from '@mdi/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const SidenavLink = ({ children, icon, to }) => (
    <Link className="sidenav__link" to={to}>
        <Icon path={icon} size={1} className="sidenav__link-icon" />
        <h3 className="sidenav__link-title">{children}</h3>
    </Link>
);
export const SidenavExternalLink = ({ children, to }) => (
    <a className="sidenav__external-link" href={to} target="_blank">
        <h3 className="sidenav__link-title">{children}</h3>
    </a>
);
