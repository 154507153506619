import ReactDatePicker from 'react-date-picker';
import React, { ChangeEvent, useState } from 'react';
import { mdiCalendar, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { FormikHandlers } from 'formik';
import './date-picker.component.scss';

let datePickerId = 1;

const DatePicker = ({ title, field, className, ...props }: any) => {
    const [id] = useState(datePickerId++);

    const name = field ? field.name : props.name;
    const value = field ? field.value : props.value;
    const onChange = field ? wrapOnChange(field.onChange, name) : props.onChange;
    if (props.minDate != null && typeof props.minDate === 'string') {
        props.minDate = new Date(props.minDate);
    }
    if (props.maxDate != null && typeof props.maxDate === 'string') {
        props.maxDate = new Date(props.maxDate);
    }

    return (
        <div className={`date-picker ${className}`}>
            <label htmlFor={`date-picker-${id}`} className="date-picker__label">
                {title}
            </label>
            <ReactDatePicker
                className="date-picker__picker"
                calendarClassName="date-picker__calendar"
                calendarIcon={<Icon size="16px" path={mdiCalendar} />}
                clearIcon={value ? <Icon size="16px" path={mdiClose} /> : null}
                closeCalendar={true}
                name={name}
                value={value}
                onChange={onChange}
                {...props}
            />
        </div>
    );
};

function wrapOnChange(
    onChange: FormikHandlers['handleChange'],
    name: string
): (value: Date) => void {
    return (value: Date | ChangeEvent<HTMLInputElement>) => {
        if (value instanceof Date) {
            onChange({
                target: {
                    name,
                    value
                }
            });
        } else {
            onChange(value);
        }
    };
}

export default DatePicker;
