import * as React from 'react';
import { AsyncState } from 'react-async';
import { Async, AsyncErrorHandling, Rejected, Resolved } from '../../../common/async';
import * as accountService from '../account-service';
import { AccountModel } from '../models/account-model';

export interface FetchMyAccountProps {
    children: (account: AccountModel, state: AsyncState<AccountModel>) => React.ReactNode;
}

const FetchMyAccount = ({ children }: FetchMyAccountProps) => (
    <Async promiseFn={accountService.getMyAccount}>
        <Resolved>{children}</Resolved>
        <Rejected>{error => <AsyncErrorHandling error={error} />}</Rejected>
    </Async>
);

export default FetchMyAccount as React.ComponentType<FetchMyAccountProps>;
