import { mdiDelete, mdiSettings } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import IconButton from '../../../../common/ui-components/icon-button/icon-button.component';
import { FactorTrendModel } from '../../models/report-model';
import TrendGraph, { TrendGraphProps } from './trend-graph.component';

export type TrendGraphContainerProps = {
    factors: FactorTrendModel[];
    onRemove: () => void;
    onToggleSettings: () => void;
    settingsActive: boolean;
} & TrendGraphProps;

const GraphPlaceholder = () => (
    <div className="trends__trend-graph-placeholder">
        {i18n('report')`Bitte Faktoren auswählen`}
    </div>
);

export const TrendGraphContainer = React.forwardRef(
    ({ settingsActive, onToggleSettings, onRemove, ...props }: TrendGraphContainerProps, ref) => {
        const graph =
            props.graph.selectedFactors.length === 0 ? (
                <GraphPlaceholder />
            ) : (
                <TrendGraph {...props} />
            );

        return (
            <div ref={ref as any} className="trends__trend">
                {graph}
                <IconButton
                    title={i18n('report')`Faktoren auswählen`}
                    className={`trends__configure-graph-btn ${
                        settingsActive ? 'trends__configure-graph-btn--active' : ''
                    }`}
                    onClick={onToggleSettings}
                    icon={mdiSettings}
                />
                <IconButton
                    title={i18n('report')`Graph Entfernen`}
                    className="trends__remove-graph-btn"
                    onClick={onRemove}
                    icon={mdiDelete}
                />
            </div>
        );
    }
);
