import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { TableSortColumn } from '../../../../../common/ui-components/table/table-sort-header.component';
import TeamList, { TeamListProps } from '../team-list.component';
import BusinessTeamListRow from './business-team-list-row.component';

const defaultBusinessColumns: TableSortColumn[] = [
    {
        row: 'name',
        label: () => i18n`Name des Teams`
    },
    {
        row: 'company',
        label: () => i18n`Firma`
    },
    {
        row: 'location',
        label: () => i18n`Standort`
    },
    {
        row: 'advisor',
        label: () => i18n`Berater`
    }
];
export const BusinessTeamList = (props: TeamListProps) => {
    const teams = props.teams.filter(t => t.isBusiness);
    const businessColumns: TableSortColumn[] = props.showAccountColumn
        ? [
              ...defaultBusinessColumns,
              {
                  row: 'account',
                  label: () => i18n`Account`
              }
          ]
        : [...defaultBusinessColumns];

    return (
        <TeamList {...props} header={businessColumns} teams={teams}>
            {(team, onDelete) => (
                <BusinessTeamListRow
                    key={team.id}
                    team={team}
                    onDelete={() => onDelete()}
                    showAccountColumn={props.showAccountColumn}
                />
            )}
        </TeamList>
    );
};
