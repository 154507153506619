import React, { createContext, useState } from 'react';
import { currentLanguage, setLocale } from './configurations';

export const LocaleContext = createContext<LocaleContextState>({ lang: currentLanguage });

export interface LocaleContextState {
    lang: string;
    switchLanguage?: (lang: string) => void;
}

export const LocaleProvider = ({ children }) => {
    const [lang, setLang] = useState(currentLanguage);

    const switchLanguage = nextLang => {
        if (nextLang === lang) {
            return;
        }
        setLocale(nextLang);
        setLang(nextLang);
    };

    return (
        <LocaleContext.Provider value={{ lang, switchLanguage }}>{children}</LocaleContext.Provider>
    );
};
