import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import './multi-select.component.scss';

export interface SelectItem<V = any> {
    label: string;
    value: V;
}

type SelectProps<V = any> = {
    title: string;
    items: SelectItem<V>[];
    className?: string;
    onChange?: (value: SelectItem<V>[]) => void;
} & any;

let selectId = 1;

const MultiSelect = ({
    title,
    className,
    items,
    required,
    field,
    form,
    small,
    ...props
}: SelectProps) => {
    const [id] = useState(selectId++);

    const name = field ? field.name : props.name;
    const onChange = (item: SelectItem[]) => {
        if (field) {
            form.setFieldValue(field.name, item);
        } else {
            props.onChange(item);
        }
    };
    const onBlur = field ? field.onBlur : props.onBlur;
    const value = field ? field.value : props.value;

    return (
        <div className={`multi-select ${className || ''}`}>
            {title && (
                <label htmlFor={`multi-select-${id}`} className="multi-select__label">
                    {title}
                    {required && '*'}
                </label>
            )}
            <Select
                {...props}
                id={`multi-select-${id}`}
                name={name}
                isMulti={true}
                options={items}
                className="multi-select__field"
                classNamePrefix="multi-select"
                styles={{
                    control: () => ({})
                }}
                placeholder={null}
                noOptionsMessage={() => i18n`Keine Einträge`}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
            />
        </div>
    );
};

export default MultiSelect;
