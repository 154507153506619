import * as React from 'react';
import PageTitle from '../common/ui-components/page-title/page-title.component';
import { RouteDefinition } from './routes';

interface RouteWithTitleProps {
    route: RouteDefinition;
}

const RouteWithTitle = ({ route }: RouteWithTitleProps) => {
    return (
        <>
            <PageTitle className="app__title">
                {route.header && <route.header />}
                {!route.header && <route.title />}
            </PageTitle>
            <route.content />
        </>
    );
};

export default RouteWithTitle;
