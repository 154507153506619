import { mdiAccountOff } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import IconButton from '../../../common/ui-components/icon-button/icon-button.component';
import { AuthState, withAuth } from '../../auth/context/auth-context';
import { UserModel } from '../models/user-model';

interface DisableUserButtonProps {
    onDisable: () => void;
    user: UserModel;
    disabled?: boolean;
}

interface MappedProps extends DisableUserButtonProps {
    userId: string;
}

const DisableUserButton = ({ onDisable, user, userId, disabled }: MappedProps) => {
    const isDisabled = userId === user.id || disabled || user.isOwner;
    return (
        <IconButton
            title={
                isDisabled
                    ? user.isOwner
                        ? i18n`Dieser User kann nicht deaktiviert werden`
                        : ''
                    : i18n`User deaktivieren`
            }
            icon={mdiAccountOff}
            onClick={onDisable}
            disabled={isDisabled}
        />
    );
};

export default withAuth((auth: AuthState, component: DisableUserButtonProps): any => ({
    ...component,
    userId: auth.user.id
}))(DisableUserButton) as React.ComponentType<DisableUserButtonProps>;
