import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import RadioButton from '../../../../common/ui-components/radio-button/radio-button.component';
import { FactorModel, GroupDynamicFactorsModel } from '../../models/report-model';
import FactorChart from './factor-chart.component';
import './group-dynamic-factors.component.scss';

export interface GroupDynamicFactorsProps {
    className?: string;
    factors: GroupDynamicFactorsModel;
    lang: string;
    onChange: (showIndividualDiagrams: boolean) => void;
    isBusiness: boolean;
    hasSixPossibleAnswers: boolean;
}

const isValidFactor = (f: FactorModel) =>
    typeof f.average === 'number' && typeof f.variance === 'number';

const GroupDynamicFactors = ({
    className,
    factors,
    onChange,
    lang,
    isBusiness,
    hasSixPossibleAnswers
}: GroupDynamicFactorsProps) => {
    const showIndividualDiagrams = !isBusiness && factors.showIndividualDiagrams;
    const hasIndividualDiagrams =
        factors.players.some(isValidFactor) && factors.staff.some(isValidFactor);

    return (
        <div className={className}>
            <h3 className="group-dynamic-factors__title">{i18n(
                'report'
            )`Gruppendynamische Faktoren`}</h3>
            {!isBusiness && (
                <div className="group-dynamic-factors__selection">
                    <RadioButton
                        className="group-dynamic-factors__radio-button"
                        label={i18n('report')`Gesamtes Team`}
                        group="group-dynamic-factors"
                        checked={!showIndividualDiagrams}
                        onChange={() => onChange(false)}
                    />
                    <RadioButton
                        className="group-dynamic-factors__radio-button"
                        label={i18n('report')`Spieler und Staff getrennt`}
                        group="group-dynamic-factors"
                        disabled={!hasIndividualDiagrams}
                        checked={showIndividualDiagrams}
                        onChange={() => onChange(true)}
                        title={
                            hasIndividualDiagrams
                                ? ''
                                : i18n('report')`Es liegen keine Antworten von Spielern/Staff vor`
                        }
                    />
                </div>
            )}
            <div>
                {!showIndividualDiagrams && (
                    <FactorChart
                        title={i18n('report')`Alle Teilnehmer`}
                        data={factors.total}
                        lang={lang}
                        hasSixPossibleAnswers={hasSixPossibleAnswers}
                    />
                )}
                {showIndividualDiagrams && (
                    <FactorChart
                        title={i18n('report')`Spieler`}
                        data={factors.players}
                        lang={lang}
                        hasSixPossibleAnswers={hasSixPossibleAnswers}
                    />
                )}
                {showIndividualDiagrams && (
                    <FactorChart
                        title={i18n('report')`Staff`}
                        data={factors.staff}
                        lang={lang}
                        hasSixPossibleAnswers={hasSixPossibleAnswers}
                    />
                )}
            </div>
        </div>
    );
};

export default GroupDynamicFactors;
