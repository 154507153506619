import { useState } from 'react';
import * as React from 'react';
import './input.component.scss';

type InputProps = {
    title: string;
    className?: string;
    required?: boolean;
} & any;

let inputId = 1;

const Input = ({ title, className, required, field, ...props }: InputProps) => {
    const [id] = useState(inputId++);

    const name = field ? field.name : props.name;
    const value = field ? field.value : props.value;
    const onChange = field ? field.onChange : props.onChange;
    const onBlur = field ? field.onBlur : props.onBlur;

    return (
        <div className={`input ${className || ''} ${props.disabled && 'input--disabled'}`}>
            <label htmlFor={`input-${id}`} className="input__label">
                {title}
                {required && '*'}
            </label>
            <input
                id={`input-${id}`}
                className="input__field"
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                required={required}
                value={value}
                {...props}
            />
        </div>
    );
};

export default Input;
