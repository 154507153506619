import { mdiAccountSupervisorCircle, mdiTable } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { RouteDefinition } from '../../routing/routes';
import { Role } from '../auth/context/auth-context';
import FetchAccount from './resolver/fetch-account.component';
import FetchMyAccount from './resolver/fetch-my-account.component';
import CreateAccount from './views/create-account/create-account.component';
import EditAccount from './views/edit-account/edit-account.component';
import ListAccounts from './views/list-accounts/list-accounts.component';
import ViewAccount from './views/view-account/view-account.component';
import AccountOverview from './views/account-overview/account-overview.component';

export const ACCOUNT_OVERVIEW: RouteDefinition = {
    path: '/account',
    title: () => i18n`Übersicht`,
    header: () => (
        <>
            <span>{i18n`Account-Übersicht`}</span>
            <span className="app__title--highlighted">
                <FetchMyAccount>{account => account.name}</FetchMyAccount>
            </span>
        </>
    ),
    icon: mdiTable,
    exact: true,
    content: AccountOverview,
    role: Role.AccountManager
};

export const ALL_ACCOUNTS: RouteDefinition = {
    path: '/admin/accounts',
    title: () => i18n`Alle Accounts`,
    icon: mdiAccountSupervisorCircle,
    exact: true,
    content: ListAccounts,
    role: Role.Admin
};

export const NEW_ACCOUNT: RouteDefinition = {
    path: '/accounts/new',
    title: () => i18n`Neuen Account anlegen`,
    content: CreateAccount,
    sidenav: false,
    exact: true,
    role: Role.Admin
};

export const EDIT_ACCOUNT: RouteDefinition = {
    path: '/accounts/:accountId/edit',
    title: () => <FetchAccount>{account => i18n`${account.name} bearbeiten`}</FetchAccount>,
    content: EditAccount,
    sidenav: false,
    exact: true,
    role: Role.Admin
};

export const VIEW_ACCOUNT: RouteDefinition = {
    path: '/accounts/:accountId',
    title: () => <FetchAccount>{account => account.name}</FetchAccount>,
    content: ViewAccount,
    sidenav: false,
    exact: true,
    backArrow: true,
    role: Role.Admin
};
