import { mdiClipboardCheck } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import { default as React, useState } from 'react';
import Dialog from '../../../../../common/ui-components/dialog/dialog.component';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import { CloseSurveyDialog } from '../close-survey-dialog/close-survey-dialog.component';

export const CloseSurveyButton = ({ onClick, disabled }) => {
    const [showDialog, setShowDialog] = useState(false);
    const onCancel = () => setShowDialog(false);
    const onConfirm = () => {
        setShowDialog(false);
        onClick();
    };
    return (
        <>
            <IconButton
                icon={mdiClipboardCheck}
                disabled={disabled}
                title={disabled ? i18n`Umfrage wird abgeschlossen` : i18n`Umfrage abschließen`}
                onClick={() => setShowDialog(true)}
            />
            <Dialog isOpen={showDialog}>
                <CloseSurveyDialog onCancel={onCancel} onConfirm={onConfirm} />
            </Dialog>
        </>
    );
};
