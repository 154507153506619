import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { debounce } from '../../../../common/debounce';
import { setLocaleForGroup } from '../../../../i18n/configurations';
import GroupDynamicFactors from '../../components/group-dynamic-factors/group-dynamic-factors.component';
import ReportSummary from '../../components/summary/summary.component';
import TextAnalysis from '../../components/text-analysis/test-analysis.component';
import Trends from '../../components/trends/trends.component';
import { ReportModel, TrendGraphModel } from '../../models/report-model';
import * as reportService from '../../report-service';
import FetchReport from '../../resolver/fetch-report.component';
import ExportReportBar from './export-report-bar.component';
import { ReportFooter } from './report-footer';
import { ReportHeader } from './report-header';
import { ReportTitlePage } from './report-title-page';
import {
    buildGroupDynamicFactorsUpdate,
    buildQuestionChartComparisonUpdate,
    buildQuestionChartUpdate,
    buildSummaryUpdate,
    buildTextAnalysisFactorToggleUpdate,
    buildTextAnalysisUpdate,
    builtTextAnalysisAllFactorsToggleUpdate
} from './update-builder';
import './view-report.component.scss';

const saveReport = debounce(reportService.updateReport);

export interface ViewReportProps {
    report: ReportModel;
    teamId: string;
}

export interface ViewReportState {
    report: ReportModel;
}

class ViewReport extends React.Component<ViewReportProps, ViewReportState> {
    constructor(props) {
        super(props);
        this.state = {
            report: props.report
        };
        setLocaleForGroup('report', this.state.report.reportCulture);
    }

    render() {
        const { report } = this.state;
        const culture = report.reportCulture;

        return (
            <>
                <div className="report">
                    <ReportHeader
                        languages={report.availableLanguages}
                        lang={culture}
                        onChangeLanguage={this.updateLanguage}
                        isBusiness={report.isBusiness}
                    />
                    <div className="report__content">
                        <ReportTitlePage report={report} teamId={this.props.teamId} />
                        <GroupDynamicFactors
                            className="report__group-dynamic-factors"
                            factors={report.groupDynamicFactors}
                            onChange={this.onUpdateGroupDynamicFactors}
                            lang={culture}
                            isBusiness={report.isBusiness}
                            hasSixPossibleAnswers={report.hasSixPossibleAnswers}
                        />
                        {report.isBusiness && (
                            <p className="report__introduction">{i18n(
                                'report'
                            )`BUSINESS_TEXT_ANALYSIS_INTRODUCTION`}</p>
                        )}
                        {!report.isBusiness && (
                            <p className="report__introduction">{i18n(
                                'report'
                            )`SPORT_TEXT_ANALYSIS_INTRODUCTION`}</p>
                        )}
                        <TextAnalysis
                            factors={report.textAnalysis.evaluationMap[culture]}
                            onChangeSummary={this.onUpdateTextAnalysis}
                            onSelectChart={this.onSelectQuestionChart}
                            onUnselectChart={this.onUnselectQuestionChart}
                            onSelectChartComparison={this.onSelectChartComparison}
                            onToggleFactor={this.onToggleFactor}
                            onToggleAllFactors={this.onToggleAllFactors}
                        />
                        <Trends
                            culture={culture}
                            trends={report.trends}
                            hasSixPossibleAnswers={report.hasSixPossibleAnswers}
                            onUpdate={this.onUpdateTrends}
                            onUpdateRange={this.onUpdateTrendRange}
                        />
                        <ReportSummary
                            className="report__summary"
                            summary={report.summary[culture]}
                            onChange={this.onUpdateReportSummary}
                        />
                        <p>{report.advisor}</p>
                    </div>
                    <ReportFooter />
                </div>
                <ExportReportBar />
            </>
        );
    }

    private updateLanguage = (culture: string) => {
        setLocaleForGroup('report', culture);
        const update: ReportModel = {
            ...this.state.report,
            reportCulture: culture
        };
        this.updateReport(update);
    };

    private onUpdateGroupDynamicFactors = (showIndividualDiagrams: boolean) => {
        const update: ReportModel = buildGroupDynamicFactorsUpdate(
            this.state.report,
            showIndividualDiagrams
        );
        this.updateReport(update);
    };

    private onUpdateTextAnalysis = (factor: number, comment: string) => {
        const update: ReportModel = buildTextAnalysisUpdate(this.state.report, factor, comment);
        this.updateReport(update);
    };

    private onSelectQuestionChart = (factor: number, chart: number) => {
        const update: ReportModel = buildQuestionChartUpdate(
            this.state.report,
            factor,
            chart,
            true
        );
        this.updateReport(update);
    };

    private onUnselectQuestionChart = (factor: number, chart: number) => {
        const update: ReportModel = buildQuestionChartUpdate(
            this.state.report,
            factor,
            chart,
            false
        );
        this.updateReport(update);
    };

    private onSelectChartComparison = (factor: number, chart: number, comparison: string) => {
        const update: ReportModel = buildQuestionChartComparisonUpdate(
            this.state.report,
            factor,
            chart,
            comparison
        );
        this.updateReport(update);
    };

    private onToggleFactor = (factor: number) => {
        const update: ReportModel = buildTextAnalysisFactorToggleUpdate(this.state.report, factor);
        this.updateReport(update);
    };

    private onToggleAllFactors = (hide: boolean) => {
        const update: ReportModel = builtTextAnalysisAllFactorsToggleUpdate(
            this.state.report,
            hide
        );
        this.updateReport(update);
    };

    private onUpdateTrends = (graphs: TrendGraphModel[]) => {
        const update: ReportModel = {
            ...this.state.report,
            trends: {
                ...this.state.report.trends,
                graphs
            }
        };
        this.updateReport(update);
    };

    private onUpdateTrendRange = (dates: string[]) => {
        const update: ReportModel = {
            ...this.state.report,
            trends: {
                ...this.state.report.trends,
                selectedDates: dates
            }
        };
        this.updateReport(update);
    };

    private onUpdateReportSummary = (summary: string) => {
        const { report } = this.state;
        const update: ReportModel = buildSummaryUpdate(report, summary);
        this.updateReport(update);
    };

    private updateReport(update: ReportModel) {
        this.setState({ ...this.state, report: update });
        saveReport(update);
    }
}

export default () => (
    <FetchReport>{(report, teamId) => <ViewReport report={report} teamId={teamId} />}</FetchReport>
);
