import * as React from 'react';
import { Table } from '../../../../../common/ui-components/table';
import { SortableList, TableSorting } from '../../../../../common/ui-components/table/sort-helpers';
import TableBody from '../../../../../common/ui-components/table/table-body.component';
import { AccountModel } from '../../../models/account-model';
import AccountListHeader from './account-list-header.component';
import AccountListRow from './account-list-row.component';

export interface AccountListProps {
    accounts: AccountModel[];
    onDelete: (account: AccountModel) => void;
}

const accountSortingAccessor = (sorting: TableSorting) => (account: AccountModel) => {
    if (sorting.row === 'accountNumber') {
        return account.accountNumber.toString();
    }
    return account[sorting.row];
};

const AccountList = (props: AccountListProps) => (
    <SortableList
        rows={props.accounts}
        initialSort="accountNumber"
        sortingAccessor={accountSortingAccessor}
    >
        {({ rows, sorting, setSorting }) => (
            <Table className="list-accounts__list">
                <AccountListHeader
                    sorting={sorting}
                    onSort={(row, direction) => setSorting({ row, direction })}
                />
                <TableBody>
                    {rows.map(a => (
                        <AccountListRow key={a.id} account={a} onDelete={() => props.onDelete(a)} />
                    ))}
                </TableBody>
            </Table>
        )}
    </SortableList>
);

export default AccountList;
