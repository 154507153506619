import { useEffect } from 'react';
import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Sidenav from './common/shell/sidenav/sidenav.component';
import { NotificationContainer } from './common/ui-components/notification';
import { AuthState, withAuth } from './domains/auth/context/auth-context';
import Authentication from './domains/auth/context/auth.component';
import Login from './domains/auth/views/login/login.component';
import AuthRedirect from './domains/auth/views/redirect/redirect.component';
import { updateUserLanguage } from './domains/users/user-service';
import { shouldLoadLocaleFromUser } from './i18n/configurations';
import { LocaleContext, LocaleContextState, LocaleProvider } from './i18n/locale';
import AppRoutes from './routing/routes.component';
import './app.component.scss';
import { ErrorBoundary } from './common/error-boundary';

const loginRoute = '/auth/login';

const Application = () => (
    <div className="app">
        <Sidenav className="app__sidenav" />
        <ErrorBoundary>
            <AppRoutes />
        </ErrorBoundary>
    </div>
);

const ApplicationRoute = ({
    loggedIn,
    user,
    switchLanguage,
    lang
}: AuthState & LocaleContextState) => {
    useEffect(() => {
        if (user?.language != null && shouldLoadLocaleFromUser()) {
            switchLanguage(user.language);
        }
    }, [user?.language]);
    useEffect(() => {
        if (!loggedIn) {
            return;
        }
        updateUserLanguage(lang);
    }, [lang]);
    return loggedIn ? <Route component={Application} /> : <Redirect to={loginRoute} />;
};

const AppRoute = withAuth()(ApplicationRoute);

const Routes = props => (
    <Switch>
        <Route exact path={loginRoute} component={Login} />
        <Route exact path="/auth/redirect" component={AuthRedirect} />
        <AppRoute {...props} />
    </Switch>
);

const App = (
    <NotificationContainer>
        <LocaleProvider>
            <Router>
                <Authentication>
                    <LocaleContext.Consumer>
                        {props => <Routes {...props} />}
                    </LocaleContext.Consumer>
                </Authentication>
            </Router>
        </LocaleProvider>
    </NotificationContainer>
);

export default App;
