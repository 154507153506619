import i18n from 'es2015-i18n-tag';
import { useEffect, useState } from 'react';
import * as React from 'react';
import Input from '../../../../common/ui-components/input/input.component';
import PrimaryLink from '../../../../common/ui-components/primary-button/primary-link.component';
import Tab from '../../../../common/ui-components/tabs/tab.component';
import Tabs from '../../../../common/ui-components/tabs/tabs.component';
import * as teamService from '../../../teams/team-service';
import { TeamModel } from '../../models/team-model';
import { BusinessTeamList } from './business/business-team-list.component';
import { SportTeamList } from './sport/sport-team-list.component';
import './list-teams.component.scss';
import { AccountModel } from '../../../accounts/models/account-model';

export interface ListTeamsProps {
    teams: TeamModel[];
    account?: AccountModel;
    showAccountColumn?: boolean;
}

const ListTeams = ({ teams: allTeams, showAccountColumn, account }: ListTeamsProps) => {
    const [state, setState] = useState({
        filter: '',
        teams: [],
        openTab: 0
    });

    const setTeams = (teams: TeamModel[]) => setState({ ...state, teams });
    const setFilter = (filter: string) => setState({ ...state, filter });

    useEffect(() => setTeams(allTeams), [allTeams]);

    const onDelete = async (team: TeamModel) => {
        await teamService.deleteTeam(team);
        const teams = state.teams.filter(t => t.id !== team.id);
        setTeams(teams);
    };

    const sportTeamListActive = state.openTab === 0;
    const businessTeamListActive = state.openTab === 1;

    return (
        <div className="list-teams">
            <div className="list-teams__create-team">
                {sportTeamListActive && (
                    <PrimaryLink
                        to="teams/sport/new"
                        className="list-teams__create-team-btn"
                        title={i18n`Neues Sport Team anlegen`}
                        disabled={account?.canCreateTeam === false}
                    />
                )}
                {businessTeamListActive && (
                    <PrimaryLink
                        to="teams/business/new"
                        className="list-teams__create-team-btn"
                        title={i18n`Neues Business Team anlegen`}
                        disabled={account?.canCreateTeam === false}
                    />
                )}
            </div>
            <div className="list-teams__filter">
                <Input
                    placeholder={i18n`Filtern...`}
                    value={state.filter}
                    onChange={e => setFilter(e.target.value)}
                />
            </div>
            <Tabs
                className="list-teams__tabs"
                onTabChange={tab => setState({ ...state, openTab: tab })}
            >
                <Tab header={i18n`Sportteams`}>
                    <SportTeamList
                        teams={state.teams}
                        onDelete={onDelete}
                        filter={state.filter}
                        showAccountColumn={showAccountColumn}
                    />
                </Tab>
                <Tab header={i18n`Businessteams`}>
                    <BusinessTeamList
                        teams={state.teams}
                        onDelete={onDelete}
                        filter={state.filter}
                        showAccountColumn={showAccountColumn}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default ListTeams;
