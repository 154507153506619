import * as React from 'react';
import { Table } from '../../../../common/ui-components/table';
import { SortableList, TableSorting } from '../../../../common/ui-components/table/sort-helpers';
import TableBody from '../../../../common/ui-components/table/table-body.component';
import { TableSortColumn } from '../../../../common/ui-components/table/table-sort-header.component';
import { getAdvisorDisplayName, TeamModel } from '../../models/team-model';
import TeamListHeader from './team-list-header.component';

export interface TeamListProps {
    teams: TeamModel[];
    filter?: string;
    showAccountColumn?: boolean;

    onDelete(team: TeamModel): void;
}

interface BaseListProps extends TeamListProps {
    header: TableSortColumn[];
    children: (t: TeamModel, onDelete: () => void) => any;
}

const teamSortingAccessor = (sorting: TableSorting) => (team: TeamModel) => {
    if (sorting.row === 'advisor') {
        return getAdvisorDisplayName(team);
    }
    if (sorting.row === 'account') {
        return team.accountName;
    }
    if (sorting.row === 'company') {
        return team.sport;
    }
    if (sorting.row === 'location') {
        return team.league;
    }

    return team[sorting.row];
};

const TeamList = (props: BaseListProps) => (
    <SortableList rows={props.teams} initialSort="name" sortingAccessor={teamSortingAccessor}>
        {({ rows, sorting, setSorting }) => {
            const filter = props.filter.toLocaleLowerCase();
            const teams = rows.filter(teamMatches(filter));

            return (
                <Table className="list-teams__list">
                    <TeamListHeader
                        sorting={sorting}
                        onSort={(row, direction) => setSorting({ row, direction })}
                        columns={props.header}
                    />
                    <TableBody>
                        {teams.map(t => props.children(t, () => props.onDelete(t)))}
                    </TableBody>
                </Table>
            );
        }}
    </SortableList>
);

function teamMatches(filter: string) {
    return (team: TeamModel): boolean => {
        const matchesAdvisor =
            team.advisor &&
            (match(team.advisor.firstName, filter) || match(team.advisor.lastName, filter));
        return (
            match(team.name, filter) ||
            match(team.league, filter) ||
            match(team.sport, filter) ||
            matchesAdvisor
        );
    };
}

const match = (field: string | null, filter: string) =>
    (field || '').toLocaleLowerCase().includes(filter);

export default TeamList;
