import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Checkbox from '../../../../common/ui-components/checkbox/checkbox.component';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import TextArea from '../../../../common/ui-components/textarea/textarea.component';
import { TextAnalysisModel } from '../../models/report-model';
import { QuestionAnalysisChart } from './question-analysis/question-analysis-chart.component';
import { QuestionContainer } from './question-analysis/question-container.component';
import { QuestionSelection } from './question-analysis/question-selection.component';
import { useState } from 'react';

export interface TextAnalysisFactorProps {
    factor: TextAnalysisModel;
    onChangeSummary: (summary: string) => void;
    onSelectChart: (index: number) => void;
    onUnselectChart: (index: number) => void;
    onSelectChartComparison: (index: number, comparison: string) => void;
    onToggleFactor: () => void;
}

const TextAnalysisFactor = ({
    factor,
    onChangeSummary,
    onSelectChart,
    onUnselectChart,
    onSelectChartComparison,
    onToggleFactor
}: TextAnalysisFactorProps) => {
    const [showSelection, setShowSelection] = useState(false);

    return (
        <div
            className={`text-analysis__factor ${factor.isHidden &&
                'text-analysis__factor--hidden'}`}
        >
            <div className="text-analysis__factor-overview">
                <h3 className="text-analysis__factor-title">{factor.title}</h3>
                <Checkbox
                    className="text-analysis__hide-factor"
                    label={i18n('report')`Ausblenden`}
                    checked={factor.isHidden}
                    onChange={() => onToggleFactor()}
                />
                {!factor.isHidden && (
                    <>
                        <p className="text-analysis__value-container">
                            <span className="text-analysis__value">
                                {i18n('report')`Mittelwert`}: {getFormattedNumber(factor.average)}
                            </span>
                            <span className="text-analysis__value">
                                {i18n('report')`Varianz`}: {getFormattedNumber(factor.variance)}
                            </span>
                        </p>
                        {factor.average != null && (
                            <p className="text-analysis__summary">{factor.summary}</p>
                        )}
                        {factor.average == null && (
                            <p className="text-analysis__summary">{i18n(
                                'report'
                            )`Faktor wurde nicht ausgewertet.`}</p>
                        )}
                    </>
                )}
            </div>
            {!factor.isHidden && (
                <>
                    {factor.questions != null &&
                        factor.questions.map((question, i) => {
                            if (!question.selected) {
                                return <React.Fragment key={i} />;
                            }
                            return (
                                <QuestionContainer
                                    key={i}
                                    onRemove={() => onUnselectChart(i)}
                                    onSelectComparison={comparison =>
                                        onSelectChartComparison(i, comparison)
                                    }
                                    selectedComparison={question?.selectedComparison}
                                    compareItems={Object.keys(question.comparisons || {})}
                                >
                                    <QuestionAnalysisChart
                                        question={question}
                                        compareDate={question.selectedComparison}
                                        compareQuestion={
                                            question.comparisons != null
                                                ? question.comparisons[question.selectedComparison]
                                                : null
                                        }
                                    />
                                </QuestionContainer>
                            );
                        })}
                    {showSelection && factor.questions != null && (
                        <QuestionSelection
                            questions={factor.questions}
                            onSelect={chart => {
                                onSelectChart(chart);
                                setShowSelection(false);
                            }}
                            onCancel={() => setShowSelection(false)}
                        />
                    )}
                    {factor.questions != null && (
                        <PrimaryButton
                            className="question-analysis__add-chart-btn"
                            title={i18n('report')`Verteilung hinzufügen`}
                            onClick={() => setShowSelection(true)}
                        />
                    )}
                    <h4
                        className={`text-analysis__comment-title ${
                            factor.comment ? '' : 'text-analysis__comment-title--empty'
                        }`}
                    >{i18n('report')`Bewertung / Kommentar`}</h4>
                    <TextArea
                        value={factor.comment || ''}
                        onChange={e => onChangeSummary(e.target.value)}
                    />
                </>
            )}
        </div>
    );
};

function getFormattedNumber(value: number): string {
    if (value != null) {
        return value.toFixed(2);
    }
    return '-';
}

export default TextAnalysisFactor;
