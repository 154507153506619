import i18n from 'es2015-i18n-tag';
import { default as React, useState } from 'react';
import { NOT_FOUND } from '../../../../common/http-status-codes';
import { emailRegex } from '../../../../common/validators';
import { login } from '../../auth-service';
import { LoginStatus, LoginStatusMessage } from './login-status.component';

interface LoginFormState {
    email: string;
    pending: boolean;
    status?: LoginStatus;
}

const emailIsValid = (email: string): boolean => {
    return emailRegex.test(email);
};

export const LoginForm = () => {
    const [state, setState] = useState<LoginFormState>({
        email: '',
        pending: false
    });

    const validEmail = emailIsValid(state.email);

    async function send() {
        const next = { ...state, pending: false };
        try {
            await login(state.email);
            next.status = LoginStatus.Success;
        } catch (err) {
            if (err.status === NOT_FOUND) {
                next.status = LoginStatus.UnknownEmail;
            }
        }
        setState(next);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setState({
            ...state,
            status: null,
            pending: true
        });
        await send();
    };

    function setEmail(email: string) {
        const isValid = emailIsValid(email);
        const status = isValid ? null : LoginStatus.InvalidEmail;
        setState({ ...state, email, status });
    }

    return (
        <form className="login__body" onSubmit={handleSubmit}>
            <input
                type="email"
                className="login__email"
                placeholder={i18n`E-Mail-Adresse eingeben...`}
                onChange={event => setEmail(event.target.value)}
                value={state.email}
                disabled={state.pending}
                autoFocus
            />
            <button
                type="submit"
                className="login__submit-btn"
                disabled={state.pending || !validEmail}
            >
                {i18n`Zugang anfordern`}
            </button>
            <LoginStatusMessage status={state.status} />
        </form>
    );
};

export default LoginForm;
