import { FormikBag, withFormik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router';
import { SelectItem } from '../../../../common/ui-components/multi-select/multi-select.component';
import { UserModel } from '../../../users/models/user-model';
import { createAccount } from '../../account-service';
import { compose } from 'ramda';
import EditAccount, {
    EditAccountFormValue,
    formSchema
} from '../../components/edit-account.component';
import { AssignedUser } from '../../models/account-create-model';

const mapSelectItemsToUsers = (
    items: SelectItem<UserModel>[],
    isAccountManager: boolean
): AssignedUser[] => items.map(u => u.value).map(u => ({ ...u, isAccountManager }));

const onCreate = (
    formValue: EditAccountFormValue,
    actions: FormikBag<any, EditAccountFormValue>
) => {
    const { teams, users, accountManagers, ...fields } = formValue;
    createAccount({
        teams: teams.map(t => t.value),
        users: [
            ...mapSelectItemsToUsers(accountManagers, true),
            ...mapSelectItemsToUsers(users, false)
        ],
        ...fields
    })
        .then(() => {
            actions.props.history.goBack();
        })
        .finally(() => {
            actions.setSubmitting(false);
        });
};

const enhance = compose(
    withRouter,
    withFormik({
        displayName: 'CreateAccount',
        validationSchema: formSchema,
        handleSubmit: onCreate,
        mapPropsToValues: (): EditAccountFormValue => ({
            name: '',
            users: [],
            accountManagers: [],
            teams: [],
            surveyQuota: '' as any,
            teamQuota: '' as any,
            validFrom: '' as any,
            validUntil: '' as any
        })
    })
);

export default enhance(EditAccount);
