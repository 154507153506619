import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import TextArea from '../../../../common/ui-components/textarea/textarea.component';
import './summary.component.scss';

export interface ReportSummaryProps {
    summary: string;
    onChange: (summary: string) => void;
    className?: string;
}

const ReportSummary = ({ summary, onChange, className }: ReportSummaryProps) => (
    <div className={`report-summary ${summary ? '' : 'report-summary--empty'} ${className || ''}`}>
        <h3 className="report-summary__title">{i18n('report')`Fazit`}</h3>
        <TextArea onChange={event => onChange(event.target.value)} value={summary || ''} />
    </div>
);

export default ReportSummary;
