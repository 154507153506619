import * as React from 'react';

const TrendTimeSelectionTracks = ({ tracks }) => <TimeSelectionTrack track={tracks[1]} />;

const TimeSelectionTrack = (props: { track: any }) => (
    <div
        className="trend-time-selection__track"
        style={{
            left: `${props.track.source.percent}%`,
            width: `${props.track.target.percent - props.track.source.percent}%`
        }}
    />
);

export default TrendTimeSelectionTracks;
