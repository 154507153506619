import * as React from 'react';
import { Rail } from 'react-compound-slider';

const TimeSelectionRail = props => <div className="trend-time-selection__rail" {...props} />;

export const TrendTimeSelectionRail = () => (
    <Rail>{({ getRailProps }) => <TimeSelectionRail {...getRailProps()} />}</Rail>
);

export default TimeSelectionRail;
