import { mdiCheck, mdiClose } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { emailRegex } from '../../../../../common/validators';
import { TeamMember } from '../../../models/team-model';
import TableColumn from '../../../../../common/ui-components/table/table-column.component';
import IconButton from '../../../../../common/ui-components/icon-button/icon-button.component';
import { useState } from 'react';

export interface EditMemberProps {
    member: TeamMember;
    onSave: (member: TeamMember) => void;
    onCancel: () => void;
}

const EditMember = ({ onSave, onCancel, ...props }: EditMemberProps) => {
    const [member, setMember] = useState(props.member);
    const onChangeFirstName = value => setMember({ ...member, firstName: value });
    const onChangeLastName = value => setMember({ ...member, lastName: value });
    const onChangeEmail = value => setMember({ ...member, email: value });
    const onChangeRole = value => setMember({ ...member, role: value });

    const valid = emailRegex.test(member.email);

    return (
        <form
            style={{ display: 'contents' }}
            onSubmit={e => {
                e.preventDefault();
                onSave(member);
            }}
        >
            <TableColumn width={1}>
                <input
                    autoFocus
                    className="edit-member__input"
                    value={member.firstName}
                    type="text"
                    onChange={e => onChangeFirstName(e.target.value)}
                />
            </TableColumn>
            <TableColumn width={1}>
                <input
                    className="edit-member__input"
                    value={member.lastName}
                    type="text"
                    onChange={e => onChangeLastName(e.target.value)}
                />
            </TableColumn>
            <TableColumn width={1}>
                <input
                    className="edit-member__input"
                    value={member.role}
                    type="text"
                    onChange={e => onChangeRole(e.target.value)}
                />
            </TableColumn>
            <TableColumn width={1}>
                <input
                    className="edit-member__input"
                    value={member.email}
                    type="email"
                    onChange={e => onChangeEmail(e.target.value)}
                />
            </TableColumn>
            <TableColumn width="50px">
                <SaveMemberButton disabled={!valid} />
                <CancelMemberButton onCancel={onCancel} />
            </TableColumn>
        </form>
    );
};

const SaveMemberButton = props => (
    <IconButton title={i18n`Speichern`} icon={mdiCheck} type="submit" {...props} />
);
const CancelMemberButton = ({ onCancel }) => (
    <IconButton title={i18n`Abbrechen`} icon={mdiClose} onClick={onCancel} type="button" />
);

export default EditMember;
