import { mdiAccountMultiple } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import { RouteDefinition } from '../../routing/routes';
import { Role } from '../auth/context/auth-context';
import ListAccountUsers from './list-users/list-account-users.component';
import ListAdminUsers from './list-users/list-admin-users.component';
import FetchMyAccount from '../accounts/resolver/fetch-my-account.component';
import * as React from 'react';

export const MANAGE_ALL_USERS: RouteDefinition = {
    path: '/users',
    icon: mdiAccountMultiple,
    title: () => i18n`Alle User`,
    content: ListAdminUsers,
    role: Role.Admin
};

export const MANAGE_ACCOUNT_USERS: RouteDefinition = {
    path: '/account/users',
    icon: mdiAccountMultiple,
    title: () => i18n`User`,
    header: () => (
        <>
            <span>{i18n`Account-User`}</span>
            <span className="app__title--highlighted">
                <FetchMyAccount>{account => account.name}</FetchMyAccount>
            </span>
        </>
    ),
    content: ListAccountUsers,
    role: Role.AccountManager
};
