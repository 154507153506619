import React, { Component, ErrorInfo, ReactNode } from 'react';
import i18n from 'es2015-i18n-tag';

export interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {};

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({
            error
        });
    }

    render() {
        if (this.state.error != null) {
            return (
                <div className="app__content">
                    <h2 className="page-title">{i18n`Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.`}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}
