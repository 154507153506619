import { mdiBasketball, mdiBriefcase } from '@mdi/js';
import Icon from '@mdi/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import TeamEditButton from '../../components/team-edit-button.component';
import { TeamModel } from '../../models/team-model';

export interface TeamTileProps {
    team: TeamModel;
}

const TeamTile = ({ team }: TeamTileProps) => {
    const urlPrefix = team.isBusiness ? '/teams/business' : '/teams/sport';

    return (
        <Link className="my-teams__tile" to={`${urlPrefix}/${team.id}`}>
            <div
                className={`my-teams__logo-container ${
                    team.hasLogo ? '' : 'my-teams__logo-container--hide'
                }`}
            >
                <img
                    className="my-teams__logo"
                    src={`/api/teams/${team.id}/icon?time=${Date.now()}`}
                />
            </div>
            <h3 className="my-teams__title">{team.name}</h3>
            <p className="my-teams__subtitle">
                {team.sport} - {team.league}
            </p>
            <Icon
                className="my-teams__type-icon"
                path={team.isBusiness ? mdiBriefcase : mdiBasketball}
                size={1}
            />
            <TeamEditButton
                className="my-teams__edit-team-btn"
                id={team.id}
                isBusiness={team.isBusiness}
            />
        </Link>
    );
};

export default TeamTile;
