import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Chart from 'react-google-charts';
import { getMaxValueForTrends } from '../../chart-helpers';
import { FactorTrendModel, TrendGraphModel } from '../../models/report-model';

export interface TrendGraphProps {
    graph: TrendGraphModel;
    factors: FactorTrendModel[];
    selectedDates: string[];
    culture: string;
    className?: string;
    hasSixPossibleAnswers: boolean;
}

const TrendGraph = ({
    graph,
    culture,
    factors,
    className,
    selectedDates,
    hasSixPossibleAnswers
}: TrendGraphProps) => {
    const dataPoints = graph.dates
        .map((date, index) => ({ date, index }))
        .filter(({ date }) => selectedDates.includes(date))
        .map(({ date, index }) => {
            return [formatDate(date), ...graph.selectedFactors.map(getDataPoint(factors, index))];
        });
    const header = [
        i18n('report')`Monat`,
        ...graph.selectedFactors.map(getFactorTitle(factors, culture))
    ];
    const data = [header, ...dataPoints];
    return (
        <Chart
            style={{ flex: 1 }}
            className={className}
            chartType="LineChart"
            data={data}
            height={300}
            options={{
                chartArea: { height: '80%', width: '65%', left: 30 },
                vAxis: { minValue: 0, maxValue: getMaxValueForTrends(hasSixPossibleAnswers) },
                fontName: 'Dosis'
            }}
        />
    );
};

function formatDate(date: string): string {
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
    const year = new Date(date)
        .getFullYear()
        .toString()
        .substr(2);

    return `${month}.${year}`;
}

function getDataPoint(factors: FactorTrendModel[], i: number): (factorIndex: number) => number {
    return factor => factors[factor].averages[i] ?? 0;
}

function getFactorTitle(
    factors: FactorTrendModel[],
    culture: string
): (factorIndex: number) => string {
    return factor => factors[factor].title[culture];
}

export default TrendGraph;
