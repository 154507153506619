import i18n from 'es2015-i18n-tag';
import React, { useEffect, useState } from 'react';
import {
    Table,
    TableColumn,
    TableHeader,
    TableRow
} from '../../../../../common/ui-components/table';
import TableBody from '../../../../../common/ui-components/table/table-body.component';
import { getAdvisorDisplayName, TeamModel } from '../../../../teams/models/team-model';
import { getAccountTeams } from '../../../account-service';

const AccountTeamList = ({ accountId }) => {
    const [teams, setTeams] = useState<TeamModel[]>([]);

    useEffect(() => {
        getAccountTeams(accountId).then(setTeams);
    }, [accountId]);

    return (
        <Table>
            <TableHeader>
                <TableColumn width={1}>{i18n`Name`}</TableColumn>
                <TableColumn width={1}>{i18n`Betreuer`}</TableColumn>
                <TableColumn width={1}>{i18n`Art`}</TableColumn>
            </TableHeader>
            <TableBody>
                {teams.map(team => (
                    <TableRow
                        key={team.id}
                        link={`/teams/${team.isBusiness ? 'business' : 'sport'}/${team.id}`}
                    >
                        <TableColumn width={1}>{team.name}</TableColumn>
                        <TableColumn width={1}>{getAdvisorDisplayName(team)}</TableColumn>
                        <TableColumn width={1}>
                            {team.isBusiness ? i18n`Business` : i18n`Sport`}
                        </TableColumn>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AccountTeamList;
