import { mdiCheck, mdiClose } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import IconButton from '../../../common/ui-components/icon-button/icon-button.component';
import TableColumn from '../../../common/ui-components/table/table-column.component';
import { emailRegex } from '../../../common/validators';
import { UserModel } from '../models/user-model';

export interface EditUserProps {
    disabled: boolean;
    user: UserModel;
    onEdit: (user: UserModel) => void;
    onSave: () => void;
    onCancel: () => void;
    validateEmail: (user: UserModel) => boolean;
    children: (
        user: UserModel,
        onUpdate: (user: Partial<UserModel>) => void,
        disabled: boolean
    ) => React.ReactNode;
    showAccountColumn?: boolean;
}

const EditUser = ({
    user,
    onEdit,
    onSave,
    onCancel,
    disabled,
    validateEmail,
    children,
    showAccountColumn
}: EditUserProps) => {
    const onChangeFirstName = value => onEdit({ ...user, firstName: value });
    const onChangeLastName = value => onEdit({ ...user, lastName: value });
    const onChangeEmail = value => onEdit({ ...user, email: value });
    const onChangePhone = value => onEdit({ ...user, phone: value });

    const emailInvalid = !validateEmail(user);
    const invalid =
        user.email === '' ||
        emailInvalid ||
        !emailRegex.test(user.email) ||
        user.firstName === '' ||
        user.lastName === '';

    return (
        <form
            style={{ display: 'contents' }}
            onSubmit={e => {
                e.preventDefault();
                onSave();
            }}
        >
            <TableColumn width={1}>
                <input
                    autoFocus
                    className="edit-user__input"
                    value={user.firstName}
                    disabled={disabled}
                    onChange={e => onChangeFirstName(e.target.value)}
                />
            </TableColumn>
            <TableColumn width={1}>
                <input
                    className="edit-user__input"
                    value={user.lastName}
                    disabled={disabled}
                    onChange={e => onChangeLastName(e.target.value)}
                />
            </TableColumn>
            <TableColumn width={1} style={{ position: 'relative' }}>
                <input
                    className="edit-user__input"
                    value={user.email}
                    type="email"
                    disabled={disabled}
                    onChange={e => onChangeEmail(e.target.value)}
                />
                <span
                    className={`edit-user__input-error-hint ${
                        emailInvalid ? 'edit-user__input-error-hint--active' : ''
                    }`}
                >{i18n`Bereits vorhanden`}</span>
            </TableColumn>
            <TableColumn width={1}>
                <input
                    className="edit-user__input"
                    value={user.phone}
                    type="tel"
                    disabled={disabled}
                    onChange={e => onChangePhone(e.target.value)}
                />
            </TableColumn>
            {showAccountColumn && (
                <TableColumn width={1}>
                    <input
                        className="edit-user__input"
                        value={user.accountName}
                        type="tel"
                        disabled={true}
                    />
                </TableColumn>
            )}
            {children(user, update => onEdit({ ...user, ...update }), disabled)}
            <TableColumn width="50px">
                {!disabled && (
                    <SaveUserButton
                        disabled={invalid}
                        title={
                            user.id == null
                                ? i18n`User anlegen und per E-Mail einladen`
                                : i18n`User speichern`
                        }
                    />
                )}
                {!disabled && <CancelUserButton onCancel={onCancel} />}
            </TableColumn>
        </form>
    );
};

const SaveUserButton = props => (
    <IconButton title={i18n`Speichern`} icon={mdiCheck} type="submit" {...props} />
);

const CancelUserButton = ({ onCancel }) => (
    <IconButton title={i18n`Abbrechen`} onClick={onCancel} type="button" icon={mdiClose} />
);

export default EditUser;
