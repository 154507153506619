import { mdiPencil } from '@mdi/js';
import i18n from 'es2015-i18n-tag';
import { History } from 'history';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import IconButton from '../../../common/ui-components/icon-button/icon-button.component';
import './account-edit-button.component.scss';

export interface AccountEditButtonProps {
    className?: string;
    id: string;
    history: History;
}

export const AccountEditButton = ({ className, id, history }: AccountEditButtonProps) => {
    return (
        <IconButton
            title={i18n`Account bearbeiten`}
            role="link"
            icon={mdiPencil}
            className={className}
            onClick={e => {
                e.preventDefault();
                history.push(`/accounts/${id}/edit`);
            }}
        />
    );
};

// @ts-ignore
export default withRouter(AccountEditButton);
