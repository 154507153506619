import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import { TextAnalysisModel } from '../../models/report-model';
import TextAnalysisFactor from './text-analysis-factor.component';
import './text-analysis.component.scss';

export interface TextAnalysisProps {
    factors: TextAnalysisModel[];
    onChangeSummary: (factor: number, comment: string) => void;
    onSelectChart: (factor: number, chart: number) => void;
    onUnselectChart: (factor: number, chart: number) => void;
    onSelectChartComparison: (factor: number, chart: number, comparison: string) => void;
    onToggleFactor: (factor: number) => void;
    onToggleAllFactors: (hide: boolean) => void;
}

const TextAnalysis = ({
    factors,
    onChangeSummary,
    onSelectChart,
    onUnselectChart,
    onSelectChartComparison,
    onToggleFactor,
    onToggleAllFactors
}: TextAnalysisProps) => (
    <>
        <div className="text-analysis__selection">
            {factors.every(f => !f.isHidden) && (
                <PrimaryButton
                    title={i18n('report')`Alle Faktoren ausblenden`}
                    onClick={() => onToggleAllFactors(true)}
                />
            )}
            {factors.some(f => f.isHidden) && (
                <PrimaryButton
                    title={i18n('report')`Alle Faktoren einblenden`}
                    onClick={() => onToggleAllFactors(false)}
                />
            )}
        </div>
        {factors.map((factor, i) => (
            <TextAnalysisFactor
                factor={factor}
                key={i}
                onChangeSummary={comment => onChangeSummary(i, comment)}
                onSelectChart={chart => onSelectChart(i, chart)}
                onUnselectChart={chart => onUnselectChart(i, chart)}
                onSelectChartComparison={(chart, comparison) =>
                    onSelectChartComparison(i, chart, comparison)
                }
                onToggleFactor={() => onToggleFactor(i)}
            />
        ))}
    </>
);

export default TextAnalysis;
