import React, { useEffect, useState } from 'react';
import * as teamService from '../../team-service';
import ListTeams from './list-teams.component';

const AdminTeamList = () => {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        teamService.getAllTeams().then(setTeams);
    }, []);

    return <ListTeams teams={teams} showAccountColumn={true} />;
};

export default AdminTeamList;
