import i18n from 'es2015-i18n-tag';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../../common/ui-components/checkbox/checkbox.component';
import { TableColumn } from '../../../common/ui-components/table';
import { UserModel } from '../models/user-model';
import * as userService from '../user-service';
import ListUsers from './list-users.component';
import { ViewAdminUser } from './view-admin-user-row.component';

const ListAdminUsers = () => {
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        userService.getUsers().then(setUsers);
    }, []);

    return (
        <ListUsers
            users={users}
            header={<TableColumn width="25px">{i18n`Admin`}</TableColumn>}
            addUser={userService.addUser}
            updateUser={userService.updateUser}
            showAccountColumn={true}
            edit={(user, onUpdate, disabled) => (
                <TableColumn width="25px">
                    <Checkbox
                        checked={user.isAdmin}
                        type="checkbox"
                        disabled={disabled || user.isOwner}
                        onChange={() => onUpdate({ isAdmin: !user.isAdmin })}
                        title={
                            user.isOwner
                                ? i18n`Diesem User können die Admin-Rechte nicht entzogen werden`
                                : ''
                        }
                    />
                </TableColumn>
            )}
        >
            {({ user, editUser, updateUser }) => (
                <ViewAdminUser user={user} onEdit={editUser} onUpdate={updateUser} />
            )}
        </ListUsers>
    );
};

export default ListAdminUsers;
