import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import { useState } from 'react';
import PrimaryButton from '../../../../common/ui-components/primary-button/primary-button.component';
import { Translatable, TrendGraphModel, TrendModel } from '../../models/report-model';
import TrendTimeSelection from './trend-time-selection/trend-time-selection.component';
import Trend from './trend.component';
import './trends.component.scss';

export interface TrendsProps {
    culture: string;
    trends: TrendModel;
    onUpdate: (graphs: TrendGraphModel[]) => void;
    onUpdateRange: (dates: string[]) => void;
    hasSixPossibleAnswers: boolean;
}

const Trends = ({
    culture,
    trends,
    onUpdate,
    onUpdateRange,
    hasSixPossibleAnswers
}: TrendsProps) => {
    const [openSettings, setOpenSettings] = useState(-1);

    const graphs = trends.graphs || [];
    const addGraph = () => {
        const index = graphs.length;
        setOpenSettings(index);
        onUpdate([
            ...graphs,
            {
                selectedFactors: [],
                dates: trends.dates,
                comment: {} as Translatable
            }
        ]);
    };

    const onUpdateGraph = (graph: TrendGraphModel, graphIndex: number) => {
        onUpdate(graphs.map((g, i) => (i === graphIndex ? graph : g)));
    };

    const onRemoveGraph = (graphIndex: number) => {
        onUpdate(graphs.filter((g, i) => i !== graphIndex));
    };

    const onToggleSettings = (graphIndex: number) => {
        if (openSettings === graphIndex) {
            setOpenSettings(-1);
        } else {
            setOpenSettings(graphIndex);
        }
    };

    return (
        <div className={`trends ${graphs.length === 0 ? 'trends--empty' : ''}`}>
            <h3 className="trends__title">{i18n('report')`Ergebnisentwicklung`}</h3>
            {trends.dates.length > 1 && (
                <TrendTimeSelection
                    dates={trends.dates}
                    onSelectRange={onUpdateRange}
                    selectedDates={trends.selectedDates}
                />
            )}
            {graphs.map((graph, index) => (
                <Trend
                    graph={graph}
                    hasSixPossibleAnswers={hasSixPossibleAnswers}
                    onUpdate={update => onUpdateGraph(update, index)}
                    onRemove={() => onRemoveGraph(index)}
                    onToggleSettings={() => onToggleSettings(index)}
                    culture={culture}
                    factors={trends.factors}
                    key={index}
                    settingsOpen={openSettings === index}
                    selectedDates={trends.selectedDates}
                />
            ))}
            <PrimaryButton
                className="trends__add-graph-btn"
                title={i18n('report')`Graph hinzufügen`}
                onClick={addGraph}
            />
        </div>
    );
};

export default Trends;
