import * as React from 'react';
import { TableSorting } from '../../../../common/ui-components/table/sort-helpers';
import TableColumn from '../../../../common/ui-components/table/table-column.component';
import TableSortHeader, {
    TableSortColumn
} from '../../../../common/ui-components/table/table-sort-header.component';
import { SortDirection } from '../../../../common/ui-components/table/table.component';

export interface TeamListHeaderProps {
    onSort: (row: string, direction: SortDirection) => void;
    sorting: TableSorting;
    columns: TableSortColumn[];
}

const TeamListHeader = (props: TeamListHeaderProps) => (
    <TableSortHeader {...props}>
        <TableColumn width="50px" />
    </TableSortHeader>
);

export default TeamListHeader;
