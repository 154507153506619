import i18n from 'es2015-i18n-tag';
import * as React from 'react';
import Button from '../../../../../common/ui-components/button/button.component';
import PrimaryButton from '../../../../../common/ui-components/primary-button/primary-button.component';
import './delete-member-dialog.component.scss';

export const DeleteMemberDialog = ({ onCancel, onConfirm }) => (
    <div className="delete-member-dialog">
        <h1 className="delete-member-dialog__title">{i18n`Teammitglied löschen?`}</h1>
        <div className="delete-member-dialog__content">
            <Button title="Nein" onClick={onCancel} />
            <PrimaryButton title="Ja" onClick={onConfirm} />
        </div>
    </div>
);
