import * as React from 'react';
import {
    ACCOUNT_OVERVIEW,
    ALL_ACCOUNTS,
    EDIT_ACCOUNT,
    NEW_ACCOUNT,
    VIEW_ACCOUNT
} from '../domains/accounts/routes';
import { Role } from '../domains/auth/context/auth-context';
import { VIEW_REPORT } from '../domains/reports/routes';
import {
    ACCOUNT_TEAMS,
    ALL_TEAMS,
    EDIT_BUSINESS_TEAM,
    EDIT_SPORT_TEAM,
    MY_TEAMS,
    ADMIN_NEW_BUSINESS_TEAM,
    ADMIN_NEW_SPORT_TEAM,
    VIEW_BUSINESS_TEAM,
    VIEW_SPORT_TEAM,
    ACCOUNT_NEW_SPORT_TEAM,
    ACCOUNT_NEW_BUSINESS_TEAM
} from '../domains/teams/routes';
import { MANAGE_ACCOUNT_USERS, MANAGE_ALL_USERS } from '../domains/users/routes';

export interface RouteDefinition {
    path: string;
    exact?: true;
    title: React.ComponentType<any>;
    header?: React.ComponentType<any>;
    icon?: string;
    sidenav?: false;
    backArrow?: true;
    content: React.ComponentType<any>;
    role: Role;
}

const routes: RouteDefinition[] = [
    ACCOUNT_OVERVIEW,
    ALL_TEAMS,
    MY_TEAMS,
    ADMIN_NEW_SPORT_TEAM,
    ADMIN_NEW_BUSINESS_TEAM,
    ACCOUNT_NEW_SPORT_TEAM,
    ACCOUNT_NEW_BUSINESS_TEAM,
    EDIT_SPORT_TEAM,
    EDIT_BUSINESS_TEAM,
    VIEW_SPORT_TEAM,
    VIEW_BUSINESS_TEAM,
    VIEW_REPORT,
    MANAGE_ALL_USERS,
    MANAGE_ACCOUNT_USERS,
    ALL_ACCOUNTS,
    NEW_ACCOUNT,
    ACCOUNT_TEAMS,
    NEW_ACCOUNT,
    EDIT_ACCOUNT,
    VIEW_ACCOUNT
];

export default routes;
