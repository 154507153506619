import { ReportModel, TextAnalysisModel } from '../../models/report-model';

export function buildGroupDynamicFactorsUpdate(
    report: ReportModel,
    showIndividualDiagrams: boolean
): ReportModel {
    return {
        ...report,
        groupDynamicFactors: {
            ...report.groupDynamicFactors,
            showIndividualDiagrams
        }
    };
}

export function buildTextAnalysisUpdate(report: ReportModel, factorIndex: number, comment: string) {
    return buildFactorUpdate(report, factorIndex, factor => ({
        ...factor,
        comment
    }));
}

export function buildTextAnalysisFactorToggleUpdate(report: ReportModel, factorIndex: number) {
    return buildFactorUpdate(report, factorIndex, factor => ({
        ...factor,
        isHidden: !factor.isHidden
    }));
}

export function builtTextAnalysisAllFactorsToggleUpdate(report: ReportModel, hide: boolean) {
    let result = report;
    for (let i = 0; i < report.textAnalysis.evaluationMap[report.reportCulture].length; i++) {
        result = buildFactorUpdate(result, i, factor => ({
            ...factor,
            isHidden: hide
        }));
    }
    return result;
}

export function buildSummaryUpdate(report, summary: string) {
    return {
        ...report,
        summary: {
            ...report.summary,
            [report.reportCulture]: summary
        }
    };
}

export function buildQuestionChartUpdate(
    report: ReportModel,
    factorIndex: number,
    chart: number,
    selected: boolean
) {
    return buildCultureFactorUpdate(report, factorIndex, factor => ({
        ...factor,
        questions: factor.questions.map((question, i) => {
            if (i !== chart) {
                return question;
            }
            return {
                ...question,
                selected
            };
        })
    }));
}

export function buildQuestionChartComparisonUpdate(
    report: ReportModel,
    factorIndex: number,
    chart: number,
    selectedComparison: string
) {
    return buildCultureFactorUpdate(report, factorIndex, factor => ({
        ...factor,
        questions: factor.questions.map((question, i) => {
            if (i !== chart) {
                return question;
            }
            return {
                ...question,
                selectedComparison
            };
        })
    }));
}

function buildFactorUpdate(
    report: ReportModel,
    factorIndex: number,
    update: (factor: TextAnalysisModel) => TextAnalysisModel
): ReportModel {
    return buildCultureFactorUpdate(report, factorIndex, (factor, culture) => {
        if (culture !== report.reportCulture) {
            return factor;
        }
        return update(factor);
    });
}

function buildCultureFactorUpdate(
    report: ReportModel,
    factor: number,
    update: (factor: TextAnalysisModel, culture: string) => TextAnalysisModel
): ReportModel {
    const evaluationMap = {};
    for (const culture of Object.getOwnPropertyNames(report.textAnalysis.evaluationMap)) {
        evaluationMap[culture] = report.textAnalysis.evaluationMap[culture].map((f, i) => {
            if (i !== factor) {
                return f;
            }
            return update(f, culture);
        });
    }
    return {
        ...report,
        textAnalysis: { evaluationMap }
    };
}
