import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import i18n from 'es2015-i18n-tag';
import * as React from 'react';

export const SidenavToggleBtn = (props: { onClick: () => void }) => (
    <button className="sidenav__toggle-btn" onClick={props.onClick}>
        <Icon className="sidenav__toggle-btn-icon" path={mdiChevronRight} size={1} />
        <span className="sidenav__toggle-btn-label">{i18n`Einklappen`}</span>
    </button>
);
